import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';
import { RequestNav } from './NavActions';

export function QuickSearch(searchText) {
  console.log('quicksearch');
  // auto add ORs to
  if(!searchText.includes('AND') && !searchText.includes('OR') && !searchText.includes('"')) {

    let st2 = searchText.split(' ');
    while(st2.indexOf('') !== -1)
    {
      st2.splice(st2.indexOf(''), 1);
    }
    if(st2.length > 1)
    {
      searchText = '';
      st2.forEach((part) => {
        if(searchText !== '') searchText += ' OR ';
        searchText += part;
      });
    }
  }

  console.log(searchText);
  axios
    .post(
      `${Globals.baseUrl}/Search/QuickSearch`,
      {
        search: searchText,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);
         if (res.data.documentCount === 0) {
           PostAlert('error', 'No matching documents found');
         }
        res.data.searchText = searchText;
        console.log('dispatch');
        dispatcher.dispatch({
          actionType: actionTypes.SearchQuickSearch,
          Result: res.data,
        });
        if (res.data.documentCount === 1) {
          let docId = res.data.documentIds[0];
          RequestNav(`Document ${docId}`, 'searchDocGoto');
        } else if(res.data.documentCount > 1) {
          RequestNav('Search', 'advancedSearch');
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {

    });
}

export function SearchByDocumentId(searchText) {
  axios
    .post(
      `${Globals.baseUrl}/Search/SearchByDocumentId`,
      {
        search: searchText
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {

        if (res.data.documentCount === 0) {
          PostAlert('error', 'No matching documents found');
        }
        dispatcher.dispatch({
          actionType: actionTypes.SearchByDocId,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
    });
}

export function SearchByValues(searchText, documentsToSearch) {
  console.log('SearchByValues');

  if(!searchText.includes('AND') && !searchText.includes('OR') && !searchText.includes('\"')) {

    let st2 = searchText.split(' ');
    while(st2.indexOf('') !== -1)
    {
      st2.splice(st2.indexOf(''), 1);
    }
    if(st2.length > 1)
    {
      searchText = '';
      st2.forEach((part) => {
        if(searchText !== '') searchText += ' OR ';
        searchText += part;
      });
    }
  }
  console.log(searchText);

  axios
    .post(
      `${Globals.baseUrl}/Search/SearchByValues`,
      {
        search: searchText,
        documentsToSearch: documentsToSearch
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.data.documentCount === 0) {
        PostAlert('error', 'No matching documents found');
      }
      if (res.status === 200) {
        res.data.searchText = searchText;
        dispatcher.dispatch({
          actionType: actionTypes.SearchByValues,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
    });
}

export function GetSearchStep(
  id,
  bpId,
  value,
  searchType,
  data,
  userAudit,
  userName,
  column,
  order,
  offset,
  documentsToSearch
) {
  console.log('GetSearchStep');
  axios
    .post(
      `${Globals.baseUrl}/Search/GetSearchStep`,
      {
        id: id,
        bpId: bpId,
        value: value,
        searchType: searchType,
        data: data,
        userAudit: userAudit,
        userName: userName,
        column: column.toString(),
        order: order.toString(),
        offset: offset.toString(),
        documentsToSearch: documentsToSearch
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;
        res.request = {
          id: id,
          bpId: bpId,
          value: value,
          searchType: searchType,
          data: data,
          userAudit: userAudit,
          userName: userName,
          column: column.toString(),
          order: order.toString(),
          offset: offset.toString(),
        };
        dispatcher.dispatch({

          actionType: actionTypes.SearchGetStep,
          Result: result,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetSearchBp(
  bpId,
  value,
  searchType,
  data,
  userAudit,
  userName,
  column,
  order,
  offset,
  documentsToSearch
) {
  axios
    .post(
      `${Globals.baseUrl}/Search/GetSearchBp`,
      {
        id: '0',
        bpId: bpId,
        value: value,
        searchType: searchType,
        data: data,
        userAudit: userAudit,
        userName: userName,
        column: column.toString(),
        order: order.toString(),
        offset: offset.toString(),
        documentsToSearch: documentsToSearch
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SearchGetBp,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetIndexFields() {
  axios
    .get(`${Globals.baseUrl}/Search/GetIndexFields`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.GetIndexFields,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.GetIndexFields,
        Result: result,
      });
    });
}

export function SearchByFieldValue(data, documentsToSearch) {
  axios
    .post(
      `${Globals.baseUrl}/Search/SearchByFieldValue`,
      {
        data: data,
        documentsToSearch: documentsToSearch
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.documentCount === 0) {
          PostAlert('error', 'No matching documents found');
        }
        dispatcher.dispatch({
          actionType: actionTypes.SearchByFieldValue,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
    });
}

export function GetWorkflowUserSteps() {
  axios
    .get(`${Globals.baseUrl}/Search/GetWorkflowUserSteps`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WorkflowGetUserSteps,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        //Logout();
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        //Logout();
      }
      // Logout();
    });
}

export function SearchByUserAudit(data, documentsToSearch) {
  axios
    .post(
      `${Globals.baseUrl}/Search/SearchByUserAudit`,
      {
        userId: data.userId,
        stepId: data.stepId,
        startDate: data.startDate,
        endDate: data.endDate,
        action: data.action,
        documentsToSearch: documentsToSearch
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.data.documentCount === 0) {
        PostAlert('error', 'No matching documents found');
      }
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SearchByUserAudit,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
    });
}

export function SearchByUserQueue(searchText, documentsToSearch) {
  axios
    .post(
      `${Globals.baseUrl}/Search/SearchByUserQueue`,
      {
        userName: searchText,
        documentsToSearch: documentsToSearch
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.data.documentCount === 0) {
        PostAlert('error', 'No matching documents found');
      }
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SearchByUserQueue,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
    });
}

export function SearchByNotes(searchText, documentsToSearch) {
  axios
    .post(
      `${Globals.baseUrl}/Search/SearchByNotes`,
      {
        search: searchText,
        documentsToSearch: documentsToSearch
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.data.documentCount === 0) {
        PostAlert('error', 'No matching documents found');
      }
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SearchByNotes,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
    });
}

export function SearchByProperties(searchText, documentsToSearch) {
  axios
    .post(
      `${Globals.baseUrl}/Search/SearchByProperties`,
      {
        search: searchText,
        documentsToSearch: documentsToSearch
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.data.documentCount === 0) {
        PostAlert('error', 'No matching documents found');
      }
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SearchByProperties,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
    });
}
