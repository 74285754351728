import { Component } from 'react';
import logo from '../images/TD AI Logo 2 Color Clear.png';
import mslogo from '../images/mslogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  CheckMSButton,
  LoginViaUserIdPwd,
  LoginViaMicrosoft,
  LoginViaMSOAuth,
  CheckTDVersion,
} from '../actions/LoginActions';
import LoginStore from '../stores/LoginStore';

class LoginView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMSButton: false,
      userId: '',
      password: '',
      hasError: false,
      errorMessage: 'Unable to reach server',
    };

    this.onLoginStoreChange = this.onLoginStoreChange.bind(this);
    this.onUserIdChange = this.onUserIdChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
    this.onPwdKeyDown = this.onPwdKeyDown.bind(this);
    this.signInWithMicrosoft = this.signInWithMicrosoft.bind(this);
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onLoginStoreChange);
    if (window.location.search.startsWith('?auth=')) {
      let auth = window.location.search.split('=')[1];
      LoginViaMSOAuth(auth);
      //this._sendAuthRequest(auth);
    }
    CheckMSButton();
  }

  componentWillUnmount() {
    console.log('unmount');
    LoginStore.removeChangeListener(this.onLoginStoreChange);
  }

  onUserIdChange(event) {
    let uid = event.target.value;
    this.setState({ userId: uid });
  }

  onPasswordChange(event) {
    let pwd = event.target.value;
    this.setState({ password: pwd });
  }

  onPwdKeyDown(e) {
    if (e.code === 'Enter') {
      this.onLoginClick();
    }
  }

  onLoginStoreChange() {
    let action = LoginStore.getLastAction();
    console.log(action);
    switch (action) {
      case 'CheckMSButton':
        let msbtn = LoginStore.getMSButtonSetting();
        this.setState({ showMSButton: msbtn });
        break;
      case 'Login':
        let isLoggedIn = LoginStore.getLoggedInStatus();
        if (isLoggedIn === true) {
          // route to app page
          this.setState({ hasError: false, errorMessage: '' });
          console.log('checkVersion');
          CheckTDVersion();
          //console.log('routing');
        } else {
          let error = LoginStore.getError();
          this.setState({ hasError: true, errorMessage: error });
        }
        break;

      default:
        break;
    }
  }

  onLoginClick() {
    LoginViaUserIdPwd(this.state.userId, this.state.password);
  }

  signInWithMicrosoft() {
    LoginViaMicrosoft();
  }

  render() {
    return (
      <div className='App'>
        <header className='App-header'>
          <img src={logo} className='App-logo' alt='logo' />
          <div className='App-version'>
            {' '}
            Version {process.env.REACT_APP_VERSION}
          </div>
          <div className='card login-form'>
            <div className='card-body' style={{ backgroundColor: '#D8FDFF' }}>
              <input
                type='text'
                value={this.state.userId}
                onChange={this.onUserIdChange}
                className='form-control login-input'
                placeholder='Username'
                id='txtUsername'
              />
              <input
                type='password'
                value={this.state.password}
                onChange={this.onPasswordChange}
                className='form-control login-input'
                placeholder='Password'
                onKeyDown={this.onPwdKeyDown}
                id='txtPassword'
              />
              <button onClick={this.onLoginClick} className='login-button btn'>
                <FontAwesomeIcon icon={solid('sign-in-alt')} /> Login
              </button>
              {this.state.showMSButton && (
                <div className='msbutton' onClick={this.signInWithMicrosoft}>
                  <img
                    style={{
                      paddingTop: '9px',
                      float: 'left',
                      paddingRight: '12px',
                    }}
                    alt='mslogo'
                    src={mslogo}
                  />
                  <div
                    style={{
                      lineHeight: '42px',
                      float: 'left',
                    }}
                  >
                    Sign in with Microsoft
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.hasError && (
            <div className='alert alert-danger' style={{ marginTop: '10px' }}>
              <FontAwesomeIcon icon={solid('times-circle')} />{' '}
              {this.state.errorMessage}
            </div>
          )}
        </header>
      </div>
    );
  }
}

export default LoginView;
