import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SetDocumentZoomSetting } from '../../../actions/DocumentActions';

var dragStartX = -1;
var dragStartY = -1;
var isDragging = false;
var isDown = false;
var initialPanX = -1;
var initialPanY = -1;
var lastWheelTop = 0;
var wheelStopClicks = 0;

function DocImageViewer(props) {
  const canvasRef = useRef();
  //const [viewWidth, setViewWidth] = useState(832);
  const [scale, setScale] = useState(100);
  const [first, setFirst] = useState(true);

  const mouseMove = (e) => {
    if (!isDragging && isDown) {
      if (
        Math.abs(dragStartX - e.pageX) > 15 ||
        Math.abs(dragStartY - e.pageY) > 15
      ) {
        isDragging = true;

        var el = document.getElementById('te-canvas-container');
        initialPanX = el.scrollLeft;
        initialPanY = el.scrollTop;
      }
    }
    if (isDragging) {
      var el2 = document.getElementById('te-canvas-container');

      if (el2) {
        el2.scrollTo(
          initialPanX - e.pageX + dragStartX,
          initialPanY - e.pageY + dragStartY
        );
      }
    }
  };

  const mouseDown = (e) => {
    isDown = true;
    dragStartX = e.pageX;
    dragStartY = e.pageY;
    e.stopPropagation();
  };

  const mouseUp = (e) => {
    isDown = false;
    isDragging = false;
  };

  const mouseLeave = (e) => {
    isDragging = false;
    isDown = false;
  };

  const mouseWheel = (e) => {
    var delta = (e.deltaY * -1) / 25;
    var el = document.getElementById('te-canvas-container');
    var st = el.scrollTop;

    if (st !== lastWheelTop) {
      wheelStopClicks = 0;
    } else {
      wheelStopClicks++;
    }

    if (wheelStopClicks > 2) {
      if (delta < 0) {
        wheelStopClicks = 0;
        if (props.currentPage !== props.imageCount) {
          props.onPageNext(props.currentPage + 1);
          el.scrollTo(0, 0);
        }
      }
      if (delta > 0) {
        wheelStopClicks = 0;
        if (props.currentPage > 1) {
          props.onPageNext(props.currentPage - 1);
          el.scrollTo(0, el.scrollHeight);
        }
      }
    }
    lastWheelTop = el.scrollTop;
  };

  const draw = () => {
    //console.log('draw');
    //console.log(props.imageHeight);
    var canvas = canvasRef.current;
    var context = canvas.getContext('2d');

    var image = new Image();

    image.onload = function () {
      context.clearRect(
        0,
        0,
        (props.imageWidth * scale) / 100,
        (props.imageHeight * scale) / 100
      );
      context.drawImage(
        image,
        0,
        0,
        (props.imageWidth * scale) / 100,
        (props.imageHeight * scale) / 100
      );
    };
    image.src = props.base64;
    context.drawImage(
      image,
      0,
      0,
      (props.imageWidth * scale) / 100,
      (props.imageHeight * scale) / 100
    );
  };

  const scaleImage = (e) => {
    //var delta = props.imageWidth * scale - props.imageWidth * e.target.value;
    //console.log(e.target.value);
    setScale(parseInt(e.target.value));
  };

  const documentZoomSetting = (e) => {
    //var delta = props.imageWidth * scale - props.imageWidth * e.target.value;
    SetDocumentZoomSetting(e.target.value);
  };

  useEffect(() => {
    //console.log('effect');
    if (first){
      setScale(props.zoom, [first, draw, props.zoom]);
      setFirst(false);
    }

    draw();

    //var canvas = canvasRef.current;
  });


  //draw();

  return (
    <div>
      <div className='d-flex flex-row aligh-items-start te-toolbar2'>
        <input
          type='range'
          tabIndex='-1'
          min='30'
          max='200'
          value={scale}
          className='slider'
          id='myRange'
          onChange={scaleImage}
          onMouseUp={documentZoomSetting}
        />
        <div className='mt-1 ms-1'>{scale}%</div>
        {props.isRotatingLeft && (
          <>
            <FontAwesomeIcon
              className='mt-2 ms-3 fa-spin-reverse'
              icon={solid('undo')}
              spin
            />
            <FontAwesomeIcon
              style={{ color: '#979797' }}
              className='mt-2 ms-3'
              icon={solid('redo')}
            />
          </>
        )}
        {props.isRotatingRight && (
          <>
            <FontAwesomeIcon
              style={{ color: '#979797' }}
              className='mt-2 ms-3'
              icon={solid('undo')}
            />
            <FontAwesomeIcon className='mt-2 ms-3' icon={solid('redo')} spin />
          </>
        )}
        {!props.isRotatingLeft && !props.isRotatingRight && (
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            className='mt-2 ms-3'
            icon={solid('undo')}
            onClick={props.rotateLeft}
          />
        )}

        {!props.isRotatingLeft && !props.isRotatingRight && (
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            className='mt-2 ms-3'
            icon={solid('redo')}
            onClick={props.rotateRight}
          />
        )}

        <FontAwesomeIcon
          style={{ cursor: 'pointer', marginLeft: '40px' }}
          className='mt-2'
          icon={solid('angle-double-left')}
          onClick={() => props.onPageNext(1)}
        />
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className='mt-2 ms-3'
          icon={solid('arrow-left')}
          onClick={() => props.onPageNext(props.currentPage - 1)}
        />
        <div className='mt-1 ms-3'>
          <input
            style={{
              fontSize: '11pt',
              border: 'solid 1px #4C8CAE',
              paddingTop: '0',
              width: '30px',
              paddingLeft: '4px',
            }}
            value={props.currentPage}
            onChange={(e) => {
              var idx = parseInt(e.target.value);
              if (!isNaN(idx)) {
                props.onPageNext(idx);
              }
            }}
            type='text'
          />{' '}
          / {props.imageCount}
        </div>
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className='mt-2 ms-3'
          icon={solid('arrow-right')}
          onClick={() => props.onPageNext(props.currentPage + 1)}
        />
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className='mt-2 ms-3'
          icon={solid('angle-double-right')}
          onClick={() => props.onPageNext(props.imageCount)}
        />
      </div>
      <div
        className='coolScroll2'
        id='te-canvas-container'
        style={{
          height: `${props.viewHeight}px`,
          maxWidth: `${props.viewWidth}px`,
          overflow: 'auto',
          background: '#4F4F4F',
          padding: '10px',
        }}
      >
        <canvas
          id='myCanvas'
          onMouseLeave={mouseLeave}
          onMouseUp={mouseUp}
          onWheel={mouseWheel}
          onMouseDown={mouseDown}
          onMouseMove={mouseMove}
          ref={canvasRef}
          width={(props.imageWidth * scale) / 100}
          height={(props.imageHeight * scale) / 100}
          style={{background: '#FFF'}}
        ></canvas>
      </div>
    </div>
  );
}

export default DocImageViewer;
