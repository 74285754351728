import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Prompt } from './prompt';
import AutoComplete from '../../../components/AutoComplete';
import ActionButton from '../../../components/ActionButton';
import {
  GetDocument,
  CheckIn,
  SaveDocument,
  SubmitDocument,
  GetNextDocument,
  UpdateUserFieldSettings,
  GetDocumentAudit,
  GetAvailableTemplates,
  UpdateTemplate,
  ExecuteExtender,
  SendDocumentToImageReview,
  //ChangeQueueAssignment,
  DeleteDocument,
  AddNote,
  DownloadAttachment,
  PullDocument,
  DeleteAttachment,
  UploadAttachment,
  DownloadPDF,
  DownloadRelatedDocumentPacket,
  DownloadPDFWithStamps,
  FetchAssignableUsers,
  MoveAssignDocument,
  GetAvailableTemplatesByBp2,
  GetDocumentStatusInfo,
  SendEmail,
  ChangeBusinessProcesses,
  GetAssignees,
   GetDocumentZoomSetting,
  ExecuteExtender3,
  GetRelatedDocs,
  UndeleteDocument,
  DownloadPDFWithAttachments,

} from '../../../actions/DocumentActions';
import { UpdateSetting } from '../../../actions/UserActions';
import {
  GetImages,
  FetchImage,
  RotateImage,
} from '../../../actions/ImageActions';
import DocumentStore from '../../../stores/DocumentStore';
import SearchStore from '../../../stores/SearchStore';
import BPStore from '../../../stores/BPStore';
import BulkActionStore from '../../../stores/BulkActionStore';
import DocImageViewer from './DocImageViewer';
import FieldEditor from './FieldEditor';
import TableCellEditor from './TableCellEditor';
import { RequestNav } from '../../../actions/NavActions';
import actionTypes from '../../../actions/ActionTypes';
import { GetBPList } from '../../../actions/BPActions';
import { PostAlert } from '../../../actions/AlertActions';
import HotKeyStore from '../../../stores/HotKeyStore';
import { createBrowserHistory } from 'history';

const customHistory = createBrowserHistory();

var isDraggingColumn = false;
var startColX = 0;
var dragColIndex = 0;
var startColWidth = 0;
var useMagic = true;
var _existingKeys = [];
var _pendingKeys = [];

class DocumentEditor extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentDoc: undefined,
      currentFieldSet: { fieldSetId: 0, fieldDefs: [] },
      isLoading: false,
      preventViewing: false,
      isSaving: false,
      currentDocId: 0,
      documentLoaded: false,
      showAttachmentMenu: false,
      showPdfMenu: false,
      showUserAssign: false,
      canAssignUser: false,
      showSnagMenu: false,
      workflowName: '',
      stepName: '',
      formTypeName: '',
      templateName: '',
      checkedOutBy: '',
      documentID: 0,
      attachmentCount: 0,
      noteCount: 1,
      lockedByMe: false,
      lockedByOther: false,
      automatedTask: false,
      userCanSubmit: false,
      userCanEdit: false,
      userCanSendToReview: false,
      userCanDelete: false,
      userCanMoveAssign: false,
      isDraggingSplitBar: false,
      userCanAddNotes: false,
      topHeight: 400,
      bottomHeight: 10,
      topHeightOnDragStart: 0,
      imageviewerHeight: 400 - 32,
      //tableHeight: window.innerHeight - 400 - 100,
      tableHeight: window.innerHeight - 400 - 239,
      imageviewerWidth: window.innerWidth - 300,
      tableWidth: window.innerWidth - 30,
      images: [],
      currentImage: undefined,
      imageCount: 0,
      loadingImage: false,
      snapshots: [],
      returnUrl: '/ActiveDocs',
      currentEdit: { rowId: 1, colId: 1, orderId: 1 },
      allRowCheck: false,
      showTrash: true,
      showChecks: false,
      showTools: false,
      rowData: [],
      showColumnEdit: false,
      showDocumentMenu: false,
      showAudit: false,
      showAudit2: false,
      currentAuditView: 'user',
      isAuditLoading: false,
      auditInfo: undefined,
      showTemplateChange: false,
      selectedTemplate: { templateId: -1, templateName: 'Template...' },
      suggestedTemplateId: { templateId: -1, templateName: 'Template...' },
      templateSearchText: '',
      availableTemplates: [],
      filteredTemplates: [],
      applyTemplate: true,
      userSearch: '',
      showDeleteConfirm: false,
      showUserNotes: false,
      showAddNote: true,
      newNote: '',
      showExcelPaste: false,
      excelData: [],
      showProperties: false,
      hasStamps: false,
      showStampMenu: false,
      showStampScreen: false,
      queuesDirty: false,
      stampRequest: {
        showDocId: true,
        showUserName: true,
        showDate: true,
        showTime: true,
        size: 2,
        sizeDesc: 'Medium',
        useTransparency: false,
        stamps: [],
      },
      lastStampPos: -1,
      showMoveAssign: false,
      moveAssignStepId: -1,
      moveAssignableUsers: [],
      moveAssignUsers: [],
      moveAssignUserSearch: '',
      moveAssignSelectedUsers: [],
      relatedInfo: {
        show: false,
        left: 187,
        formType: 'Payables',
        field: 'Vendor Id',
      },
      isRotatingLeft: false,
      isRotatingRight: false,

      //bp
      availableBps: [],
      selectedBpId: -1,
      selectedBpId2: -1,
      showChangeBusinessProcess: false,
      filteredTemplates2: [],
      availableTemplates2: [],
      selectedTemplate2: { templateId: -1, templateName: 'Template...' },
      suggestedTemplateId2: { templateId: -1, templateName: 'Template...' },
      templateSearchText2: '',
      fieldsHaveError: false,
      showAllColumns: false,
      isSubmitting: false,

      relatedDocChange: false,
      showReasonForReject: false,
      rejectReasonNote: '',
      showEmailPDF: false,
      emailTo: '',
      emailSubject: '',
      emailMessage: '',
      inProgressAnimation: false,
      selectedChangeBpTemplate: -1,
      showErrorMessage: false,
      errorMessages: [],
      documentZoomSetting: 100,
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      alertNextOnClose: false,
      tempCount: 0,
      loadExtenderRows: false,
      showExpandedView: false,
      expandedViewText: '',
      expandedViewTitle: '',
    };

    this.loadDocumentFromDB = this.loadDocumentFromDB.bind(this);
    this.handleAttachmentClick = this.handleAttachmentClick.bind(this);
    this.handlePdfClick = this.handlePdfClick.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.toggleSnagMenu = this.toggleSnagMenu.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onSplitBarMouseDown = this.onSplitBarMouseDown.bind(this);
    this.onDocumentChange = this.onDocumentChange.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.handleImageNav = this.handleImageNav.bind(this);
    this.generateSnapshot = this.generateSnapshot.bind(this);
    this.handleHeaderFieldEdit = this.handleHeaderFieldEdit.bind(this);
    this.handleTableFieldEdit = this.handleTableFieldEdit.bind(this);
    this.updateCurrentSnapshot = this.updateCurrentSnapshot.bind(this);
    this.undoLatestChange = this.undoLatestChange.bind(this);
    this.beforeUnloadListener = this.beforeUnloadListener.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.initSnapshot = this.initSnapshot.bind(this);
    this.handleApproveClick = this.handleApproveClick.bind(this);
    this.handleRejectClick = this.handleRejectClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.DragColumnSize = this.DragColumnSize.bind(this);
    this.stopDraggingColSize = this.stopDraggingColSize.bind(this);
    this.setRowCheck = this.setRowCheck.bind(this);
    this.setAllRowCheck = this.setAllRowCheck.bind(this);
    this.toggleChecks = this.toggleChecks.bind(this);
    this.toggleShowTools = this.toggleShowTools.bind(this);
    this.toggleFieldSet = this.toggleFieldSet.bind(this);
    this.toggleColumnEdit = this.toggleColumnEdit.bind(this);
    this.toggleColumnDisplay = this.toggleColumnDisplay.bind(this);
    //this.createNewRow = this.createNewRow.bind(this);
    this.onMoveCell = this.onMoveCell.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.deleteRows = this.deleteRows.bind(this);
    this.toggleDocMenu = this.toggleDocMenu.bind(this);
    this.hideDocMenu = this.hideDocMenu.bind(this);
    this.onSearchUpdate = this.onSearchUpdate.bind(this);
    this.toggleShowAudit = this.toggleShowAudit.bind(this);
    this.switchAuditView = this.switchAuditView.bind(this);
    this.toggleTemplateChange = this.toggleTemplateChange.bind(this);
    this.suggestTemplateItem = this.suggestTemplateItem.bind(this);
    this.templateSuggestionClick = this.templateSuggestionClick.bind(this);
    this.onChangeTemplateSearchText =
      this.onChangeTemplateSearchText.bind(this);
    this.onClickTemplateSearch = this.onClickTemplateSearch.bind(this);
    this.filterTemplates = this.filterTemplates.bind(this);
    this.toggleApplyTemplate = this.toggleApplyTemplate.bind(this);
    this.onClickTemplateChange = this.onClickTemplateChange.bind(this);
    this.setFieldError = this.setFieldError.bind(this);
    this.ValidateDocument = this.ValidateDocument.bind(this);
    this.closeAllList = this.closeAllList.bind(this);
    this.handleImageRevClick = this.handleImageRevClick.bind(this);
    this.removeAssignment = this.removeAssignment.bind(this);
    this.editAssignUser = this.editAssignUser.bind(this);
    this.assignUserBlur = this.assignUserBlur.bind(this);
    this.handlePreviousClick = this.handlePreviousClick.bind(this);
    this.handleTrashClick = this.handleTrashClick.bind(this);
    this.cancelTrashClick = this.cancelTrashClick.bind(this);
    this.deleteDoc = this.deleteDoc.bind(this);
    this.toggleUserNotes = this.toggleUserNotes.bind(this);
    this.toggleAddNote = this.toggleAddNote.bind(this);
    this.addNote = this.addNote.bind(this);
    this.editNewNote = this.editNewNote.bind(this);
    this.toggleExcelPaste = this.toggleExcelPaste.bind(this);
    this.pasteExcel = this.pasteExcel.bind(this);
    this.appendExcelData = this.appendExcelData.bind(this);
    this.replaceExcelData = this.replaceExcelData.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.pullDocFromUser = this.pullDocFromUser.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.uploadAttachmentSubmit = this.uploadAttachmentSubmit.bind(this);
    this.toggleProperties = this.toggleProperties.bind(this);
    this.handleStampClick = this.handleStampClick.bind(this);
    this.toggleStamps = this.toggleStamps.bind(this);
    this.editStampRequest = this.editStampRequest.bind(this);
    this.stampImageClick = this.stampImageClick.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
    this.downloadRelatedDocumentPacket =
      this.downloadRelatedDocumentPacket.bind(this);
    this.downloadDocumentWithStamps =
      this.downloadDocumentWithStamps.bind(this);
    this.toggleShowMoveAssign = this.toggleShowMoveAssign.bind(this);
    this.editMoveAssignStep = this.editMoveAssignStep.bind(this);
    this.moveAssignUserBlur = this.moveAssignUserBlur.bind(this);
    this.editMoveAssignUser = this.editMoveAssignUser.bind(this);
    this.moveAssignDoc = this.moveAssignDoc.bind(this);
    //this.checkChildExtenders = this.checkChildExtenders.bind(this);
    this.onClickMoveAssignableUser = this.onClickMoveAssignableUser.bind(this);
    this.onClickRemoveMoveAssignUser =
      this.onClickRemoveMoveAssignUser.bind(this);
    this.onRelatedMouseIn = this.onRelatedMouseIn.bind(this);
    this.onRelatedMouseOut = this.onRelatedMouseOut.bind(this);
    this.onRelatedMouseClick = this.onRelatedMouseClick.bind(this);
    this.rotateDocImageLeft = this.rotateDocImageLeft.bind(this);
    this.rotateDocImageRight = this.rotateDocImageRight.bind(this);
    //this.onKeyUp = this.onKeyUp.bind(this);
    this.toggleChangeBusinessProcess =
      this.toggleChangeBusinessProcess.bind(this);
    this.editBps = this.editBps.bind(this);
    this.onChangeTemplateSearchText2 =
      this.onChangeTemplateSearchText2.bind(this);
    this.filterTemplates2 = this.filterTemplates2.bind(this);
    this.onBPChange = this.onBPChange.bind(this);
    this.onClickTemplateSearch2 = this.onClickTemplateSearch2.bind(this);
    this.onBulkChange = this.onBulkChange.bind(this);
    this.suggestTemplateItem = this.suggestTemplateItem.bind(this);
    this.templateSuggestionClick2 = this.templateSuggestionClick2.bind(this);
    this.goToDoc = this.goToDoc.bind(this);
    this.writeGridToClipboard = this.writeGridToClipboard.bind(this);
    this.handleHeaderFieldTextEdit = this.handleHeaderFieldTextEdit.bind(this);
    this.setLineFieldError = this.setLineFieldError.bind(this);
    this.calculateRunningTotal = this.calculateRunningTotal.bind(this);
    this.windowMouseLeave = this.windowMouseLeave.bind(this);
    this.runExtender = this.runExtender.bind(this);
    this.blankRowField = this.blankRowField.bind(this);
    this.blankHeaderField = this.blankHeaderField.bind(this);
    this.getExtenderData = this.getExtenderData.bind(this);
    this.getTableWidth = this.getTableWidth.bind(this);
    this.onRelatedClick = this.onRelatedClick.bind(this);
    this.onBackToOriginalDoc = this.onBackToOriginalDoc.bind(this);
    this.updateRunningTotals = this.updateRunningTotals.bind(this);
    this.cancelReject = this.cancelReject.bind(this);
    this.editRejectNote = this.editRejectNote.bind(this);
    this.confirmReject = this.confirmReject.bind(this);
    this.emailPDF = this.emailPDF.bind(this);
    this.cancelEmailPDF = this.cancelEmailPDF.bind(this);
    this.onEditEmailTo = this.onEditEmailTo.bind(this);
    this.onEditEmailSubject = this.onEditEmailSubject.bind(this);
    this.onEditEmailMessage = this.onEditEmailMessage.bind(this);
    this.validateAndSendEmail = this.validateAndSendEmail.bind(this);
    this.moveBpDocsClick = this.moveBpDocsClick.bind(this);
    this.editSelectedChangeBpTemplate =
      this.editSelectedChangeBpTemplate.bind(this);
    this.inpsectTemplate = this.inpsectTemplate.bind(this);
    this.checkQueueChanges = this.checkQueueChanges.bind(this);
    this.goToActiveDocs = this.goToActiveDocs.bind(this);
    this.showSaveDetails = this.showSaveDetails.bind(this);
    this.toggleShowErrorMessage = this.toggleShowErrorMessage.bind(this);
    this.findDependency = this.findDependency.bind(this);
    this.processExtenderRow = this.processExtenderRow.bind(this);
    this.processExtenderRow2 = this.processExtenderRow2.bind(this);
    this.setCellError = this.setCellError.bind(this);
    this.clearCellError = this.clearCellError.bind(this);
    this.clearChildCells = this.clearChildCells.bind(this);
    this.errorRowField = this.errorRowField.bind(this);
    this.focusFirstField = this.focusFirstField.bind(this);
    this.onDropAttachment = this.onDropAttachment.bind(this);
    this.cachedDocumentExtenderRow = this.cachedDocumentExtenderRow.bind(this);
    this.executeExtenderRows = this.executeExtenderRows.bind(this);
    this.toggleShowAlert = this.toggleShowAlert.bind (this);
    this.setAuditTab = this.setAuditTab.bind(this);
    this.undeleteDocument = this.undeleteDocument.bind(this);
    this.analyzeTemplate = this.analyzeTemplate.bind(this);
    this.onHotKey = this.onHotKey.bind(this);
    this.expandView = this.expandView.bind(this);
    this.hideExpandedView = this.hideExpandedView.bind(this);
    this.onpopstate = this.onpopstate.bind(this);
    this.downloadDocumentAndAttachments = this.downloadDocumentAndAttachments.bind(this);
    this.onMagicScroll = this.onMagicScroll.bind(this);
  }

  componentDidUpdate() {
    if (this.state.loadExtenderRows){
      //this.executeExtenderRows();
      this.setState({ loadExtenderRows: false });
    }
    //this.checkDocumentUpdate();
    // when you navigate to or from related docs, load necessary document
    if (this.state.relatedDocChange) {
      this.setState({ relatedDocChange: false });
      this.loadDocumentFromDB();
    }
  }

  componentDidMount() {
    DocumentStore.addChangeListener(this.onDocumentChange);
    SearchStore.addChangeListener(this.onSearchUpdate);
    BPStore.addChangeListener(this.onBPChange);
    BulkActionStore.addChangeListener(this.onBulkChange);
    HotKeyStore.addChangeListener(this.onHotKey);
    //var docEditor = document.getElementById('tdDocEditor');
    window.onresize = this.onWindowResize;
    window.onpopstate = this.onpopstate;
    document.onmouseleave = this.windowMouseLeave;
    // window.history.listen((location, action) => {
    // });
    //window.addEventListener('beforeunload', this.beforeUnloadListener);
    GetDocumentZoomSetting();

    this.loadDocumentFromDB();
    this.setState({ isLoading: true });
    var returnUrl = localStorage.getItem('docReturn');
    if (returnUrl) {
      this.setState({ returnUrl: returnUrl });
    }
  }

  componentWillUnmount() {
    DocumentStore.removeChangeListener(this.onDocumentChange);
    SearchStore.removeChangeListener(this.onSearchUpdate);
    BPStore.removeChangeListener(this.onBPChange);
    BulkActionStore.removeChangeListener(this.onBulkChange);
    HotKeyStore.removeChangeListener(this.onHotKey);
    //var docEditor = document.getElementById('tdDocEditor');
    //window.removeEventListener('beforeunload', this.beforeUnloadListener);

    if (this.state.currentDoc !== undefined) {
      CheckIn(this.state.currentDoc.documentId, this.state.currentDoc);
    }
  }

  //componentWillReceiveProps() {
  //   this.loadDocumentFromDB();
  onSearchUpdate() {
    let lastAction = SearchStore.getLastAction();
    if (lastAction === actionTypes.SearchQuickSearch) {
      let docCount = SearchStore.getDocCount();
      if (docCount === 1) {
        if (this.state.currentDoc !== undefined) {
          CheckIn(this.state.currentDoc.documentId, this.state.currentDoc);
        }
        let docId = SearchStore.getDocList()[0];
        GetDocument(docId.toString());
      }
    }
  }

  loadDocumentFromDB() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let docId = parseInt(pathParts[pathParts.length - 1], 10);

    let params = new URLSearchParams(window.location.search);
    var related = params.get('related');

    if (related) {
      GetDocument(docId.toString(), related.toString());
    } else {
      GetDocument(docId.toString(), null);
    }
    GetAvailableTemplates(docId.toString());
  }

  onDocumentChange() {
    let lastChange = DocumentStore.lastChange();
    if (lastChange === 'LoadDoc') {
      let doc = DocumentStore.getCurrentDocuent();

      if (doc.canView) {
        console.log('loading');
        document.title = `TrinDocs - Doc:${doc.documentId}`
        let params = new URLSearchParams(window.location.search);

        // if (!related) {
        //   window.history.pushState(null, '', `/Document/${doc.documentId}`);
        // }
        let userCanEdit = doc.canEdit;
        let userCanSubmit = doc.canApprove;
        let userCanSendToReview = doc.canImageReview;
        let userCanAddNotes = doc.canAddNotes;
        let userCanDelete = doc.canDelete;
        let userCanMoveAssign = doc.canMoveAssign;
        GetImages(doc.documentId);
        this.setState({
          currentDoc: doc,
          currentImage: undefined,
          loadingImage: true,
          attachmentCount: doc.attachments.length,
          newNote: '',
        });
        if (doc.pinUserNotes) {
          let ivw = window.innerWidth - 280 - 20 - 280;

          this.setState({ showUserNotes: true, imageviewerWidth: ivw });
        }
        if (doc.topHeight) {
          let newH = doc.topHeight;
          let navH = document.getElementById('tdAppHeader').clientHeight;
          let menuH = document.getElementById('tdDocumentMenu').clientHeight;
          let related = document.getElementById('deRlatedDocs');

          let newTableHeight = window.innerHeight - newH - 239;
          let newB = window.innerHeight - newH - navH - menuH - 20;
          if (related) {
            newB = newB - related.clientHeight;
          }
          if (newB <= 8) newB = 8;
          this.setState({
            topHeight: newH,
            bottomHeight: newB,
            imageviewerHeight: newH - 32,
            tableHeight: newTableHeight,
          });
        }
        if (doc.fieldSets.length > 0) {

          this.setState({ currentFieldSet: doc.fieldSets[0] });

          let tw = 120;
          tw += 40;
          if (this.state.showChecks) tw += 60;
          doc.fieldSets[0].fieldDefs.forEach((fd) => (tw += fd.colWidth));
          tw += 20;
          //this.setState({tableWidth: tw});

          doc.fieldSets.forEach((fs) => {
            fs.rows.forEach((row) => {
              row.fieldData.forEach((field) => {
                let fieldDef = fs.fieldDefs.find(
                  (f) => f.fieldId === field.fieldId
                );
                field.isNew = false;
                field.isDeleted = false;
                field.fieldDef = fieldDef;
                field.error = '';
              });
            });
          });

          let rd = doc.fieldSets[0].rows;

          let nextRowId = 1;
          rd.forEach((row) => {
            if (row.rowId >= nextRowId) nextRowId = row.rowId + 1;
          });
          let newRow = {
            rowId: nextRowId,
            dbRowId: -1,
            isNew: true,
            isChecked: false,
            isDeleted: false,
            fieldData: [],
          };
          if (doc.fieldSets.length > 0) {
              doc.fieldSets[0].fieldDefs.forEach((fd) => {

                let newField = {
                  fieldId: fd.fieldId,
                  fieldDef: fd,
                  fieldValue: '',
                  displayOrder: fd.displayOrder,
                  fieldDataType: 0,
                  allowedValues: [],
                  directChildDependents: fd.directChildDependents,
                  error: '',
                };
                //
                if (fd.hasExtender) {
                  newField.allowedValues = fd.extender.allowedValues;
                }

                newRow.fieldData.push(newField);

            });

            newRow.extenderDependencies = [...doc.extenderDependencies];
            rd.push(newRow);
            this.executeExtenderRows();
            //this.ValidateDocument();
            //this.setState({ rowData: rd, fieldsHaveError: false });
          }

          this.setState({ rowData: rd, fieldsHaveError: false });


        }
        //let deps = doc.fieldSets[0].rowDependencies;
        // deps.forEach((dep) => {
        //   ExecuteExtender(
        //     dep.extenderId,
        //     this.getExtenderData(),
        //     '',
        //     dep.fieldId,
        //     doc.fieldSets[0].fieldSetId,
        //     newRow.rowId
        //   );
        // });

        //Set(doc.headerDependencies);
        if (doc.headerDependencies !== undefined) {
          doc.headerDependencies.forEach((hd) => {
            if (hd.hasExtender) {
              console.log(`Execute extender ${hd.fieldId}`);
              ExecuteExtender(
                hd.extenderId,
                this.getExtenderData(),
                '',
                hd.fieldId,
                0,
                'pageLoad',
                null,
                null,
                doc.documentId
              );
            }
          });
        }

        if(doc.fieldSets.length > 0)
        {

          //.log(rd);
        }

        // execute extenders for rows
        // let i = 0;
        // doc.fieldSets.forEach((fs) => {
        //   fs.rows.forEach((row) => {
        //       this.processExtenderRow(row, fs);
        //       i++;
        //   });
        //   // if (fs.rows.length > 0){
        //   //   let firstRow = fs.rows[0];
        //   //   this.processFirstExtenderRow(firstRow, fs);
        //   // }


        // });


        if (doc.checkedOutStatus === 'ImageReview') {
          userCanEdit = false;
          userCanSubmit = false;
          userCanSendToReview = false;

          this.setState({
            //userCanSubmit: false,
            //userCanEdit: false,
            lockedByMe: false,
            lockedByOther: false,
            //userCanSendToReview: false,
          });
        } else if (doc.checkedOutStatus === 'owner') {
          //this.setState({ userCanSubmit: true });
          //this.setState({ userCanEdit: true });
          this.setState({
            lockedByMe: true,
            lockedByOther: false,
            //userCanSendToReview: true,
          });
        } else if (doc.checkedOutStatus === 'other') {
          this.setState({
            lockedByMe: false,
            lockedByOther: true,
            checkedOutBy: doc.checkedOutUser,
            //userCanSendToReview: false,
            //userCanSubmit: false,
            //userCanEdit: false,
          });
          userCanSubmit = false;
          userCanEdit = false;
          userCanSendToReview = false;
        } else if (doc.checkedOutStatus === 'Queue') {
          this.setState({
            lockedByMe: false,
            lockedByOther: false,
            checkedOutBy: 'Nobody',
            //userCanSendToReview: false,
            //userCanSubmit: false,
            //userCanEdit: false,
          });
          userCanSubmit = false;
          userCanEdit = false;
          userCanSendToReview = false;
        }


        //this.setState({ isLoading: false });
        this.setState(
          {
            workflowName: doc.workflowName,
            formTypeName: doc.formTypeName,
            documentID: doc.documentId,
            stepName: doc.stepName,
            //isLoading: false,
          },
          this.initSnapshot()
        );


        this.setState(
          {
            userCanEdit: userCanEdit,
            userCanSubmit: userCanSubmit,
            userCanSendToReview: userCanSendToReview,
            userCanAddNotes: userCanAddNotes,
            userCanDelete: userCanDelete,
            userCanMoveAssign: userCanMoveAssign,
            currentDocId: doc.documentId,
          },
          this.focusFirstField //,
          // () => {
          //   let link = document.getElementById('nextDocLink');
          //   link.click();
          // }
        );

        this.setState({ isLoading: false, newNote: '', loadExtenderRows: true });
      } else {
        this.setState({
          preventViewing: true,
          documentID: doc.documentId,
          isLoading: false,
          newNote: '',
          loadExtenderRows: true
        });
      }
    }
    if (lastChange === actionTypes.ImageLoad) {
      try {
        var imageList = DocumentStore.getImages();
        var curImage = undefined;
        if (imageList !== undefined && imageList.length > 0) {
          curImage = imageList[0];
        }
        this.setState({
          images: imageList,
          currentImage: curImage,
          imageCount: imageList.length,
          loadingImage: false,
        });
        if (imageList.length > 1) {
          // load the second image in the background
          FetchImage(this.state.currentDoc.documentId, 2);
        }
      } catch {
        this.setState({
          currentImage: undefined,
          images: [],
          loadingImage: false,
        });
      }
    }
    if (lastChange === actionTypes.ImageFetch) {
      this.setState({ loadingImage: false });
      imageList = DocumentStore.getImages();
      this.setState({
        images: imageList,
        isRotatingLeft: false,
        isRotatingRight: false,
      });

      if (this.state.currentImage !== undefined && curImage !== undefined) {
        if (
          this.state.currentImage.imageBytes === null &&
          this.state.currentImage.pageId === curImage.pageId
        ) {
          curImage = imageList[curImage.pageId - 1];
          this.setState({ currentImage: curImage, loadingImage: false });
        }
      }
    }
    if (lastChange === actionTypes.DocumentSave) {
      this.setState({ isSaving: false }, this.initSnapshot());
      console.log(this.state.currentDoc);
      GetDocumentStatusInfo(this.state.currentDocId);

      GetAssignees(this.state.currentDocId);

      GetRelatedDocs(this.state.currentDocId);

      //GetDocument(this.state.currentDocId);
    }
    if (lastChange === actionTypes.DocumentSubmit) {
      let result = DocumentStore.getSubmitResult();
      console.log(result);
      if (result.status === 'Submitted' || result.status === 'Success'){
        this.initSnapshot();
            this.setState({ isSaving: false, isSubmitting: false }, () =>
          this.handleNextClick(true)
        );
      }
      else{
        this.setState({ isSubmitting: false, isSaving: false });
        if(result.status === 'Alert')
        {
          this.setState({showAlert: true, alertTitle: 'Failed to submit', alertMessage: result.message, alertNextOnClose: true});
        }
      }
      //this.handleNextClick();
    }
    if (lastChange === actionTypes.DocumentToImageReview) {
      this.handleNextClick(true);
    }
    if (lastChange === actionTypes.DocumentDelete) {
      this.handleNextClick(true);
      this.setState({ showDeleteConfirm: false });
    }
    if (lastChange === actionTypes.DocumentEndOfList) {
      var link = document.getElementById('backLink');
      link.click();
    }
    if (lastChange === actionTypes.DocumentAddNote) {

      var doc = this.state.currentDoc;
      var newNote = DocumentStore.getNote();
      doc.userNotes.splice(0, 0, newNote);
      this.setState({ currentDoc: doc });
    }

    if (lastChange === actionTypes.DocumentGetAudit) {
      let audit = DocumentStore.getAuditInfo();
      this.setState({ auditInfo: audit, isAuditLoading: false });
    }

    if (lastChange === actionTypes.DocumentGetAvailTemplates) {
      let templates = DocumentStore.getAvailTemplates();
      this.setState({ availableTemplates: templates });
    }
    if (lastChange === actionTypes.DocumentUpdateTemp) {
      let doc = this.state.currentDoc;
      doc.templateId = this.state.selectedTemplate.templateId;
      doc.templateName = this.state.selectedTemplate.templateName;
      this.setState({ showTemplateChange: false, inProgressAnimation: false, });
    }
    if (lastChange === actionTypes.DocumentUpdateTempWDoc) {
      let doc = DocumentStore.getCurrentDocuent();
      this.loadDocumentFromDB();
      this.setState({
        currentDoc: doc,
        showTemplateChange: false,
        inProgressAnimation: false,
      });
    }
    if (lastChange === actionTypes.DocumentUpdateMoveTemp) {
      this.setState({ showTemplateChange: false, inProgressAnimation: false, });
      this.initSnapshot();
      this.setState({ isSaving: false, isSubmitting: false }, () =>
          this.handleNextClick(true)
      );
    }
    if (lastChange === actionTypes.DocumentGetRelatedDocs) {
      let doc = this.state.currentDoc;
      let related = DocumentStore.getRelatedDocs();
      if(related.length > 0)
      {
        doc.hasRelatedDocs = true;
        doc.relatedDocuments = related;
      } else
      {
        doc.hasRelatedDocs = false;
        doc.relatedDocuments = [];
      }
      this.setState({currentDoc: doc});
    }

    if (lastChange === actionTypes.DocumentGetStatusInfo) {
      let doc = this.state.currentDoc;
      let info = DocumentStore.getDocStatus();
      doc.queueAssignments = [...info.assignments];
      let userCanEdit = info.canEdit;
      let userCanSubmit = info.canApprove;
      let userCanSendToReview = info.canImageReview;
      let userCanAddNotes = info.canAddNotes;
      let userCanDelete = info.canDelete;
      let userCanMoveAssign = info.canMoveAssign;
      doc.checkedOutStatus = info.checkOutStatus;

      this.setState({ currentDoc: doc, queuesDirty: false, isSaving: false });
      this.setState({
        userCanEdit: userCanEdit,
        userCanSubmit: userCanSubmit,
        userCanSendToReview: userCanSendToReview,
        userCanAddNotes: userCanAddNotes,
        userCanDelete: userCanDelete,
        userCanMoveAssign: userCanMoveAssign,
        currentDocId: doc.documentId,
      });

      if (doc.checkedOutStatus === 'ImageReview') {
        userCanEdit = false;
        userCanSubmit = false;
        userCanSendToReview = false;

        this.setState({
          //userCanSubmit: false,
          //userCanEdit: false,
          lockedByMe: false,
          lockedByOther: false,
          //userCanSendToReview: false,
        });
      } else if (doc.checkedOutStatus === 'owner') {
        //this.setState({ userCanSubmit: true });
        //this.setState({ userCanEdit: true });
        this.setState({
          lockedByMe: true,
          lockedByOther: false,
          //userCanSendToReview: true,
        });
      } else if (doc.checkedOutStatus === 'other') {
        this.setState({
          lockedByMe: false,
          lockedByOther: true,
          checkedOutBy: doc.checkedOutUser,
          //userCanSendToReview: false,
          //userCanSubmit: false,
          //userCanEdit: false,
        });
        userCanSubmit = false;
        userCanEdit = false;
        userCanSendToReview = false;
      } else if (doc.checkedOutStatus === 'Queue') {
        this.setState({
          lockedByMe: false,
          lockedByOther: false,
          checkedOutBy: 'Nobody',
          //userCanSendToReview: false,
          //userCanSubmit: false,
          //userCanEdit: false,
        });
        userCanSubmit = false;
        userCanEdit = false;
        userCanSendToReview = false;
      }
    }
    if (lastChange === actionTypes.DocumentChagneQueue) {
      let doc = DocumentStore.getCurrentDocuent();
      let userCanEdit = doc.canEdit;
      let userCanSubmit = doc.canApprove;
      let userCanSendToReview = doc.canImageReview;
      let userCanDelete = doc.canDelete;
      if (doc.checkedOutStatus === 'ImageReview') {
        userCanEdit = false;
        userCanSubmit = false;
        userCanSendToReview = false;

        this.setState({
          lockedByMe: false,
          lockedByOther: false,
        });
      } else if (doc.checkedOutStatus === 'owner') {
        this.setState({
          lockedByMe: true,
          lockedByOther: false,
        });
      } else if (doc.checkedOutStatus === 'other') {
        this.setState({
          lockedByMe: false,
          lockedByOther: true,
          checkedOutBy: doc.checkedOutUser,
        });
      } else if (doc.checkedOutStatus === 'Queue') {
        this.setState({
          lockedByMe: false,
          lockedByOther: false,
          checkedOutBy: 'Nobody',
        });

        userCanSubmit = false;
        userCanEdit = false;
        userCanSendToReview = false;
        userCanDelete = false;
      }
      this.setState({
        currentDoc: doc,
        userCanSubmit: userCanSubmit,
        userCanEdit: userCanEdit,
        userCanSendToReview: userCanSendToReview,
        userCanDelete: userCanDelete,
      });
    }
    if (lastChange === actionTypes.DocumentExtender) {
      let doc = this.state.currentDoc;
      let extId = DocumentStore.getLastExtenderId();
      let ext = DocumentStore.getExtender(extId);
      let header = doc.headerFields.find(
        (h) => h.hasExtender && h.extender.extenderId === extId
      );

      if (header) {
        // header.fieldValue = ext.defaultValue;
        // header.extenderKey = extId;
        header.extender.allowedValues = ext.allowedValues;
        let dep = this.findDependency(doc.headerDependencies, header.fieldId);

        let matchingKey = ext.allowedValues.find(
          (ex) =>
            //ex.extenderKey === header.extenderKey ||
            // ex.extenderKey === header.fieldValue ||
            ex.textValue === header.fieldValue
        );
        if (!matchingKey) {
          matchingKey = ext.allowedValues.find(
            (ex) => ex.extenderKey === header.extenderKey
            // ex.extenderKey === header.fieldValue ||
            //ex.textValue === header.fieldValue
          );
        }

        let matchingDefault = ext.allowedValues.find(
          (ex) =>
            //ex.extenderKey === header.extenderKey ||
            // ex.extenderKey === header.fieldValue ||
            ex.textValue === ext.defaultValue
        );

        header.error = '';
        let fieldEdit = false;
        if (header.fieldValue === '') {
          // if only one allowed Value,  go with it
          if (header.extender.allowedValues.length === 1) {
            header.fieldValue = ext.allowedValues[0].textValue;
            header.extenderKey = ext.allowedValues[0].extenderKey;
            fieldEdit = true;
          }
          //  else if there's a default
          else if (ext.defaultValue !== null) {
            matchingKey = ext.allowedValues.find(
              (ex) => ex.ExtenderKey === ext.defaultValue.toString()
            );
            if (matchingKey) {
              header.fieldValue = matchingKey.textValue;
              header.extenderKey = matchingKey.extenderKey;
              fieldEdit = true;
            } else {
              matchingKey = ext.allowedValues.find(
                (ex) => ex.textValue.toUpperCase() === ext.defaultValue.toUpperCase()
              );
              if (matchingKey) {
                header.fieldValue = matchingKey.textValue;
                header.extenderKey = matchingKey.extenderKey;
                fieldEdit = true;
              }
            }
          } else {
            // else just populate first one
            //header.fieldValue = header.extender.allowedValues[0].textValue;
            //header.extenderKey = header.extender.allowedValues[0].extenderKey;
            fieldEdit = true;
          }
        } else {
          // header is not blank
          let matched = false;
          if (matchingKey !== null && matchingKey !== undefined) {
            header.fieldValue = matchingKey.textValue;
            header.extenderKey = matchingKey.extenderKey;
            matched = true;

          } else if(matchingDefault !== null && matchingDefault !== undefined) {
            header.fieldValue = matchingDefault.textValue;
            header.extenderKey = matchingDefault.extenderKey;
            matched = true;

          } else if (
            header.extender.allowedValues.length === 1 &&
            header.extender.allowedValues[0].textValue !== ''
          ) {
            header.fieldValue = header.extender.allowedValues[0].textValue;
            header.extenderKey = header.extender.allowedValues[0].extenderKey;

            matched = true;
          } else {
            header.error = `Unknown value: ${header.fieldValue}`;

          }

          if(matched) {

            let hasRowchildren = false;
            doc.fieldSets.forEach((fs) => {
              fs.headerDependencies.forEach((hd) => {
                if (hd.fieldId === dep.fieldId) {
                  hasRowchildren = true;
                }
              });
              if(hasRowchildren) {
                fs.rows.forEach((row) => {
                  fs.rowDependencies.forEach((rd) => {
                    if (rd.hasExtender) {
                      this.processRowExtender(rd.fieldId, fs, row, doc, ext.exeSource);
                    }
                  });
                });
              }
            });
          }
        }



        // call child extenders
        let eData = this.getExtenderData();
        let u = eData.find((f) => f.fieldId === header.fieldId);
        if (u) {
          u.KeyValue = header.extenderKey;
        }
        if (dep.childNodes !== undefined && dep.childNodes.length > 0) {
          dep.childNodes.forEach((hd) => {
            if (hd.hasExtender) {
              //let d = this.getExtenderData();
              ExecuteExtender(
                hd.extenderId,
                eData,
                '',
                hd.fieldId,
                0,
                ext.exeSource,
                null,
                null,
                doc.documentId

              );
            }
          });
        }

        // call child rows
        doc.fieldSets.forEach((fs) => {
        });
        //if (fieldEdit) {

        //}
      }
      this.setState({ currentDoc: doc });
    }
    if (lastChange === actionTypes.DocumentExtenderRow) {
      let extId = DocumentStore.getLastRowExtenderId();
      let rowId = DocumentStore.getLastRowId();
      let key = DocumentStore.getLastKey();
      let ext = DocumentStore.getRowExtender(extId, key);
      let fieldSetId = DocumentStore.getLastFieldSet();
      let doc = this.state.currentDoc;
      if(ext.key !== undefined)
      {
        let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);
        //let row = fieldSet.rows.find((r) => r.rowId === rowId);


        let pending = _pendingKeys.find(pk => pk.extId === extId && pk.key === ext.key);
        if(pending !== null && pending !== undefined)
        {

          pending.pendingFields.forEach((pf) => {
            let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === pf.fieldSetId);
            let rows = fieldSet.rows;
            let row = rows.find((r) => r.rowId === pf.rowId);

            let column = row.fieldData.find(
              (f) => f.fieldDef.fieldId === pf.fieldId
            );
            if (column) {
              let dep = this.findDependency(fieldSet.rowDependencies, column.fieldId);
              column.allowedValues = [...ext.allowedValues];
              if(ext.defaultValue !== undefined && ext.defaultValue !== null)
              {
                column.defaultValue = ext.defaultValue;
              }
              let matchingRowKey = ext.allowedValues.find(
                (ex) =>
                  ex.textValue === column.fieldValue
              );

              if (column.fieldValue === '') {
                // field is currently blank
                if (column.allowedValues.length === 1) {

                  column.fieldValue = column.allowedValues[0].textValue;
                  column.extenderKey = column.allowedValues[0].extenderKey;
                }
                //  else if there's a default
                else if (ext.defaultValue !== undefined && ext.defaultValue !== null && column.allowedValues.length > 0) {

                  matchingRowKey = ext.allowedValues.find(
                    (ex) => ex.ExtenderKey === ext.defaultValue.toString() ||
                    ex.textValue === ext.defaultValue.toString()
                  );
                  if (matchingRowKey) {
                    console.log('set default');
                    column.fieldValue = matchingRowKey.textValue;
                    column.extenderKey = matchingRowKey.extenderKey;
                  }
                } else {
                  // else just populate first one
                  //column.fieldValue = column.allowedValues[0].textValue;
                  //column.extenderKey = column.allowedValues[0].extenderKey;
                  //blankit
                }
              } else {
                // header is not blank
                let matchingRowKey = column.allowedValues.find(
                  (ex) =>
                    (ex.textValue === column.fieldValue)
                );
                if(matchingRowKey === undefined && column.allowedValues.length === 1)
                {
                  // no match, but one result... override
                  column.fieldValue = column.allowedValues[0].textValue;
                  column.extenderKey = column.allowedValues[0].extenderKey;
                  column.error = '';
                  //column.error = `Unknown value "${column.fieldValue}"`;
                } else if(matchingRowKey !== undefined)
                {
                  // we have a matching text value,  make sure the extKey is uptodate
                  column.extenderKey = matchingRowKey.extenderKey;
                  column.error = '';

                }else
                {
                  // we have a garbage value, make it red
                  column.error = `Unknown value "${column.fieldValue}"`;
                }

              }

              if (column.fieldValue !== '' && column.error === '') {
                // call child extenders
                //let eData = this.getExtenderData(row);
                //let ed = this.getExtenderData2(d.fieldId, fieldSet.depMap, fieldSet.fieldSetId, row.fieldData, doc.depMap, doc.headerFields);
                if(dep.childNodes.length > 0) {
                  dep.childNodes.forEach((d) => {
                    if(d.hasExtender) {
                      this.processRowExtender(d.fieldId, fieldSet, row, doc, ext.exeSource)
                    }
                  });

                }

              //   let u = eData.find((f) => f.fieldId === column.fieldId);
              //   if (u) {
              //     u.KeyValue = column.extenderKey;
              //   }
              //   if (dep.childNodes !== undefined && dep.childNodes.length > 0) {
              //     dep.childNodes.forEach((hd) => {
              //       if (hd.hasExtender) {
              //         //let d = this.getExtenderData();

              //         ExecuteExtender(
              //           hd.extenderId,
              //           eData,
              //           '',
              //           hd.fieldId,
              //           fieldSetId,
              //           ext.exeSource,
              //           row.rowId
              //         );
              //       }
              //     });
              //   }
              // }
              }
            }
          });
        }
        _existingKeys.push({
          extId: pending.extId,
          key: pending.key,
          allowedValues: [...ext.allowedValues]
        });
        let idx = _pendingKeys.indexOf(pending);
        _pendingKeys.splice(idx, 1);
      }

      // let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);
      // let rows = fieldSet.rows;
      // let row = rows.find((r) => r.rowId === rowId);


      // let column = row.fieldData.find(
      //   (f) => f.fieldDef.fieldId === ext.fieldId
      // );

      // if (column) {
      //   let dep = this.findDependency(fieldSet.rowDependencies, column.fieldId);
      //   column.allowedValues = [...ext.allowedValues];

      //     if (!column.extenderKey) {
      //       column.extenderKey = '-1';
      //     }

      //     let matchingRowKey = ext.allowedValues.find(
      //       (ex) =>
      //         ex.ExtenderKey === column.extenderKey.toString() ||
      //         ex.ExtenderKey === column.fieldValue ||
      //         ex.textValue === column.fieldValue
      //     );

      //     if (column.fieldValue === '') {
      //       // field is currently blank

      //       if (column.allowedValues.length === 1) {
      //         column.fieldValue = column.allowedValues[0].textValue;
      //         column.extenderKey = column.allowedValues[0].extenderKey;
      //       }
      //       //  else if there's a default
      //       else if (ext.defaultValue !== null) {
      //         matchingRowKey = ext.allowedValues.find(
      //           (ex) => ex.ExtenderKey === ext.defaultValue.toString()
      //         );
      //         if (matchingRowKey) {
      //           column.fieldValue = matchingRowKey.textValue;
      //           column.extenderKey = matchingRowKey.extenderKey;
      //         }
      //       } else {
      //         // else just populate first one
      //         //column.fieldValue = column.allowedValues[0].textValue;
      //         //column.extenderKey = column.allowedValues[0].extenderKey;
      //         //blankit
      //       }
      //     } else {
      //       // header is not blank
      //       if (matchingRowKey !== null && matchingRowKey !== undefined) {
      //         column.fieldValue = matchingRowKey.textValue;
      //         column.extenderKey = matchingRowKey.extenderKey;
      //         column.textValue = matchingRowKey.textValue;
      //         column.error = '';
      //       } else if (
      //         column.allowedValues.length === 1 &&
      //         column.allowedValues[0].textValue
      //       ) {
      //         column.fieldValue = column.allowedValues[0].textValue;
      //         column.textValue = column.allowedValues[0].textValue;
      //         column.extenderKey = column.allowedValues[0].extenderKey;
      //         column.error = '';
      //       } else {
      //         if (ext.exeSource === 'pageLoad') {
      //           this.errorRowField(
      //             fieldSet.fieldSetId,
      //             row.rowId,
      //             column.fieldId
      //           );
      //         } else {
      //           this.blankRowField(
      //             fieldSet.fieldSetId,
      //             row.rowId,
      //             column.fieldId
      //           );
      //         }
      //       }
      //     }
      //     this.setState({ currentDoc: doc });

      //     if (column.fieldValue !== '' && column.error === '') {
      //       // call child extenders
      //       let eData = this.getExtenderData(row);

      //       let u = eData.find((f) => f.fieldId === column.fieldId);
      //       if (u) {
      //         u.KeyValue = column.extenderKey;
      //       }
      //       if (dep.childNodes !== undefined && dep.childNodes.length > 0) {
      //         dep.childNodes.forEach((hd) => {
      //           if (hd.hasExtender) {
      //             //let d = this.getExtenderData();

      //             ExecuteExtender(
      //               hd.extenderId,
      //               eData,
      //               '',
      //               hd.fieldId,
      //               fieldSetId,
      //               ext.exeSource,
      //               row.rowId
      //             );
      //           }
      //         });
      //       }
      //     }

      // }
      this.setState({ currentDoc: doc });
    }
    if (lastChange === actionTypes.DocumentFirstExtenderRow) {
      let extId = DocumentStore.getLastRowExtenderId();
      let rowId = DocumentStore.getLastRowId();
      let key = DocumentStore.getLastKey();
      let ext = DocumentStore.getRowExtender(extId, key);

      let fieldSetId = DocumentStore.getLastFieldSet();
      let doc = this.state.currentDoc;

      let independentExtenders = DocumentStore.getIndependentRowExtenders();
      let independentExtender = independentExtenders.find((fs) => fs.extenderId === extId);

      let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);
      let rows = fieldSet.rows;
      let row = rows.find((r) => r.rowId === rowId);

      if (independentExtender !== undefined){
        let i = 0;
        // for this field set only
        //let fs = doc.fieldSets.find((f) => f.fieldSetId === fieldSetId);
          fieldSet.rows.forEach((r) => {
            i++;
            if (i > 1){
              let col = r.fieldData.find(
                (f) => f.fieldDef.fieldId === ext.fieldId
              );
              col.allowedValues = [...independentExtender.allowedValues];
            }

          });
      }



      if (row.isNew) {
      }
      if (!row.isNew) {
      }
      let column = row.fieldData.find(
        (f) => f.fieldDef.fieldId === ext.fieldId
      );

      if (column) {
        let dep = this.findDependency(fieldSet.rowDependencies, column.fieldId);
        column.allowedValues = [...ext.allowedValues];

        if (!row.isNew) {
          if (!column.extenderKey) {
            column.extenderKey = '-1';
          }

          let matchingRowKey = ext.allowedValues.find(
            (ex) =>
              ex.ExtenderKey === column.extenderKey.toString() ||
              ex.ExtenderKey === column.fieldValue ||
              ex.textValue === column.fieldValue
          );

          if (column.fieldValue === '') {
            // field is currently blank

            if (column.allowedValues.length === 1) {
              column.fieldValue = column.allowedValues[0].textValue;
              column.extenderKey = column.allowedValues[0].extenderKey;
            }
            //  else if there's a default
            else if (ext.defaultValue !== null) {
              matchingRowKey = ext.allowedValues.find(
                (ex) => ex.ExtenderKey === ext.defaultValue.toString()
              );
              if (matchingRowKey) {
                column.fieldValue = matchingRowKey.textValue;
                column.extenderKey = matchingRowKey.extenderKey;
              }
            } else {
              // else just populate first one
              //column.fieldValue = column.allowedValues[0].textValue;
              //column.extenderKey = column.allowedValues[0].extenderKey;
              //blankit
            }
          } else {
            // header is not blank
            if (matchingRowKey !== null && matchingRowKey !== undefined) {
              column.fieldValue = matchingRowKey.textValue;
              column.extenderKey = matchingRowKey.extenderKey;
              column.textValue = matchingRowKey.textValue;
              column.error = '';
            } else if (
              column.allowedValues.length === 1 &&
              column.allowedValues[0].textValue
            ) {
              column.fieldValue = column.allowedValues[0].textValue;
              column.textValue = column.allowedValues[0].textValue;
              column.extenderKey = column.allowedValues[0].extenderKey;
              column.error = '';
            } else {
              if (ext.exeSource === 'pageLoad') {
                this.errorRowField(
                  fieldSet.fieldSetId,
                  row.rowId,
                  column.fieldId
                );
              } else {
                this.blankRowField(
                  fieldSet.fieldSetId,
                  row.rowId,
                  column.fieldId
                );
              }
            }
          }
          this.setState({ currentDoc: doc });

          if (column.fieldValue !== '' && column.error === '') {
            // call child extenders
            let eData = this.getExtenderData(row);



            let u = eData.find((f) => f.fieldId === column.fieldId);
            if (u) {
              u.KeyValue = column.extenderKey;
            }
            if (dep.childNodes !== undefined && dep.childNodes.length > 0) {

              dep.childNodes.forEach((hd) => {
                if (hd.hasExtender) {
                  //let d = this.getExtenderData();
                  let i = 0;
                  fieldSet.rows.forEach((r) => {
                    i++;
                    let r2 = rows.find((r) => r.rowId === i);
                    let eData2 = this.getExtenderData(r2);
                    ExecuteExtender(
                      hd.extenderId,
                      eData2,
                      '',
                      hd.fieldId,
                      fieldSetId,
                      ext.exeSource,
                      i,
                      null,
                      doc.documentId
                    );
                  });
                }

              });
            }
          }
        }
      }
      this.setState({ currentDoc: doc });
    }
    if (lastChange === actionTypes.DocumentPull || lastChange === actionTypes.DocumentUndelete) {
      let pullData = DocumentStore.getPullData();
      let doc = this.state.currentDoc;
      doc.checkedOutUser = pullData.checkOutUser;
      doc.checkedOutStatus = pullData.checkedOutStatus;
      this.setState({
        checkedOutBy: pullData.checkOutUser,
        currentDoc: doc,
        lockedByMe: true,
        lockedByOther: false,
      });
      this.setState({
        userCanEdit: pullData.canEdit,
        userCanSubmit: pullData.canApprove,
        userCanSendToReview: pullData.canImageReview,
        userCanDelete: pullData.canDelete,
        currentDocId: doc.documentId,
      });
    }
    if (lastChange === 'ALTER_ATTCH') {
      let att = DocumentStore.getAttachments();
      let doc = this.state.currentDoc;
      doc.attachments = att;
      this.setState({ currentDoc: doc, attachmentCount: att.length });
    }
    if (lastChange === actionTypes.DocumentAssignUsers) {
      let assUsers = DocumentStore.getMoveAssignUsers();
      this.setState({ moveAssignUsers: assUsers.assignableUsers });
    }
    //Change bp is the only thing that uses Bulk Action
    if (lastChange === actionTypes.BulkAction) {
      // bp will have just changed, reload doc

      this.loadDocumentFromDB();
    }

    if (lastChange === actionTypes.DocumentGetAssignees) {
      let assignees = DocumentStore.getAssignees();
      let doc = this.state.currentDoc;

      doc.assignableUsers = assignees;
      this.setState({ currentDoc: doc });
      GetDocumentStatusInfo(this.state.currentDocId);
    }

    if (lastChange === actionTypes.GetDocumentZoomSetting) {
      let zoom = DocumentStore.getZoomSetting();
      this.setState({ documentZoomSetting: zoom });
    }
  }

  onpopstate() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let docId = parseInt(pathParts[pathParts.length - 1], 10);

    let params = new URLSearchParams(window.location.search);
    var related = params.get('related');
    if(related !== null && this.state.currentDocId !== related)
    {
      console.log('go to other related');
      GetDocument(docId.toString(), related.toString());
    }
    if(related === null && this.state.currentDoc.originalDocId !== this.state.currentDocId && !isNaN(docId))
    {
      console.log('go back to main');
      GetDocument(docId.toString(), null);
    }
  }

  findDependency(deps, fieldId) {
    console.log(fieldId);
    console.log(deps);
    let res = null;
    deps.forEach((d) => {
      if (d.fieldId === fieldId) {
        res = d;
      }
      if (d.childNodes !== undefined && d.childNodes.length > 0) {
        let n = this.findDependency(d.childNodes, fieldId);
        if (n !== null) res = n;
      }
    });
    return res;
  }

  blankHeaderField(fieldId, clearVals) {
    let doc = this.state.currentDoc;
    let dep = this.findDependency(doc.headerDependencies, fieldId);
    let field = doc.headerFields.find((f) => f.fieldId === fieldId);
    field.extenderKey = '-1';
    field.fieldValue = '';
    field.error = '';
    if (clearVals) {
      field.extender.allowedValues = [];
    }

    if (dep.hasExtender) {
      dep.childNodes.forEach((node) => {
        this.blankHeaderField(node.fieldId, true);
      });
    }
  }

  blankRowField(fieldSetId, rowId, fieldId) {
    let doc = this.state.currentDoc;

    let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);

    let row = fieldSet.rows.find((r) => r.rowId === rowId);
    if (row) {
      let dep = this.findDependency(fieldSet.rowDependencies, fieldId);
      let field = row.fieldData.find((f) => f.fieldId === fieldId);
      if (field) {
        field.extenderKey = '-1';
        field.fieldValue = '';
        field.error = '';

        if (dep) {
          dep.childNodes.forEach((node) => {
            this.blankRowField(fieldSetId, rowId, node.fieldId);
          });
        }
      }
    }
  }

  errorRowField(fieldSetId, rowId, fieldId) {
    let doc = this.state.currentDoc;

    let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);

    let row = fieldSet.rows.find((r) => r.rowId === rowId);
    if (row) {
      let dep = this.findDependency(fieldSet.rowDependencies, fieldId);
      let field = row.fieldData.find((f) => f.fieldId === fieldId);
      if (field) {
        if (field.fieldValue !== '') {
          field.error = `Invalid value: ${field.fieldValue}`;
          field.keyValue = '';
        }

        if (dep) {
          dep.childNodes.forEach((node) => {
            this.errorRowField(fieldSetId, rowId, node.fieldId);
          });
        }
      }
      this.setState({ currentDoc: doc });
    }
  }

  getExtenderData(row) {
    let extenderData = [];
    let doc = this.state.currentDoc;
    doc.headerFields.forEach((head) => {
      if (head.hasExtender) {
        extenderData.push({
          fieldId: head.fieldId,
          KeyValue: head.extenderKey,
        });
      }
    });
    if (row) {
      row.fieldData.forEach((field) => {
        if (field.fieldDef) {
          if (field.fieldDef.hasExtender) {
            extenderData.push({
              fieldId: field.fieldId,
              KeyValue:
                field.extenderKey !== undefined ? field.extenderKey : '',
            });
          }
        }
      });
    }

    return extenderData;
  }

  getExtenderData2(fieldId, rowFieldMap, rowFieldSetId, rowFieldData, docFieldMap, docFieldData) {
    let results = [];
    let dep2 = rowFieldMap.filter(m => m.fieldId === fieldId);
    if(dep2 !== undefined && dep2.length > 0)
    {
      dep2.forEach(d => {
        if(d.depFieldSetId === rowFieldSetId)
        {
          let fd = rowFieldData.find(f => f.fieldId === d.depFieldId);
          if(fd !== undefined)
          {
            results.push({
              fieldId: fd.fieldId,
              value: fd.fieldValue,
              key: fd.extenderKey,
            });

            //  any more dependencies
            let moreDep = rowFieldMap.filter(m => m.fieldId === fd.fieldId);
            moreDep.forEach(d2 => {
              let moreResults = this.getExtenderData2(fd.fieldId, rowFieldMap, rowFieldSetId, rowFieldData, docFieldMap, docFieldData);
              moreResults.forEach(mr => {
                let preExist = results.find(m => m.fieldId === mr.fieldId);
                if(preExist === undefined)
                {
                  results.push(mr);
                }
              });
            });
          }
        } else {

          let fd = docFieldData.find(f => f.fieldId === d.depFieldId);

          if(fd !== undefined)
          {
            //let res = this.getHeaderExtenderData(fd.fieldId, docFieldMap, docFieldData);
            //console.log(res);
             results.push({
               fieldId: fd.fieldId,
               value: fd.fieldValue,
               key: fd.extenderKey,
             });

            // console.log(results);

            // //  any more dependencies
            let moreDep = this.getHeaderExtenderData(fd.fieldId, docFieldMap, docFieldData);
            if(moreDep.length > 0)
              {
                moreDep.forEach(mr => {
                  let preExist = results.find(m => m.fieldId === mr.fieldId);
                  if(preExist === undefined)
                  {
                    results.push(mr);
                  }
                });
              }

          }

        }
      });
    }

    results.sort((a, b) => a.fieldId - b.fieldId);
    return results;
  }

  getHeaderExtenderData(fieldId, docFieldMap, docFieldData)
  {

    let result = [];
    docFieldMap.forEach(fm => {
      if(fm.fieldId === fieldId)
        {

          let fd = docFieldData.find(d => d.fieldId === fm.depFieldId);

          result.push({
            fieldId: fd.fieldId,
            value: fd.fieldValue,
            key: fd.extenderKey,
          });

          let moreDeps = this.getHeaderExtenderData(fm.depFieldId, docFieldMap, docFieldData);
          if(moreDeps.length > 0)
            {
              moreDeps.forEach(mr => {
                let preExist = result.find(m => m.fieldId === mr.fieldId);
                if(preExist === undefined)
                {
                  result.push(mr);
                }
              });
            }
        }
    });

    return result;

  }




  executeExtenderRows(){
    console.log('executeExtenderRows');
    let doc = DocumentStore.getCurrentDocuent();
            // execute extenders for rows
      //let i = 0;
      doc.fieldSets.forEach((fs) => {
        fs.rows.forEach((row) => {
          //if(!row.isNew) {
            this.processExtenderRow2(row, fs);
            //i++;
          //}
        });
        // if (fs.rows.length > 0){
        //   let firstRow = fs.rows[0];
        //   this.processFirstExtenderRow(firstRow, fs);
        // }


      });

  }

  processExtenderRow2(row, fieldSet) {
    if (fieldSet.rowDependencies !== undefined) {
      let doc = this.state.currentDoc;
      let dep = [...fieldSet.rowDependencies];
      dep.forEach((d) => {
        if (d.hasExtender) {

            let ed = this.getExtenderData2(d.fieldId, fieldSet.depMap, fieldSet.fieldSetId, row.fieldData, doc.depMap, doc.headerFields);
            if(ed !== undefined && ed !== null)
            {
              let fullKey = '';
              let extData = [];
              ed.forEach((keyPart) => {
                fullKey = fullKey + `{${keyPart.fieldId}:${keyPart.value}}`;
                extData.push({
                  fieldId: keyPart.fieldId,
                  keyValue: keyPart.key,
                });
              });
              let cache = DocumentStore.getRowExtender(d.extenderId, fullKey);
              if(cache !== undefined)
              {
                let column = row.fieldData.find(
                  (f) => f.fieldId === d.fieldId
                );
                if(column)
                {
                  column.allowedValues = [...cache.allowedValues];
                  let hasMatch = false;
                  if (column.fieldValue === '')
                  {
                    if (column.allowedValues.length === 1)
                    {
                      column.fieldValue = column.allowedValues[0].textValue;
                      column.extenderKey = column.allowedValues[0].extenderKey;
                      column.error = '';
                      hasMatch = true;
                    }
                  }
            else {
              let matchingRowKey = cache.allowedValues.find(
                (ex) =>
                  (ex.textValue === column.fieldValue)
              );
              if(matchingRowKey === undefined && cache.allowedValues.length === 1)
              {
                // no match, but one result... override
                column.fieldValue = column.allowedValues[0].textValue;
                column.extenderKey = column.allowedValues[0].extenderKey;
                column.error = '';
                hasMatch = true;
                //column.error = `Unknown value "${column.fieldValue}"`;
              }  else if(matchingRowKey !== undefined)
              {
                // we have a matching text value,  make sure the extKey is uptodate
                column.extenderKey = matchingRowKey.extenderKey;
                column.error = '';
                hasMatch = true;
              }
              else
              {
                // we have a garbage value, make it red
                column.error = `Unknown value "${column.fieldValue}"`;
              }
            }

            if(hasMatch)
            {
              let dep = this.findDependency(fieldSet.rowDependencies, column.fieldId);
              if(dep.childNodes.length > 0) {
                dep.childNodes.forEach((d) => {
                  if(d.hasExtender) {
                    this.processRowExtender(d.fieldId, fieldSet, row, doc, 'loadDoc')
                  }
                });

              }
            }
                }
              }
              else {
                let existingKey = _existingKeys.find((f) => f.extId === d.extenderId && f.key === fullKey);
                if(existingKey === undefined)
                {
                  // check pending keys
                  let pendingKey = _pendingKeys.find((f) => f.extId === d.extenderId && f.key === fullKey);
                  if(pendingKey === undefined)
                  {
                    // create new pending key
                    _pendingKeys.push({
                      extId: d.extenderId,
                      key: fullKey,
                      pendingFields: [
                        {
                          rowId: row.rowId,
                          fieldId: d.fieldId,
                          fieldSetId: fieldSet.fieldSetId,
                        }
                      ]
                    });
                    console.log(fullKey);
                    ExecuteExtender(
                      d.extenderId,
                      extData,
                      '',
                      d.fieldId,
                      fieldSet.fieldSetId,
                      'pageLoad',
                      row.rowId,
                      fullKey,
                      doc.documentId
                    );
                  } else {
                    pendingKey.pendingFields.push({
                      rowId: row.rowId,
                      fieldId: d.fieldId,
                      fieldSetId: fieldSet.fieldSetId,
                    });
                  }
                } else {
                  // map existing key;
                }
              }

              // locate in existing keys

            }
        }
      });

    }
  }

  processRowExtender(fieldId, fieldSet, row, doc, source) {
    let d = this.findDependency(fieldSet.rowDependencies, fieldId);
    let ed = this.getExtenderData2(fieldId, fieldSet.depMap, fieldSet.fieldSetId, row.fieldData, doc.depMap, doc.headerFields);
    if(ed !== undefined && ed !== null)
    {
      let fullKey = '';
      let extData = [];
      ed.forEach((keyPart) => {
        fullKey = fullKey + `{${keyPart.fieldId}:${keyPart.value}}`;
        extData.push({
          fieldId: keyPart.fieldId,
          keyValue: keyPart.key,
        });
      });
      let existingKey = _existingKeys.find((f) => f.extId === d.extenderId && f.key === fullKey);

      let cache = DocumentStore.getRowExtender(d.extenderId, fullKey);

      if(cache !== undefined)
      {
        if(true)
        {
          let column = row.fieldData.find(
            (f) => f.fieldDef.fieldId === fieldId
          );
          if(column)
          {
            column.allowedValues = [...cache.allowedValues];

            let hasMatch = false;
            if (column.fieldValue === '')
            {
              if (column.allowedValues.length === 1)
              {
                column.fieldValue = column.allowedValues[0].textValue;
                column.extenderKey = column.allowedValues[0].extenderKey;
                column.error = '';
                hasMatch = true;
              }
              else if (cache.defaultValue !== undefined && cache.defaultValue !== null && column.allowedValues.length > 0) {

                let matchingRowKey = cache.allowedValues.find(
                  (ex) => ex.ExtenderKey === cache.defaultValue.toString() ||
                  ex.textValue === cache.defaultValue.toString()
                );
                if (matchingRowKey) {
                  column.fieldValue = matchingRowKey.textValue;
                  column.extenderKey = matchingRowKey.extenderKey;
                }
              }
            }
            else {
              let matchingRowKey = cache.allowedValues.find(
                (ex) =>
                  (ex.textValue === column.fieldValue)
              );
              if(matchingRowKey === undefined && cache.allowedValues.length == 1)
              {
                // no match, but one result... override
                column.fieldValue = column.allowedValues[0].textValue;
                column.extenderKey = column.allowedValues[0].extenderKey;
                column.error = '';
                hasMatch = true;
                //column.error = `Unknown value "${column.fieldValue}"`;
              }  else if(matchingRowKey !== undefined)
              {
                // we have a matching text value,  make sure the extKey is uptodate
                column.extenderKey = matchingRowKey.extenderKey;
                column.error = '';
                hasMatch = true;

              }
              else
              {
                // we have a garbage value, make it red
                column.error = `Unknown value "${column.fieldValue}"`;
              }
            }

            if(hasMatch)
            {
              let dep = this.findDependency(fieldSet.rowDependencies, column.fieldId);
              if(dep.childNodes.length > 0) {
                dep.childNodes.forEach((d) => {
                  if(d.hasExtender) {
                    this.processRowExtender(d.fieldId, fieldSet, row, doc, source)
                  }
                });

              }
            }
          }
        }

      }



      if(cache === undefined)
      {
        // check pending keys
        let pendingKey = _pendingKeys.find((f) => f.extId === d.extenderId && f.key === fullKey);

        if(pendingKey === undefined)
        {
          // create new pending key
          _pendingKeys.push({
            extId: d.extenderId,
            key: fullKey,
            pendingFields: [
              {
                rowId: row.rowId,
                fieldId: d.fieldId,
                fieldSetId: fieldSet.fieldSetId,
              }
            ]
          });

          ExecuteExtender(
            d.extenderId,
            extData,
            '',
            d.fieldId,
            fieldSet.fieldSetId,
            source,
            row.rowId,
            fullKey,
            doc.documentId
          );
        } else {
          pendingKey.pendingFields.push({
            rowId: row.rowId,
            fieldId: d.fieldId,
            fieldSetId: fieldSet.fieldSetId,
          });
        }
      }

      // locate in existing keys

    }
  }

  processExtenderRow(row, fieldSet) {
    console.log('processRow');
    this.state.currentFieldSet.fieldDefs.forEach((fd) => {
      if(fd.hasExtender)
      {
        let sibDependency = false;
          let d = this.state.currentFieldSet.depMap.filter(d2=> d2.fieldId === fd.fieldId);

          if(d.length > 0)
          {
            d.forEach((d2) => {
              let sib = this.state.currentFieldSet.fieldDefs.find(s => s.fieldId === d2.depFieldId);

              if(sib !== undefined)
              {
                sibDependency = true;
              }
            });
          }
          if(!sibDependency)
          {
            this.processRowExtender(fd.fieldId, this.state.currentFieldSet, row, this.state.currentDoc, 'userEdit');
          }
      }
    });
  }
  //   if (fieldSet.rowDependencies !== undefined) {
  //     let dep = [...fieldSet.rowDependencies];
  //     dep.forEach((d) => {
  //       let ed = this.getExtenderData(row);
  //       // if (header) {
  //       //   let u = ed.find((f) => f.fieldId === header.fieldId);
  //       //   if (u) {
  //       //     u.KeyValue = header.extenderKey;
  //       //   }
  //       // }
  //       if (d.hasExtender) {
  //         // the only way it works is to look for the cache here

  //         let cachedValue = this.lookForExtenderCache(d.extenderId, ed);
  //         if (cachedValue.length > 0){
  //           this.cachedDocumentExtenderRow(d.extenderId, row.rowId, fieldSet.fieldSetId, cachedValue);
  //         }
  //         else{
  //           ExecuteExtender(
  //             d.extenderId,
  //             ed,
  //             '',
  //             d.fieldId,
  //             fieldSet.fieldSetId,
  //             'pageLoad',
  //             row.rowId,
  //             null,
  //             this.state.currentDoc.documentId
  //           );
  //         }

  //       }
  //     });
  //   }
  // }


  lookForExtenderCache(extenderId, extenderData){

    let ext = {
      extenderId: extenderId,
      dataSourceValue: null,
      parentFields: [],
    };
      let ext2 = {
      cache: ext
    }
    let parentId = DocumentStore.getParentIdFromCache(ext2);

    if (parentId.length > 0 && parentId[0].cache.parentFields !== undefined){

      for (let i = 0; i < parentId[0].cache.parentFields.length; i++){

        let parentField = parentId[0].cache.parentFields[i];

        let parentValue = extenderData.find((dep) => dep.fieldId === parentField.parentId);

        let pf = {
          parentId: parentId[0].cache.parentFields[i].parentId,
          parentValue: parentValue.KeyValue
        };

        ext2.cache.parentFields.push(pf);
      }


      // if (parentValue !== undefined){
      //   ext2.cache.parentValue = parentValue.KeyValue;
      // }

    }
    else{
    }

    let cachedValue = DocumentStore.getExtenderFromCache(ext2);

    return cachedValue;
  }

  cachedDocumentExtenderRow(extId, rowId, fieldSetId, cachedValue){


    let ext = DocumentStore.getRowExtender(extId);

    ext = cachedValue[0];

    let doc = this.state.currentDoc;

    let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);
    let rows = fieldSet.rows;
    let row = rows.find((r) => r.rowId === rowId);

    let column = row.fieldData.find(
      (f) => f.fieldDef.fieldId === ext.fieldId
    );

    if (column) {
      let dep = this.findDependency(fieldSet.rowDependencies, column.fieldId);
      column.allowedValues = [...ext.allowedValues];

        if (!column.extenderKey) {
          column.extenderKey = '-1';
        }

        let matchingRowKey = ext.allowedValues.find(
          (ex) =>
            ex.ExtenderKey === column.extenderKey.toString() ||
            ex.ExtenderKey === column.fieldValue ||
            ex.textValue === column.fieldValue
        );

        if (column.fieldValue === '') {
          // field is currently blank

          if (column.allowedValues.length === 1) {
            column.fieldValue = column.allowedValues[0].textValue;
            column.extenderKey = column.allowedValues[0].extenderKey;
          }
          //  else if there's a default
          else if (ext.defaultValue !== null) {
            matchingRowKey = ext.allowedValues.find(
              (ex) => ex.ExtenderKey === ext.defaultValue.toString()
            );
            if (matchingRowKey) {
              column.fieldValue = matchingRowKey.textValue;
              column.extenderKey = matchingRowKey.extenderKey;
            }
          } else {
            // else just populate first one
            //column.fieldValue = column.allowedValues[0].textValue;
            //column.extenderKey = column.allowedValues[0].extenderKey;
            //blankit
          }
        } else {
          // header is not blank
          if (matchingRowKey !== null && matchingRowKey !== undefined) {
            column.fieldValue = matchingRowKey.textValue;
            column.extenderKey = matchingRowKey.extenderKey;
            column.textValue = matchingRowKey.textValue;
            column.error = '';
          } else if (
            column.allowedValues.length === 1 &&
            column.allowedValues[0].textValue
          ) {
            column.fieldValue = column.allowedValues[0].textValue;
            column.textValue = column.allowedValues[0].textValue;
            column.extenderKey = column.allowedValues[0].extenderKey;
            column.error = '';
          } else {
            if (ext.exeSource === 'pageLoad') {
              this.errorRowField(
                fieldSet.fieldSetId,
                row.rowId,
                column.fieldId
              );
            } else {
              this.blankRowField(
                fieldSet.fieldSetId,
                row.rowId,
                column.fieldId
              );
            }
          }
        }
        this.setState({ currentDoc: doc });

        if (column.fieldValue !== '' && column.error === '') {
          // call child extenders
          let eData = this.getExtenderData(row);

          let u = eData.find((f) => f.fieldId === column.fieldId);
          if (u) {
            u.KeyValue = column.extenderKey;
          }
          if (dep.childNodes !== undefined && dep.childNodes.length > 0) {
            dep.childNodes.forEach((hd) => {
              if (hd.hasExtender) {
                //let d = this.getExtenderData();


          let cachedValue = this.lookForExtenderCache(hd.extenderId, eData);
          if (cachedValue.length > 0){
            this.cachedDocumentExtenderRow(hd.extenderId, row.rowId, fieldSetId, cachedValue);
          }
          else{
            ExecuteExtender(
              hd.extenderId,
              eData,
              '',
              hd.fieldId,
              fieldSetId,
              ext.exeSource,
              row.rowId,
              null,
              doc.documentId

            );
          }

              }
            });
          }
        }

    }

    this.setState({ currentDoc: doc });
  }

  processFirstExtenderRow(row, fieldSet) {
    console.log('processFirstRow');
      if (fieldSet.rowDependencies !== undefined) {
        let dep = [...fieldSet.rowDependencies];
        dep.forEach((d) => {
          let ed = this.getExtenderData(row);
          if (d.hasExtender) {
            ExecuteExtender3(
              d.extenderId,
              ed,
              '',
              d.fieldId,
              fieldSet.fieldSetId,
              'pageLoad',
              row.rowId
            );
          }
        });
      }

  }

  windowMouseLeave() {
    if (this.state.isDraggingSplitBar) {
      this.setState({ isDraggingSplitBar: false });
      UpdateSetting('doc_image_height', this.state.topHeight.toString());
    }
  }

  onBulkChange() {
    let lastChange = BulkActionStore.getLastChange();
    if (lastChange === actionTypes.DocumentGetAvailTemplatesByBp2) {
      let templates = BulkActionStore.getWorkflowAvailableTemplatesResults();
      this.setState({ availableTemplates2: templates });
      return;
    }
  }

  beforeUnloadListener(event) {
    if (this.state.snapshots.length > 1) {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to exit?';
    }
  }

  getFldFromFldDef(fd) {
    let newField = {
      fieldId: fd.fieldId,
      fieldDef: fd,
      fieldValue: '',
      fieldDataType: 0,
      allowedValues: [],
      directChildDependents: fd.directChildDependents,
      error: '',
    };

    if (fd.hasExtender) {
      newField.allowedValues = fd.extender.allowedValues;
    }
    return newField;
  }

  initSnapshot() {
    let snapshots = [];
    var newSnapshot = { headerFields: [], fieldSets: [] };
    if (this.state.currentDoc !== null && this.state.currentDoc !== undefined) {
      this.state.currentDoc.headerFields.forEach((header) => {
        var snap = {
          fieldId: header.fieldId,
          sysName: header.systemName,
          value: header.fieldValue,
        };
        newSnapshot.headerFields.push(snap);
      });

      let fieldsets = this.state.currentDoc.fieldSets;
      fieldsets.forEach((fs) => {
        let fsSnap = {
          fieldSetId: fs.fieldSetId,
          rowData: [],
        };
        fs.rows.forEach((row) => {
          if (!row.isNew) {
            let fsRow = {
              rowId: row.rowId,
              fields: [],
            };

            row.fieldData.forEach((field) => {
              let fsField = { ...field };
              fsRow.fields.push(fsField);
            });
            fsSnap.rowData.push(fsRow);
          }
        });
        newSnapshot.fieldSets.push(fsSnap);
      });

      snapshots.push(newSnapshot);
      this.setState({ snapshots: snapshots });
      this.updateRunningTotals();
      this.focusFirstField();
    }
  }

  focusFirstField() {
    let headers = this.state.currentDoc.headerFields;
    let head = headers[0];

    try {
      let fieldTag = '';
      if (head.hasExtender) {
        if (head.extender.type === 4) {
          fieldTag = `${head.fieldId}_dropdown`;
        }
        if (head.extender.type === 1) {
          fieldTag = `autoCompleteExtender_${head.fieldId}`;
        }
        if (head.extender.type == 2) {
          fieldTag = `fieldEdit_${head.fieldId}`;
        }
      } else {
        fieldTag = `fieldEdit_${head.fieldId}`;
      }
      //fieldTag = 'documentButton';

      document.getElementById(fieldTag).focus();
    } catch {}
  }

  generateSnapshot() {
    var newSnapshot = { headerFields: [], fieldSets: [] };
    if (this.state.currentDoc !== null && this.state.currentDoc !== undefined) {
      this.state.currentDoc.headerFields.forEach((header) => {
        var snap = {
          fieldId: header.fieldId,
          sysName: header.systemName,
          value: header.fieldValue,
        };
        newSnapshot.headerFields.push(snap);
      });

      let fieldsets = this.state.currentDoc.fieldSets;
      fieldsets.forEach((fs) => {
        let fsSnap = {
          fieldSetId: fs.fieldSetId,
          rowData: [],
        };
        fs.rows.forEach((row) => {
          if (!row.isNew) {
            let fsRow = {
              rowId: row.rowId,
              fields: [],
            };

            row.fieldData.forEach((field) => {
              let fsField = { ...field };
              fsRow.fields.push(fsField);
            });
            fsSnap.rowData.push(fsRow);
          }
        });
        newSnapshot.fieldSets.push(fsSnap);
      });
      let currentSnapshots = this.state.snapshots;
      currentSnapshots.push(newSnapshot);
      this.setState({ snapshots: currentSnapshots });
    }
    this.updateRunningTotals();
  }

  handleApproveClick() {
    console.log('handleApprove');
    let valid = this.ValidateDocument();
    if (valid) {
      console.log('docValid');
      SubmitDocument('APPROVE', this.state.currentDoc);
      this.setState({ isSubmitting: true });
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  handleRejectClick() {
    if (!this.state.currentDoc.forceRejectReason) {
      SubmitDocument('REJECT', this.state.currentDoc);
    } else {
      this.setState({ showReasonForReject: true });
    }
  }

  handleImageNav(imageId) {
    var imageList = DocumentStore.getImages();
    if (imageList.length >= imageId) {
      var curImage = imageList[imageId - 1];
      if (curImage.imageBytes !== null) {
        this.setState({ currentImage: curImage });
      } else {
        FetchImage(this.state.currentDoc.documentId, imageId);
        this.setState({ loadingImage: true });
      }

      // check for previous and next page
      if (imageList.length >= imageId + 1) {
        var nextImage = imageList[imageId];
        if (nextImage.imageBytes === null) {
          FetchImage(this.state.currentDoc.documentId, imageId + 1);
        }
      }

      if (imageId - 1 > 0) {
        var previousImage = imageList[imageId - 2];
        if (previousImage.imageBytes === null) {
          FetchImage(this.state.currentDoc.documentId, imageId - 1);
        }
      }
    }
  }

  handleSaveClick() {
    let valid = this.ValidateDocument();
    let hasQueueChanges = this.checkQueueChanges();
    if (valid || hasQueueChanges) {
      this.setState({ isSaving: true });
      console.log(this.state.currentDoc);
      SaveDocument(this.state.currentDoc.documentId, this.state.currentDoc);
    } else {
      PostAlert('error', 'Unable to save document.  See errors');
      if (!valid) {
        this.setState({ showErrorMessage: true });
      }
    }
  }

  handlePreviousClick() {
    let docIds = SearchStore.getDocList();
    let docId = this.state.documentID;
    if(this.state.currentDoc.hasRelatedDocs && this.state.currentDoc.originalDocId !== 0)
      {
        docId = this.state.currentDoc.originalDocId;
      }
    if (docIds !== undefined) {
      if (docIds.includes(docId)) {
        let indexOfCurrent = docIds.indexOf(docId);
        let nextIndex = indexOfCurrent - 1;
        if (nextIndex >= 0) {
          //this.goToDoc(docIds[nextIndex]);
          this.setState({ isLoading: true });
          GetNextDocument(docId, docIds[nextIndex]);
          customHistory.push(`/Document/${docIds[nextIndex]}`);

          //moving to next doc, move all scrollable elements back to top
          var coolScroll = document.getElementsByClassName('coolScroll');
          for (var i = 0; i < coolScroll.length; i++) {
            coolScroll[i].scrollTop = 0;
          }
          var coolScroll2 = document.getElementsByClassName('coolScroll2');
          for (var i2 = 0; i2 < coolScroll2.length; i2++) {
            coolScroll2[i2].scrollTop = 0;
          }
          var dgr = document.getElementsByClassName('document-grid-rows');
          for (var i3 = 0; i3 < dgr.length; i3++) {
            dgr[i3].scrollTop = 0;
          }

          return;
        } else {
          // reached the end of list
          let link = document.getElementById('backLink');
          link.click();
          return;
        }
      }
    }
    let link = document.getElementById('backLink');
    link.click();
    //GetPreviousDocument(this.state.currentDoc);
  }

  handleNextClick(remove) {
    let docIds = SearchStore.getDocList();
    let docId = this.state.documentID;
    if(this.state.currentDoc.hasRelatedDocs && this.state.currentDoc.originalDocId !== 0)
      {
        docId = this.state.currentDoc.originalDocId;
      }
    let lastSearch = SearchStore.getLastSearch();
    if (docIds !== undefined) {
      if (docIds.includes(docId)) {
        let indexOfCurrent = docIds.indexOf(docId);
        let nextIndex = indexOfCurrent + 1;
        if (docIds.length > nextIndex) {
          let previousDocId = this.state.documentID;
          //this.goToDoc(docIds[nextIndex]);
          this.setState({ isLoading: true });
          GetNextDocument(docId, docIds[nextIndex]);
          customHistory.push(`/Document/${docIds[nextIndex]}`);
          console.log(remove);
          if(remove)
          {
            SearchStore.removeDocFromList(previousDocId);
          }
          //moving to next doc, move all scrollable elements back to top
          var coolScroll = document.getElementsByClassName('coolScroll');
          for (var i = 0; i < coolScroll.length; i++) {
            coolScroll[i].scrollTop = 0;
          }
          var coolScroll2 = document.getElementsByClassName('coolScroll2');
          for (var i2 = 0; i2 < coolScroll2.length; i2++) {
            coolScroll2[i2].scrollTop = 0;
          }
          var dgr = document.getElementsByClassName('document-grid-rows');
          for (var i3 = 0; i3 < dgr.length; i3++) {
            dgr[i3].scrollTop = 0;
          }

          return;
        } else {
          // reached the end of list
          let link = document.getElementById('backLink');
          link.click();
          return;
        }
      }
    }
    let link = document.getElementById('backLink');
    link.click();
  }

  goToDoc(docId) {
    let returnPath = window.location.pathname + window.location.search;
    localStorage.setItem('docReturn', returnPath);
    this.setState({ goToDocId: docId }, () => {
      let docLink = document.getElementById('docLink');
      docLink.click();
    });
  }

  handleAttachmentClick(e) {
    // toggle attachment menu
    let showMenu = !this.state.showAttachmentMenu;
    this.setState({
      showAttachmentMenu: showMenu,
      showPdfMenu: false,
      showSnagMenu: false,
    });
    e.stopPropagation();
  }

  handleTrashClick() {
    this.setState({ showDeleteConfirm: true });
  }

  cancelTrashClick() {
    this.setState({ showDeleteConfirm: false });
  }

  deleteDoc() {
    DeleteDocument(this.state.currentDoc);
  }

  handlePdfClick(e) {
    let showMenu = !this.state.showPdfMenu;
    this.setState({ showPdfMenu: showMenu, showAttachmentMenu: false });
    e.stopPropagation();
  }

  handleImageRevClick() {
    SendDocumentToImageReview(this.state.currentDoc);
  }

  handleDocumentClick() {
    this.setState({
      showPdfMenu: false,
      showAttachmentMenu: false,
      showSnagMenu: false,
      showDocumentMenu: false,
      showStampMenu: false,
    });
  }

  toggleSnagMenu(e) {
    let showMenu = !this.state.showSnagMenu;
    this.setState({
      showPdfMenu: false,
      showSnagMenu: showMenu,
      showAttachmentMenu: false,
      showStampMenu: false,
    });
    e.stopPropagation();
  }

  onMouseMove(e) {
    if (this.state.isDraggingSplitBar) {
      let navH = document.getElementById('tdAppHeader').clientHeight;
      let menuH = document.getElementById('tdDocumentMenu').clientHeight;
      let newH = e.clientY - this.state.topHeightOnDragStart;
      let newB = window.innerHeight - newH - navH - menuH - 11;
      let related = document.getElementById('deRlatedDocs');
      if (related) {
        newB = newB - related.clientHeight;
      }

      if (newB > 45) {

        let newTableHeight = window.innerHeight - newH - 239;
        this.setState({
          topHeight: newH,
          bottomHeight: newB,
          imageviewerHeight: newH - 32,
          tableHeight: newTableHeight,
        });
      }
    }
  }

  onMouseUp(e) {
    if (this.state.isDraggingSplitBar) {
      this.setState({ isDraggingSplitBar: false });
      UpdateSetting('doc_image_height', this.state.topHeight.toString());
    }
  }

  onHotKey() {
    let key=HotKeyStore.getLastKey();

    if(key === 'F2')
    {
      if( this.state.userCanSubmit &&
        !this.state.isSaving &&
        !this.state.fieldsHaveError &&
        !this.state.isSubmitting &&
        !this.state.isLoading
      )
      {
        this.handleApproveClick();
      }
    }
    if(key === 'F4')
    {
      // reject
      if( this.state.userCanSubmit &&
        !this.state.isSaving &&
        !this.state.fieldsHaveError &&
        !this.state.isSubmitting &&
        !this.state.isLoading
      )
      {
      this.handleRejectClick();
      }
    }
    if(key === 'F8')
    {
      // save

      if(!(this.state.isSaving ||
        ((this.state.fieldsHaveError ||
          this.state.snapshots.length === 1 ||
          !this.state.userCanEdit) &&
          //!this.state.userCanMoveAssign &&
          !this.state.queuesDirty)))
      {
        this.handleSaveClick();
      }
    }
  }

  // onKeyUp(e) {
  //   // approve document when hitting F2
  //   if (e.keyCode === 113) {
  //     this.handleApproveClick();
  //     e.preventDefault();
  //   }
  // }

  onSplitBarMouseDown(e) {
    this.setState({
      isDraggingSplitBar: true,
      topHeightOnDragStart: e.clientY - this.state.topHeight,
    });
  }

  onWindowResize(e) {
    //setViewWidth
    let ivw = window.innerWidth - 280 - 20;
    let navH = document.getElementById('tdAppHeader').clientHeight;
    let menuH = document.getElementById('tdDocumentMenu').clientHeight;
    let newH = this.state.topHeight;
    if (this.state.showUserNotes) ivw = ivw - 280;
    this.setState({ imageviewerWidth: ivw });
    this.setState({ tableWidth: window.innerWidth });
    //this.setState({tableHeight: window.innerHeight - this.state.imageViewerHeight - 500});
    this.setState({
      tableHeight: window.innerHeight - this.state.topHeight - 239,
    });
    let newB = window.innerHeight - newH - navH - menuH - 11;
    let related = document.getElementById('deRlatedDocs');
    if (related) {
      newB = newB - related.clientHeight;
    }
    if (newB > 8) {
      //let newTableHeight = window.innerHeight - newH - 239;
      this.setState({
        bottomHeight: newB,
      });
    }
  }

  handleHeaderFieldTextEdit(editText, fieldId, extenderId) {
    let doc = this.state.currentDoc;
    let header = doc.headerFields.find((h) => h.fieldId === fieldId);
    if (header) {
      header.fieldValue = editText;
      if (extenderId !== null) {
        header.extenderKey = extenderId;
      }
    }

    this.setState({ currentDoc: doc }, this.reviewSnapshots());
  }
  handleHeaderFieldEdit(editText, fieldId, extenderKey, extenderId) {
    //find the header field.
    let doc = this.state.currentDoc;
    let header = doc.headerFields.find((h) => h.fieldId === fieldId);
    if (header && !header.hasExtender) {
      header.fieldValue = editText.trim();
    }
    let dep = this.findDependency(doc.headerDependencies, header.fieldId);
    if (editText === '' && dep.hasExtender) {
      this.blankHeaderField(fieldId, false);
    } else {
      if (dep.childNodes !== undefined && dep.childNodes.length > 0) {
        dep.childNodes.forEach((hd) => {
          if (hd.hasExtender) {
            let d = this.getExtenderData();
            let u = d.find((f) => f.fieldId === header.fieldId);
            if (u) {
              u.KeyValue = extenderKey;
            }
            ExecuteExtender(
              hd.extenderId,
              d,
              '',
              hd.fieldId,
              0,
              'userEdit',
              null,
              null,
              doc.documentId
            );
          }
        });
      }
    }

    if (dep.hasExtender) {
      if (header) {
        header.fieldValue = editText.trim();
        header.extenderKey = extenderKey;
      }

      // dispatch will handle header fields,  what about row fields...
      let hasRowchildren = false;
      doc.fieldSets.forEach((fs) => {
        fs.headerDependencies.forEach((hd) => {
          if (hd.fieldId === dep.fieldId) {
            hasRowchildren = true;
          }
        });
        if (hasRowchildren) {
          fs.rows.forEach((row) => {
            fs.rowDependencies.forEach((rd) => {
              if (rd.hasExtender) {
                this.processRowExtender(rd.fieldId, fs, row, doc, 'userEdit');
              }
            });
          });
        }
        // field Ids to check for...
      });
    }

    this.setState({ currentDoc: doc }, this.reviewSnapshots());
  }

  runExtender(extenderId, rowId, fieldSetId) {
    let doc = this.state.currentDoc;

    let extenderData = [];
    doc.headerFields.forEach((head) => {
      if (head.hasExtender) {
        extenderData.push({
          fieldId: head.fieldId,
          KeyValue: head.extenderKey.toString(),
        });
      }
    });
    console.log('run');
    ExecuteExtender(extenderId, extenderData, '', 0, fieldSetId, rowId, null, doc.documentId);
  }

  handleTableFieldEdit(editText, rowId, fieldId, extenderKey, fieldSetId) {

    let doc = this.state.currentDoc;
    let rows = this.state.rowData;
    let row = rows.find((r) => r.rowId === rowId);
    console.log('CellUpdate');
    if (row) {
      let field = row.fieldData.find((f) => f.fieldId === fieldId);

      let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);
      let dep = this.findDependency(fieldSet.rowDependencies, fieldId);

      if(editText !== field.fieldValue || extenderKey !== field.extenderKey)
      {

        if (field) {
          field.fieldValue = editText;
          field.keyValue = extenderKey;
          field.extenderKey = extenderKey;
          field.textValue = editText;
          field.error = '';
          this.setState({ rowData: rows }, this.reviewSnapshots());
        }
        let extenderId = -1;

        if (dep !== null && dep.childNodes !== undefined && dep.childNodes.length > 0) {
          dep.childNodes.forEach((hd) => {
            if (hd.hasExtender) {
              this.processRowExtender(hd.fieldId, fieldSet, row, doc, 'userEdit');
            }
          });
        }
      }
    }
  }

  reviewSnapshots() {
    let doc = this.state.currentDoc;

    let snapshots = this.state.snapshots;
    if (snapshots.length === 0) {
      this.generateSnapshot();
    }

    let changeCount = 0;

    let latestSnapshot = snapshots[snapshots.length - 1];
    doc.headerFields.forEach((header) => {
      var hsnap = latestSnapshot.headerFields.find(
        (snap) => snap.fieldId === header.fieldId
      );
      if (hsnap) {
        if (header.fieldValue !== hsnap.value) {
          changeCount++;
        }
      }
    });
    let rows = this.state.rowData;
    let fsSnap = latestSnapshot.fieldSets.find(
      (fs) => fs.fieldSetId === this.state.currentFieldSet.fieldSetId
    );
    if (fsSnap) {
      rows.forEach((row) => {
        if (!row.isNew) {
          let fsRow = fsSnap.rowData.find((r) => r.rowId === row.rowId);
          if (fsRow) {
            row.fieldData.forEach((field) => {
              let fsField = fsRow.fields.find(
                (f) => f.fieldId === field.fieldId
              );
              if (fsField) {
                if (fsField.fieldValue !== field.fieldValue) {
                  changeCount++;
                }
              }
            });
          } else {
            changeCount++;
          }
        }
      });

      if(rows.length - 1 !== fsSnap.rowData.length && changeCount === 0)
        {
          changeCount++;
        }
    }
    if (snapshots.length === 1 && changeCount > 0) {
      this.generateSnapshot();
    } else if (snapshots.length > 1) {
      let snapChanges = 0;
      let previousSnapshot = snapshots[snapshots.length - 2];
      doc.headerFields.forEach((header) => {
        var hsnap = previousSnapshot.headerFields.find(
          (snap) => snap.fieldId === header.fieldId
        );
        if (hsnap) {
          if (header.fieldValue !== hsnap.value) {
            snapChanges++;
          }
        }
      });
      let rows = this.state.rowData;
      let fsSnap = previousSnapshot.fieldSets.find(
        (fs) => fs.fieldSetId === this.state.currentFieldSet.fieldSetId
      );
      if (fsSnap) {
        rows.forEach((row) => {
          if (!row.isNew) {
            let fsRow = fsSnap.rowData.find((r) => r.rowId === row.rowId);
            row.fieldData.forEach((field) => {
              if (!fsRow && field.fieldValue !== '') {
                snapChanges++;
              }
              if (fsRow) {
                let fsField = fsRow.fields.find(
                  (f) => f.fieldId === field.fieldId
                );
                if (fsField) {
                  if (fsField.fieldValue !== field.fieldValue) {
                    snapChanges++;
                  }
                }
              }
            });

            // if(fsRow){
            //    row.fieldData.forEach(field => {
            //       let fsField = fsRow.fields.find(f => f.fieldId === field.fieldId);
            //       if(fsField)
            //       {
            //          if(fsField.fieldValue !== field.fieldValue){
            //             snapChanges++;
            //          }
            //       }
            //    });
            // } else {
            //    snapChanges++;
            // }
          }
        });
      }
      if (snapChanges === 1) {
        // update current snapshot
        this.updateCurrentSnapshot();
      } else if (snapChanges >= 2) {
        this.generateSnapshot();
      }
      // possible scenarios for destroying snapshots
      else if (snapChanges === 0) {
        snapshots.pop();
        this.setState({ snapshots: snapshots });
      }
    }
  }

  updateCurrentSnapshot() {
    var newSnapshot = { headerFields: [], fieldSets: [] };
    if (this.state.currentDoc !== null && this.state.currentDoc !== undefined) {
      this.state.currentDoc.headerFields.forEach((header) => {
        var snap = {
          fieldId: header.fieldId,
          sysName: header.systemName,
          value: header.fieldValue,
        };
        newSnapshot.headerFields.push(snap);
      });

      let fieldsets = this.state.currentDoc.fieldSets;
      fieldsets.forEach((fs) => {
        let fsSnap = {
          fieldSetId: fs.fieldSetId,
          rowData: [],
        };
        fs.rows.forEach((row) => {
          if (!row.isNew) {
            let fsRow = {
              rowId: row.rowId,
              fields: [],
            };
            row.fieldData.forEach((field) => {
              let fsField = { ...field };

              fsRow.fields.push(fsField);
            });
            fsSnap.rowData.push(fsRow);
          }
        });
        newSnapshot.fieldSets.push(fsSnap);
      });
      let currentSnapshots = this.state.snapshots;
      currentSnapshots.pop();
      currentSnapshots.push(newSnapshot);
      this.setState({ snapshots: currentSnapshots });
    }
  }

  undoLatestChange() {
    let snapshots = this.state.snapshots;
    let doc = this.state.currentDoc;
    if (snapshots.length > 1) {
      snapshots.pop();
      let newSnap = snapshots[snapshots.length - 1];
      //header fields
      doc.headerFields.forEach((header) => {
        var snap = newSnap.headerFields.find(
          (f) => f.fieldId === header.fieldId
        );
        if (snap) {
          header.fieldValue = snap.value;
        }
      });
      doc.fieldSets.forEach((fs) => {
        let fsSnap = newSnap.fieldSets.find(
          (f) => fs.fieldSetId === f.fieldSetId
        );
        if (fsSnap) {
          fsSnap.rowData.forEach((rowSnap) => {
            let editRow = fs.rows.find((r) => r.rowId === rowSnap.rowId);
            if (editRow) {
              if (!editRow.isNew) {
                // check field
                editRow.fieldData.forEach((field) => {
                  let snapField = rowSnap.fields.find(
                    (f) => f.fieldId === field.fieldId
                  );
                  if (snapField) {
                    field.fieldValue = snapField.fieldValue;
                  }
                });
              }
            } else {
              // add the row
              let newRow = {
                rowId: rowSnap.rowId,
                isDeleted: false,
                fieldData: [],
              };
              rowSnap.fields.forEach((field) => {
                newRow.fieldData.push({ ...field });
              });
              //fs.rows.push(newRow);
              let idx = 0;
              fs.rows.forEach((row) => {
                if (!row.isNew && row.rowId < newRow.rowId) {
                  idx++;
                }
              });
              fs.rows.splice(idx, 0, newRow);
            }
          });

          fs.rows.forEach((row) => {
            let rowSnap2 = fsSnap.rowData.find((r) => r.rowId === row.rowId);
            if (!rowSnap2 && !row.isNew) {
              // delete
              let idx = fs.rows.indexOf(row);
              fs.rows.splice(idx, 1);
            }
          });

          let rowIdx = 1;
          fs.rows.forEach((row) => {
            row.rowId = rowIdx;
            rowIdx++;
          });
        }
      });
      this.setState({ currentDoc: doc, snapshots: snapshots });
    }
  }

  onMoveCell(dir) {
    if (dir === 'next') {
      let rowId = this.state.currentEdit.rowId;
      let colId = this.state.currentEdit.orderId;
      let element = document.getElementById(`edit_${rowId}_${colId+1}`);
      if (element) {
        element.focus();
        if (element.tagName !== 'SELECT') {
          element.select();
        }
      } else {
        element = document.getElementById(`edit_${rowId+1}_1`);
        if (element) {
          element.focus();
          if (element.tagName !== 'SELECT') {
            element.select();
          }
        }


      }
    }

    if (dir === 'down') {
      let rowId = this.state.currentEdit.rowId;
      let nextRow = this.state.rowData.find((r) => r.rowId === rowId + 1);
      if (nextRow) {
        let fieldId = this.state.currentEdit.orderId;
        let element = document.getElementById(
          `edit_${nextRow.rowId}_${fieldId}`
        );
        if (element) {
          element.focus();

          if (element.tagName !== 'SELECT') {
            element.select();
          }
        }
      }
    }
    if (dir === 'left') {
      let rowId = this.state.currentEdit.rowId;
      let colId = this.state.currentEdit.orderId;

      let element = document.getElementById(`edit_${rowId}_${colId - 1}`);
      if (element) {
        element.focus();
        if (element.tagName !== 'SELECT') {
          element.select();
        }
      }
    }
    if (dir === 'right') {
      let rowId = this.state.currentEdit.rowId;
      let colId = this.state.currentEdit.orderId;

      let element = document.getElementById(`edit_${rowId}_${colId+1}`);
      if (element) {
        element.focus();
        if (element.tagName !== 'SELECT') {
          element.select();
        }
      } else {
        element = document.getElementById(`edit_${rowId+1}_1`);
        if (element) {
          element.focus();
          if (element.tagName !== 'SELECT') {
            element.select();
          }
        }


      }
    }

    if (dir === 'up') {
      let rowId = this.state.currentEdit.rowId;
      let nextRow = this.state.rowData.find((r) => r.rowId === rowId - 1);
      if (nextRow) {
        let fieldId = this.state.currentEdit.orderId;
        let element = document.getElementById(
          `edit_${nextRow.rowId}_${fieldId}`
        );
        if (element) {
          element.focus();
          if (element.tagName !== 'SELECT') {
            element.select();
          }
        }
      }
    }

    //let input = document.getElementById(
  }

  onCellClick(rowId, colId, orderId) {
    this.setState({ currentEdit: { rowId, colId, orderId } });
    let rows = this.state.rowData;
    let thisRow = rows.find((r) => r.rowId === rowId);
    if (thisRow && thisRow.isNew) {
      thisRow.isNew = false;
      let nextRowId = 1;
      //
      rows.forEach((row) => {
        if (row.rowId >= nextRowId) nextRowId = row.rowId + 1;
      });
      let newRow = {
        rowId: nextRowId,
        dbRowId: -1,
        isChecked: false,
        isNew: true,
        isDeleted: false,
        fieldData: [],
        error: '',
      };
      //let x = this.state.currentDoc;
      newRow.extenderDependencies = [
        ...this.state.currentDoc.extenderDependencies,
      ];
      this.state.currentFieldSet.fieldDefs.forEach((fd) => {

        let newField = {
          fieldId: fd.fieldId,
          fieldDef: fd,
          fieldValue: '',
          extenderId: '',
          displayOrder: fd.displayOrder,
          fieldDataType: 0,
          allowedValues: [],
          directChildDependents: fd.directChildDependents,
          error: '',
        };
        newRow.fieldData.push(newField);
      });
      this.processExtenderRow2(newRow, this.state.currentFieldSet);
        // if (fd.hasExtender) {

        //   // does this field depend on other rows? Only headers?
        //   let sibDependency = false;
        //   let d = this.state.currentFieldSet.depMap.filter(d2=> d2.fieldId === fd.fieldId);

        //   if(d.length > 0)
        //   {
        //     d.forEach((d2) => {
        //       let sib = this.state.currentFieldSet.fieldDefs.find(s => s.fieldId === d2.depFieldId);

        //       if(sib !== undefined)
        //       {
        //         sibDependency = true;
        //       }
        //     });
        //   }
        //   if(!sibDependency)
        //   {
        //     this.processRowExtender(fd.fieldId, this.state.currentFieldSet, newRow, this.state.currentDoc, 'userEdit');
        //   }

        //   //newField.allowedValues = fd.extender.allowedValues;
        // }

      rows.push(newRow);



      this.setState({ rowData: rows }, this.reviewSnapshots());
    }
  }

  // checkChildExtenders(row, column) {
  //   column.directChildDependents.forEach((ed) => {
  //     var fieldSetId = -1;
  //     let doc = this.state.currentDoc;
  //     let fieldSets = doc.fieldSets;

  //     fieldSets.forEach((fs) => {
  //       var check = fs.fieldDefs.find((y) => y.fieldId === ed.fieldId);
  //       if (check !== null && check !== undefined) {
  //         fieldSetId = fs.fieldSetId;
  //       }
  //     });

  //     //let fs = doc.fieldSets.find((f) => f.fieldSetId === fieldSetId);

  //     var snap = this.GetValueSnapShot2(
  //       false,
  //       row.rowId,
  //       fieldSetId,
  //       ed.fieldId
  //     );

  //     let extenderData = [];
  //     let temp = snap.find((x) => x.fieldId === column.fieldId);

  //     //let v = row.fieldData.find((x) => x.fieldId === column.fieldId);
  //     //let ext = v.allowedValues.find((x) => x.textValue === temp.fieldValue);

  //     //let doc = this.state.currentDoc;
  //     extenderData.push({
  //       fieldId: column.fieldId,
  //       KeyValue: temp.extenderKey,
  //     });
  //     column.textValue = '';

  //     ExecuteExtender(
  //       ed.extenderId,
  //       extenderData,
  //       '',
  //       ed.fieldId,
  //       snap,
  //       row.rowId
  //     );
  //     //}
  //     //}
  //   });
  // }

  // createNewRow(fieldId, text) {
  //   let currentRows = this.state.rowData;

  //   let rowData = {
  //     rowId: 1,
  //     fieldData: [],
  //   };

  //   this.state.currentFieldSet.fieldDefs.forEach((fd) => {
  //     var newFd = {
  //       fieldDef: fd,
  //       fieldId: fd.fieldId,
  //       value: '',
  //       directChildDependents: fd.directChildDependents,
  //       error: '',
  //     };
  //     if (newFd.fieldId === fieldId) newFd.value = text;
  //   });

  //   currentRows.push(rowData);
  //   this.setState({ rowData: currentRows });
  // }

  startDraggingColumn(e, colIndex) {
    if (colIndex > 0) {
      isDraggingColumn = true;
      startColX = e.clientX;

      let colInfo = this.state.currentFieldSet.fieldDefs.find(
        (c) => c.fieldId === colIndex
      );

      dragColIndex = colIndex;
      if (colInfo) {
        startColWidth = colInfo.colWidth;
      }
    }
  }

  DragColumnSize(e) {
    if (isDraggingColumn) {
      var delta = e.clientX - startColX;
      if (startColWidth + delta > 20) {
        let info = this.state.currentFieldSet;
        let colInfo = info.fieldDefs.find((c) => c.fieldId === dragColIndex);
        colInfo.colWidth = startColWidth + delta;

        this.setState({ currentFieldSet: info });
      }
    }
  }

  stopDraggingColSize() {
    if (isDraggingColumn) {
      isDraggingColumn = false;

      // set user settings
      var settings = {
        showChecks: this.state.showChecks,

        fieldSettings: [],
      };

      this.state.currentDoc.fieldSets.forEach((fs) => {
        fs.fieldDefs.forEach((fd) => {
          let newFS = {
            fieldId: fd.fieldId,
            colWidth: fd.colWidth,
            display: fd.display,
          };
          settings.fieldSettings.push(newFS);
        });
      });

      UpdateUserFieldSettings(this.state.currentDoc.formTypeId, settings);
    }
  }

  toggleShowAllColumns(e) {
    let cfs = this.state.currentFieldSet;
    let showAll = this.state.showAllColumns;
    showAll = !showAll;
    cfs.fieldDefs.forEach((fd) => {
      fd.display = showAll;
    });
    this.setState({ showAllColumns: showAll, currentFeildSet: cfs });

    // set user settings
    var settings = {
      showChecks: this.state.showChecks,

      fieldSettings: [],
    };

    this.state.currentDoc.fieldSets.forEach((fs) => {
      fs.fieldDefs.forEach((fd) => {
        let newFS = {
          fieldId: fd.fieldId,
          colWidth: fd.colWidth,
          display: fd.display,
        };
        settings.fieldSettings.push(newFS);
      });
    });

    UpdateUserFieldSettings(this.state.currentDoc.formTypeId, settings);
  }

  toggleColumnDisplay(e, fieldId) {
    let cfs = this.state.currentFieldSet;
    let fieldDef = cfs.fieldDefs.find((f) => f.fieldId === fieldId);
    if (fieldDef) {
      fieldDef.display = e.target.checked;
    }
    this.setState({ currentFieldSet: cfs });

    // set user settings
    var settings = {
      showChecks: this.state.showChecks,

      fieldSettings: [],
    };

    this.state.currentDoc.fieldSets.forEach((fs) => {
      fs.fieldDefs.forEach((fd) => {
        let newFS = {
          fieldId: fd.fieldId,
          colWidth: fd.colWidth,
          display: fd.display,
        };
        settings.fieldSettings.push(newFS);
      });
    });

    UpdateUserFieldSettings(this.state.currentDoc.formTypeId, settings);
  }

  setRowCheck(e, rowId) {
    let rows = this.state.rowData;
    let row = rows.find((r) => r.rowId === rowId);
    if (row) {
      row.isChecked = e.target.checked;
    }
    this.setState({ rowData: rows });
  }

  setAllRowCheck(e) {
    let check = e.target.checked;
    this.setState({ allRowCheck: check });

    let rows = this.state.rowData;
    rows.forEach((row) => {
      if (!row.isNew) {
        row.isChecked = check;
      }
    });
    this.setState({ rowData: rows });
  }

  toggleChecks(e) {
    this.setState({ showChecks: e.target.checked });
  }

  toggleShowTools() {
    let show = this.state.showTools;
    this.setState({ showTools: !show });
  }

  writeGridToClipboard() {
    const type = 'text/plain';
    let blobData = '';

    let rowData = this.state.rowData;
    rowData.forEach((rd) => {
      if (rd.isNew !== true) {
        let fieldData = rd.fieldData;
        fieldData.forEach((fd) => {
          blobData += `${fd.fieldValue}\t`;
        });
        blobData += '\n';
      }
    });
    const blob = new Blob([blobData], {
      type,
    });
    const data = [new window.ClipboardItem({ [type]: blob })];
    navigator.clipboard.write(data);
    PostAlert('success', 'Grid lines written to clipboard');
  }

  convertDate(dateString) {
    let d = new Date(dateString);
    let h = d.getHours();
    let mrdn = 'AM';
    if (h === 12) {
      mrdn = 'PM';
    }
    if (h > 12) {
      h = h - 12;
      mrdn = 'PM';
    }
    let min = d.getMinutes();
    if (d.getMinutes() < 10) {
      min = '0' + d.getMinutes();
    }
    return `${
      d.getMonth() + 1
    }/${d.getDate()}/${d.getFullYear()} ${h}:${min} ${mrdn}`;
  }

  toggleFieldSet(fs) {
    let nr = fs.rows.find((r) => r.isNew === true);

    if (!nr) {
      console.log('newRow');
      let maxRowId = 1;
      fs.rows.forEach((r) => {
        if (r.rowId >= maxRowId) {
          maxRowId++;
        }
      });
      let newRow = {
        rowId: maxRowId,
        dbRowId: -1,
        isNew: true,
        isChecked: false,
        isDeleted: false,
        fieldData: [],
      };
      newRow.extenderDependencies = [
        ...this.state.currentDoc.extenderDependencies,
      ];
      fs.fieldDefs.forEach((fd) => {
        let newField = {
          fieldId: fd.fieldId,
          fieldDef: fd,
          fieldValue: '',
          fieldDataType: 0,
          allowedValues: [],
          directChildDependents: fd.directChildDependents,
          error: '',
        };
        newRow.fieldData.push(newField);
        if (fd.hasExtender) {
          let sibDependency = false;
          let d = fs.depMap.filter(d2=> d2.fieldId === fd.fieldId);
          if(d.length > 0)
          {
            d.forEach((d2) => {
              let sib = fs.fieldDefs.find(s => s.fieldId === d2.depFieldId);

              if(sib !== undefined)
              {
                sibDependency = true;
              }
            });
          }
          if(!sibDependency)
          {
            this.processRowExtender(fd.fieldId, fs, newRow, this.state.currentDoc, 'userEdit');
          }

        }

      });
      console.log(newRow);
      fs.rows.push(newRow);
    }

    //needed to add this so the first row of secondary field sets populate extender values
    // fs.rows.forEach((row) => {
    //     this.processExtenderRow(row, fs);
    // });

    this.setState({ currentFieldSet: fs, rowData: fs.rows });
  }

  toggleColumnEdit() {
    let ce = this.state.showColumnEdit;
    this.setState({ showColumnEdit: !ce, showAllColumns: false });
  }

  deleteRow(rowId) {
    let rows = this.state.rowData;

    //this.setState({ rowData: rows }, () => this.generateSnapshot());

    //rows = this.state.rowData;

    let rowToDelete = rows.find((r) => r.rowId === rowId);
    let idx = rows.indexOf(rowToDelete);
    rows.splice(idx, 1);

    let newRowId = 1;
    rows.forEach((r) => {
      r.rowId = newRowId;
      newRowId++;
    });

    /*
    let rowIdx = 1;
    rows.forEach((row) => {
      row.rowId = rowIdx;
      rowIdx++;
    });
    */
    this.updateRunningTotals();
    this.setState({ rowData: rows }, () => {
      this.generateSnapshot();
      this.updateRunningTotals();
    });
  }

  deleteRows() {
    let rows = this.state.rowData;
    let rowsToDelete = [];
    rows.forEach((row) => {
      if (row.isChecked) {
        rowsToDelete.push(row);
      }
    });

    rowsToDelete.forEach((row) => {
      let idx = rows.indexOf(row);
      rows.splice(idx, 1);
    });

    let rowIdx = 1;
    rows.forEach((row) => {
      row.rowId = rowIdx;
      rowIdx++;
    });

    this.setState({ rowData: rows }, () => {
      this.generateSnapshot();
      this.updateRunningTotals();
    });
  }

  toggleDocMenu(e) {
    let showMenu = this.state.showDocumentMenu;
    this.setState({ showDocumentMenu: !showMenu });
    e.stopPropagation();
  }

  hideDocMenu() {
    this.setState({ showDocumentMenu: false });
  }

  toggleShowAudit() {
    let audit = this.state.showAudit;
    this.setState({ showAudit: !audit });
    if (!audit) {
      GetDocumentAudit(this.state.currentDoc);
    }
  }

  switchAuditView(newView) {
    this.setState({ currentAuditView: newView });
  }

  toggleTemplateChange() {
    let stc = this.state.showTemplateChange;
    if (!stc) {
      this.filterTemplates('');
    }

    let defaultSuggest = 0;
    if(this.state.currentDoc.templateId !== null) {
      defaultSuggest = this.state.currentDoc.templateId;
      if(defaultSuggest === -1) defaultSuggest = 0;
      let selTemplate = this.state.availableTemplates.find(f => f.templateId === defaultSuggest);
      if(selTemplate !== null && defaultSuggest !== -1)
      {
        this.setState({selectedTemplate: selTemplate});
      }
    }
    this.setState({ suggestedTemplateId: defaultSuggest, showTemplateChange: !stc });


  }

  filterTemplates(searchText) {
    if (searchText === '') {
      this.setState({ filteredTemplates: [...this.state.availableTemplates] });
    } else {
      let newList = [];
      this.state.availableTemplates.forEach((temp) => {
        if (
          temp.templateName.toLowerCase().includes(searchText.toLowerCase())
        ) {
          newList.push(temp);
        }
      });
      this.setState({ filteredTemplates: newList });
      if (newList.length > 0) {
        this.setState({ suggestedTemplateId: newList[0] });
      }
    }
  }

  suggestTemplateItem(tempOption) {
    this.setState({ suggestedTemplateId: tempOption });
  }

  templateSuggestionClick(e) {
    let st = this.state.filteredTemplates.find((t) => t.templateId === e);
    if (st) {
      this.setState({
        selectedTemplate: st,
      });
    }
  }

  onChangeTemplateSearchText(e) {
    this.setState({ templateSearchText: e.target.value });
    this.filterTemplates(e.target.value);
    // do the search filtering thingy...
  }

  onClickTemplateSearch() {
    if (this.state.selectedTemplate.templateId > 0) {
      this.setState({
        selectedTemplate: {
          templateId: -1,
          templateName: 'Template...',
        },
        templateSearchText: '',
      });
      this.filterTemplates('');
    }
  }

  onClickTemplateChange(changeId) {
    if(changeId === 1){
      UpdateTemplate(
        this.state.currentDoc.documentId,
        this.state.selectedTemplate.templateId,
        false,
        false,
      );
    }
    if(changeId === 2){
      UpdateTemplate(
        this.state.currentDoc.documentId,
        this.state.selectedTemplate.templateId,
        true,
        false,
      );
    }
    if(changeId === 3){
      UpdateTemplate(
        this.state.currentDoc.documentId,
        this.state.selectedTemplate.templateId,
        true,
        true
      );
    }
    this.setState({ inProgressAnimation: true });
  }

  toggleApplyTemplate(e) {
    this.setState({ applyTemplate: e.target.checked });
  }

  setFieldError(fieldId, errorMessage) {
    console.log('setFieldError');
    let doc = this.state.currentDoc;
    let f = doc.headerFields.find((fi) => fi.fieldId === fieldId);
    f.error = errorMessage;
    if (errorMessage === '') {
      this.setState({ currentDoc: doc, fieldsHaveError: false });
    } else {
      this.setState({ currentDoc: doc, fieldsHaveError: true });
    }
  }

  updateRunningTotals() {
    let fs = this.state.currentFieldSet;
    fs.fieldDefs.forEach((fd) => {
      if (fd.showTotal === true) {
        let dec = fd.decimalPlaces ?? 0;
        this.calculateRunningTotal(dec, fd.fieldId, fs.fieldSetId);
      }
    });
  }

  calculateRunningTotal(numDecimalPlaces, fieldId, fieldSetId) {
    //var y = document.getElementById(`${fieldId}_runningTotal`);
    //document.getElementById(`${fieldId}_runningTotal`).innerHTML = '( 0.00 )';
    //var x = document.querySelector(`${fieldId}_runningTotal`).innerHTML;

    //let y2 = document.getElementById(`${fieldId}_runningTotal`).innerHTML;
    let doc = this.state.currentDoc;
    let fieldSet = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);

    var total = 0;
    for (var i = 0; i < fieldSet.rows.length; i++) {
      let row = fieldSet.rows[i];
      var field = row.fieldData.find((field) => field.fieldId === fieldId);
      if (field.fieldValue !== '') {
        var fieldValNoComma = field.fieldValue.replace(/\,/g, '');
        var flt = parseFloat(fieldValNoComma);
        if (!isNaN(flt)) {
          total = total + flt;
        }
      }
    }
    if (numDecimalPlaces === null || numDecimalPlaces === undefined) {
      numDecimalPlaces = 2;
    }
    let el = document.getElementById(`${fieldId}_runningTotal`);
    if(el !== null && el !== undefined){
      el.innerHTML =
        '( ' + total.toFixed(numDecimalPlaces) + ' )';
    }
  }
  setLineFieldError(fieldId, errorMessage, fieldSetId, rowNo) {
    let doc = this.state.currentDoc;
    //let f2 = doc.headerFields.find((fi) => fi.fieldId === fieldId);
    let fsToError = doc.fieldSets.find((fs) => fs.fieldSetId === fieldSetId);
    let rowToError = fsToError.rows.find((row) => row.rowId === rowNo);

    if (rowToError === undefined || rowToError.isNew === true){
      return;
    }

    let f = rowToError.fieldData.find((field) => field.fieldId === fieldId);

    f.error = errorMessage;
    if (errorMessage === '') {
      this.setState({ currentDoc: doc, fieldsHaveError: false });
    } else {
      this.setState({ currentDoc: doc, fieldsHaveError: true });
    }
  }

  generateDocFields() {
    //let temp = this.state.currentDoc;
  }

  ValidateDocument() {
    let incompleteExtenders = 0;
    //this.showSaveDetails();
    let doc = this.state.currentDoc;
    let validDoc = true;
    let fieldError = false;
    let validationErrors = [];
    console.log('validate');
    doc.headerFields.forEach((field) => {
      let newError = false;
      if (field.hasExtender) {
        var exists = field.extender.allowedValues.some(
          (x) => x.textValue === field.fieldValue
        );
        if (
          exists === false &&
          field.fieldValue !== null &&
          field.fieldValue !== ''
        ) {
          //PostAlert('error', 'UHOH');

          let errMsg = `Value not allowed by extender.  Field: '${field.displayName}' Value: '${field.fieldValue}' `;
          validationErrors.push(errMsg);

          field.error =
            'Value not allowed by extender {' + field.fieldValue + '}';

          validDoc = false;
          fieldError = true;
          newError = true;
        } else if (field.fieldValue === null) {
          field.fieldValue = '';
          field.extenderKey = '-1';
        } else {
          //PostAlert('error', 'UHOH');
          field.error = '';

          fieldError = false;
        }
      } else {
        //doesnt have extender
      }

      if (field.isRequired) {
        if (field.fieldValue === '') {
          let errMsg = `Field is required.  Field: '${field.displayName}' `;
          validationErrors.push(errMsg);

          field.error = 'Field is required';
          validDoc = false;
          fieldError = true;
          newError = true;
        }
      }

      if (!newError && field.fieldType === 1) {
        if (field.fieldValue !== '') {
          let indexOfDecimal = field.fieldValue.indexOf('.');
          if (indexOfDecimal > -1) {
            let currentDigits = field.fieldValue.length - indexOfDecimal - 1;
            if (currentDigits < field.decimalPlaces) {
              while (currentDigits < field.decimalPlaces) {
                field.fieldValue = field.fieldValue + '0';
                currentDigits = field.fieldValue.length - indexOfDecimal - 1;
              }

            }
          } else {
            if(field.decimalPlaces > 0)
            {
              field.fieldValue = field.fieldValue + '.';
              for (let i = 0; i < this.props.decimalPlaces; i++) {
                field.fieldValue = field.fieldValue + '0';
              }
            }
          }
          let re = new RegExp('^-?[0-9]*$');
          if (field.decimalPlaces > 0) {
            re = new RegExp(
              `^-?[0-9]*\\.[0-9]{${field.decimalPlaces.toString()}}$`
            );
          }
          if (!re.test(field.fieldValue)) {
            let errMsg = `Field must be numeric.  Field: '${field.displayName}'  Value: '${field.fieldValue}'  `;
            validationErrors.push(errMsg);

            field.error = 'Field must be numeric';
            validDoc = false;
            fieldError = true;
            newError = true;
          }
        }
      }

      if (!newError && field.fieldType === 2) {
        if (field.fieldValue !== '') {

          // fix short handed dates
          let reFullDate = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}');
          let reShortDate = new RegExp('^[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}');
          if(!reFullDate.test(field.fieldValue) && reShortDate.test(field.fieldValue) && (field.format === 'dd/mm/yyyy' || field.format === 'mm/dd/yyyy'))
          {
            let dateParts = field.fieldValue.split('/');
            if(dateParts[0].length === 1) dateParts[0] = '0' + dateParts[0];
            if(dateParts[1].length === 1) dateParts[1] = '0' + dateParts[1];
            field.fieldValue = dateParts[0] + '/' + dateParts[1] + '/' + dateParts[2];
          }

          if (field.format === 'dd/mm/yyyy') {
            let re = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}');
            if (!re.test(field.fieldValue)) {
              let errMsg = `Field must in date format.  Field: '${field.displayName}'  Value: '${field.fieldValue}'  `;
              validationErrors.push(errMsg);

              field.error = 'Field must in date format';
              validDoc = false;
              fieldError = true;
              newError = true;
            }
          }
          if (field.format === 'mm/dd/yyyy') {
            let re = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}');
            if (!re.test(field.fieldValue)) {
              let errMsg = `Field must in date format.  Field: '${field.displayName}'  Value: '${field.fieldValue}'  `;
              validationErrors.push(errMsg);
              field.error = 'Field must be date format';
              validDoc = false;
              fieldError = true;
              newError = true;
            }
          }
          if (field.format === 'mm-dd-yyyy') {
            let re = new RegExp('^[0-9]{2}-[0-9]{2}-[0-9]{4}');
            if (!re.test(field.fieldValue)) {
              let errMsg = `Field must in date format.  Field: '${field.displayName}'  Value: '${field.fieldValue}'  `;

              validationErrors.push(errMsg);

              field.error = 'Field must be numeric';
              validDoc = false;
              fieldError = true;
              newError = true;
            }
          }
        }
      }

      if (!fieldError) {
        field.error = '';
      }
    });


    let extendersNotLoaded = [];
    let totalExtenderCount = 0;
    //now lets do lines
    doc.fieldSets.forEach((fieldSet) => {
      //let rows = fieldSet.rows;
      for (var i = 0; i < fieldSet.rows.length; i++) {
        let row = fieldSet.rows[i];
        let fields = row.fieldData;
        let pendingExtenders = 0;
        fields.forEach((field) => {
          //let fieldError = false;
          let dep = this.findDependency(
            fieldSet.rowDependencies,
            field.fieldId
          );
          if (dep !== null && dep.hasExtender) {
            totalExtenderCount++;
            let exists = field.allowedValues.some(
              (x) => x.textValue.toUpperCase === field.fieldValue.toUpperCase
            );
            if (field.error.length > 0) {

              let errMsg = `Row value not allowed by extender. ${field.error}  Field: '${field.fieldDef.colName}' Row: '${row.rowId}' `;
              validationErrors.push(errMsg);
              validDoc = false;
            } else if (
              exists === false &&
              field.fieldValue !== null &&
              field.fieldValue !== '' &&
              field.displayName === undefined
            ) {

              // this is to get % of extenders processed
              extendersNotLoaded.push(i);
              validDoc = false;
              //field.error ='Field has no values';
              fieldError = true;
            }
            else if (
              exists === false &&
              field.fieldValue !== null &&
              field.fieldValue !== ''
            ) {
              //PostAlert('error', 'UHOH');
              let errMsg = `Row value not allowed by extender.  Field: '${field.displayName}' Value: '${field.fieldValue}' Row: '${row.rowId}' `;
              validationErrors.push(errMsg);
              validDoc = false;
              field.error =
                'Value not allowed by extender {' + field.fieldValue + '}';
              validDoc = false;
              fieldError = true;
            }

            else {
              //PostAlert('error', 'UHOH');
              // field.error = '';
              // fieldError = false;
            }
          }
        });
        if(pendingExtenders>0) incompleteExtenders++;
      }
    });

    // instead of giving generic error, give error that tells how many have processed
    if (extendersNotLoaded.length > 0){
      let finished = totalExtenderCount - extendersNotLoaded.length;
      let percent = Math.round((finished/totalExtenderCount)*100);
      validationErrors.push('Line values are still loading. ' + percent + '% complete. Please wait a moment and try again.');
    }
    if (validationErrors.length > 0) {
      this.setState({ errorMessages: validationErrors });
    }
    return validDoc;
  }

  checkQueueChanges() {
    return this.state.queuesDirty;
  }

  GetValueSnapShot(isHeaderValue, rowNo, currentFieldSetId, currentFieldId) {
    let doc = this.state.currentDoc;

    let fieldSnap = [];
    doc.headerFields.forEach((field) => {
      var obj = {
        fieldId: field.fieldId,
        fieldValue: field.fieldValue,
        isHeaderField: true,
        extenderKey: field.extenderKey,
        hasExtender: field.hasExtender,
        fieldSetId: -1,
        rowNo: -1,
      };

      if (currentFieldSetId === null || currentFieldSetId === undefined) {
        obj.fieldSetId = -1;
      }
      if (obj.extenderKey === undefined || obj.extenderKey === null) {
        obj.extenderKey = '';
      }

      if (obj.extenderKey === '' && field.hasExtender) {
        //lets try and find extender keys
        var ext = field.extender.allowedValues.find(
          (x) => x.textValue === field.fieldValue
        );
        if (ext !== null && ext !== undefined) {
          obj.extenderKey = ext.extenderKey;
        }
      }

      fieldSnap.push(obj);
    });

    if (!isHeaderValue) {
      if (currentFieldSetId === null && currentFieldId !== null) {
        //var x = 1;
        let fieldSets = doc.fieldSets;

        fieldSets.forEach((fs) => {
          var check = fs.fieldDefs.find((y) => y.fieldId === currentFieldId);
          if (check !== null && check !== undefined) {
            currentFieldSetId = fs.fieldSetId;
          }
        });
      }

      let fs = doc.fieldSets.find((f) => f.fieldSetId === currentFieldSetId);

      if (
        fs !== undefined &&
        fs !== null &&
        fs.rows !== undefined &&
        fs.rows !== null
      ) {
        let row = fs.rows.find((r) => r.rowId === rowNo);
        if (row !== null) {
          row.fieldData.forEach((rowField) => {
            let matchingFfs = fs.fieldDefs.find(
              (x) => x.fieldId === rowField.fieldId
            );
            var obj = {
              fieldId: rowField.fieldId,
              fieldValue: rowField.fieldValue,
              isHeaderField: false,
              extenderKey: rowField.keyValue,
              hasExtender: matchingFfs.hasExtender,
              fieldSetId: currentFieldSetId,
              rowNo: rowNo,
            };
            if (obj.extenderKey === undefined || obj.extenderKey === null) {
              obj.extenderKey = '';
            }

            if (obj.extenderKey === '' && rowField.hasExtender) {
              //lets try and find extender keys
              var ext = rowField.allowedValues.find(
                (x) => x.textValue === rowField.fieldValue
              );
              if (ext !== null && ext !== undefined) {
                obj.extenderKey = ext.extenderKey;
              }
            }

            fieldSnap.push(obj);
          });
        }
      }
      //row.fieldData.forEach((rowField) => {
      //let matchingFfs = fs.fieldDefs.find(x=> x.fieldId==rowField.fieldId);
      //});
    }

    return fieldSnap;
  }

  GetValueSnapShot2(isHeaderValue, rowNo, currentFieldSetId, currentFieldId) {
    let doc = this.state.currentDoc;

    let fieldSnap = [];
    doc.headerFields.forEach((field) => {
      var obj = {
        fieldId: field.fieldId,
        fieldValue: field.fieldValue,
        isHeaderField: true,
        extenderKey: field.extenderKey,
        hasExtender: field.hasExtender,
        fieldSetId: -1,
        rowNo: -1,
      };

      if (obj.fieldValue === null) {
        obj.fieldValue = '';
      }

      if (currentFieldSetId === null || currentFieldSetId === undefined) {
        obj.fieldSetId = -1;
      }
      if (obj.extenderKey === undefined || obj.extenderKey === null) {
        obj.extenderKey = '';
      }

      if (obj.extenderKey === '' && field.hasExtender) {
        //lets try and find extender keys
        var ext = field.extender.allowedValues.find(
          (x) => x.textValue === field.fieldValue
        );
        if (ext !== null && ext !== undefined) {
          obj.extenderKey = ext.extenderKey;
        }
      }

      fieldSnap.push(obj);
    });

    if (!isHeaderValue) {
      if (currentFieldSetId === null && currentFieldId !== null) {
        //var x = 1;
        let fieldSets = doc.fieldSets;

        fieldSets.forEach((fs) => {
          var check = fs.fieldDefs.find((y) => y.fieldId === currentFieldId);
          if (check !== null && check !== undefined) {
            currentFieldSetId = fs.fieldSetId;
          }
        });
      }

      let fs = doc.fieldSets.find((f) => f.fieldSetId === currentFieldSetId);

      if (
        fs !== undefined &&
        fs !== null &&
        fs.rows !== undefined &&
        fs.rows !== null
      ) {
        let row = fs.rows.find((r) => r.rowId === rowNo);
        if (row !== null) {
          row.fieldData.forEach((rowField) => {
            let matchingFfs = fs.fieldDefs.find(
              (x) => x.fieldId === rowField.fieldId
            );
            if (matchingFfs) {
              var obj = {
                fieldId: rowField.fieldId,
                fieldValue: rowField.fieldValue,
                isHeaderField: false,
                extenderKey: rowField.keyValue,
                hasExtender: matchingFfs.hasExtender,
                fieldSetId: currentFieldSetId,
                rowNo: rowNo,
              };
              if (obj.extenderKey === undefined || obj.extenderKey === null) {
                obj.extenderKey = '';
              }

              if (obj.extenderKey === '' && rowField.hasExtender) {
                //lets try and find extender keys
                var ext = rowField.allowedValues.find(
                  (x) => x.textValue === rowField.fieldValue
                );
                if (ext !== null && ext !== undefined) {
                  obj.extenderKey = ext.extenderKey;
                }
              }

              fieldSnap.push(obj);
            }
          });
        }
      }
    }
    //row.fieldData.forEach((rowField) => {
    //let matchingFfs = fs.fieldDefs.find(x=> x.fieldId==rowField.fieldId);
    //});

    return fieldSnap;
  }

  closeAllList() {
    var x = document.getElementsByClassName('autocomplete-items');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
  }

  removeAssignment(userId) {
    let doc = this.state.currentDoc;
    let assignment = doc.queueAssignments.find((a) => a.userId === userId);
    if (assignment) {
      if (assignment.assignStatus === 'NEW') {
        let idx = doc.queueAssignments.indexOf(assignment);
        doc.queueAssignments.splice(idx, 1);
      } else {
        assignment.assignStatus = 'DELETE';
      }
      let dirty = false;
      doc.queueAssignments.forEach((q) => {
        if (q.assignStatus === 'NEW') dirty = true;
        if (q.assignStatus === 'DELETE') dirty = true;
      });
      this.setState({ currentDoc: doc, queuesDirty: dirty });
    }
    //ChangeQueueAssignment(userId, doc.documentId, doc.workflowStepId, 'REMOVE');
  }

  editAssignUser(e) {
    var x = document.getElementsByClassName('docAssignUserItem');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }

    let doc = this.state.currentDoc;
    let auto = document.getElementById('docContainerAssignUserText');

    let val = e.target.value;
    let a = document.createElement('DIV');
    a.setAttribute('id', 'autocomplete-list');
    a.setAttribute('class', 'docAssignUserItem');

    a.setAttribute('style', 'max-height: 200px; overflow: auto');
    auto.appendChild(a);
    for (let i = 0; i < doc.assignableUsers.length; i++) {
      if (
        doc.assignableUsers[i].userName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase() ||
        doc.assignableUsers[i].fullName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase()
      ) {
        let b = document.createElement('DIV');
        b.setAttribute('class', 'docAssignItem');
        b.innerHTML =
          '<div><strong tabIndex="-1">' +
          doc.assignableUsers[i].fullName +
          '</strong></div>';
        b.innerHTML +=
          '<div class="docAssignSubHeader">' +
          doc.assignableUsers[i].userName +
          '</div>';
        b.addEventListener('mousedown', (e) =>
          this.onClickAssignableUser(e, doc.assignableUsers[i])
        );
        a.appendChild(b);
      }
    }

    //this.props.updateValue(e.target.value, this.props.fieldId, this.props.systemName);
    this.setState({ userSearch: e.target.value });
  }

  editMoveAssignUser(e) {
    var x = document.getElementsByClassName('moveAssignUserItem');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }

    let auto = document.getElementById('moveContainerAssignUserText');

    let val = e.target.value;
    let a = document.createElement('DIV');
    a.setAttribute('id', 'autocomplete-list');
    a.setAttribute('class', 'moveAssignUserItem');

    a.setAttribute('style', 'max-height: 260px; overflow: auto');
    auto.appendChild(a);
    for (let i = 0; i < this.state.moveAssignUsers.length; i++) {
      if (
        this.state.moveAssignUsers[i].userName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase() ||
        this.state.moveAssignUsers[i].fullName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase()
      ) {
        let b = document.createElement('DIV');
        b.setAttribute('class', 'docAssignItem');
        b.innerHTML =
          '<div><strong tabIndex="-1">' +
          this.state.moveAssignUsers[i].fullName +
          '</strong></div>';
        b.innerHTML +=
          '<div class="docAssignSubHeader">' +
          this.state.moveAssignUsers[i].userName +
          '</div>';
        b.addEventListener('mousedown', (e) =>
          this.onClickMoveAssignableUser(e, this.state.moveAssignUsers[i])
        );
        a.appendChild(b);
      }
    }
    this.setState({ moveAssignUserSearch: e.target.value });
  }

  onClickMoveAssignableUser(e, user) {
    let assignedUsers = this.state.moveAssignSelectedUsers;
    let idx = assignedUsers.indexOf(user);
    if (idx === -1) {
      assignedUsers.push(user);
      this.setState({ moveAssignSelectedUsers: assignedUsers });
    }
  }

  onClickRemoveMoveAssignUser(e, user) {
    let assignedUsers = this.state.moveAssignSelectedUsers;
    let idx = assignedUsers.indexOf(user);
    assignedUsers.splice(idx, 1);
    this.setState({ moveAssignSelectedUsers: assignedUsers });
  }

  assignUserBlur() {
    var x = document.getElementsByClassName('docAssignUserItem');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
    this.setState({ userSearch: '' });
  }

  moveAssignUserBlur() {
    var x = document.getElementsByClassName('moveAssignUserItem');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
    this.setState({ moveAssignUserSearch: '' });
  }

  onClickAssignableUser(e, au) {
    let doc = this.state.currentDoc;
    let newAssignment = {
      userId: au.userId,
      assignStatus: 'NEW',
      userName: au.userName,
      canUnassign: true,
    };
    doc.queueAssignments.push(newAssignment);
    this.setState({ currentDoc: doc, queuesDirty: true });
    //ChangeQueueAssignment(au.userId, doc.documentId, doc.workflowStepId, 'ADD');
  }

  toggleUserNotes() {
    let sun = this.state.showUserNotes;
    if (sun) {
      let ivw = window.innerWidth - 280 - 20;
      this.setState({
        showUserNotes: false,
        showAddNote: false,
        imageviewerWidth: ivw,
      });
      UpdateSetting('pin_user_notes', 'false');
    } else {
      let ivw = window.innerWidth - 280 - 20 - 280;
      this.setState({
        showUserNotes: true,
        showAddNote: true,
        imageviewerWidth: ivw,
      });
      UpdateSetting('pin_user_notes', 'true');
    }
  }

  toggleAddNote() {
    let addNote = this.state.showAddNote;
    this.setState({ showAddNote: !addNote });
  }

  addNote() {
    AddNote(this.state.newNote, this.state.currentDoc.documentId);
    this.setState({ newNote: '' });
  }

  cancelReject() {
    this.setState({ showReasonForReject: false, rejectReasonNote: '' });
  }

  confirmReject() {
    AddNote(this.state.rejectReasonNote, this.state.currentDoc.documentId);
    SubmitDocument('REJECT', this.state.currentDoc);
    this.setState({ showReasonForReject: false, rejectReasonNote: '' });
  }

  editNewNote(e) {
    this.setState({ newNote: e.target.value });
  }

  editRejectNote(e) {
    this.setState({ rejectReasonNote: e.target.value });
  }

  convertDate2(strDate) {
    //console.log(strDate);
    if (strDate === null) {
      return '';
    }
    let strDate2 = strDate.toString();
    if (!strDate2.endsWith('Z')) {
      strDate2 = strDate2 + 'Z';
    }
    let date = new Date(strDate2);
    let d1 = date.getDate();
    let day = d1.toString();
    let m1 = date.getMonth();
    m1 = m1 + 1;
    let mon = m1.toString();
    let year = date.getFullYear().toString();
    let h1 = date.getHours();
    //console.log(h1);
    let mer = 'AM';
    if(h1 > 11) {
      mer = 'PM';
    }
    if(h1 === 24) {
      mer = 'AM';
    }
    if (h1 > 12) {
      h1 = h1 - 12;
    }
    if (h1 === 0) {
      h1 = 12;
    }

    let h = h1.toString();
    let m = date.getUTCMinutes().toString();
    if (m.length === 1) {
      m = '0' + m;
    }

    let fulldtime =
      mon + '/' + day + '/' + year + ' ' + h + ':' + m + ' ' + mer;
    return fulldtime;
  }

  // Excel paste
  toggleExcelPaste() {
    let excelOpen = this.state.showExcelPaste;
    this.setState({ showExcelPaste: !excelOpen, excelData: [] });
  }

  pasteExcel(e) {
    if (e.target.value.length > 1) {
      navigator.clipboard.readText().then((clipText) => {
        let currentRowId = 1;
        let currentString = '';
        let wordStart = 0;
        let currentCell = 1;
        let excelData = [];
        let currentRow = { rowId: currentRowId, cells: [] };

        for (var i = 0; i < clipText.length; i++) {
          let charId = clipText.charCodeAt(i);
          if (charId === 9) {
            // tab
            currentString = clipText.substring(wordStart, i);
            wordStart = i + 1;

            currentRow.cells.push({
              cellId: currentCell,
              value: currentString,
            });
            currentCell++;
          } else if (charId === 13) {
            // new line
            currentString = clipText.substring(wordStart, i);
            wordStart = i + 1;

            currentRow.cells.push({
              cellId: currentCell,
              value: currentString,
            });
            currentCell = 1;
            excelData.push({ rowId: currentRowId, rowData: currentRow });
            currentRowId++;
            currentRow = { rowId: currentRowId, cells: [] };
          }
        }
        this.setState({ excelData: excelData });
      });
    }
  }

  replaceExcelData() {
    let rows = this.state.rowData;
    let lastRow = this.state.rowData[this.state.rowData.length - 1];

    let excelData = this.state.excelData;
    let nextRowId = 0;
    let fieldSet = this.state.currentFieldSet;
    let maxRow = {
      rowId: 1,
      fieldData: [],
    };

    let newRow = {};
    let rowsToRemove = [];
    rows.forEach((row) => {
      if(!row.isNew) { rowsToRemove.push(row); }
      else newRow = row;
    });
    rowsToRemove.forEach((row) => {
      let idx = rows.indexOf(row);
      rows.splice(idx, 1);
    });
    if (nextRowId === 0) nextRowId = 1;
    let rowIndex = rows.indexOf(maxRow);

    excelData.forEach((ed) => {
      let newRow = {
        rowId: nextRowId,
        dbRowId: -1,
        isChecked: false,
        isNew: false,
        isDeleted: false,
        fieldData: [],
      };
      let cellIndex = 0;
      this.state.currentFieldSet.fieldDefs.forEach((fd) => {
        let newField = {
          fieldId: fd.fieldId,
          fieldDef: fd,
          fieldValue: '',
          fieldDataType: 0,
          allowedValues: [],
          directChildDependents: fd.directChildDependents,
          error: '',
        };
        // if (fd.hasExtender) {
        //   //newField.allowedValues = fd.extender.allowedValues;
        //   if (fd.extender.allowedValues.length === 0){
        //     let rowData = lastRow.fieldData.find((r) => r.fieldId === fd.fieldId);
        //     newField.allowedValues = rowData.allowedValues;
        //     fd.extender.allowedValues = rowData.allowedValues;
        //   }
        //   else{
        //     newField.allowedValues = fd.extender.allowedValues;
        //   }
        // }


        if (ed.rowData.cells.length > cellIndex) {
          newField.fieldValue = ed.rowData.cells[cellIndex].value.replace(
            /\n/g,
            ''
          );
        }
        cellIndex++;

        newRow.fieldData.push(newField);
      });
      nextRowId++;
      rowIndex++;
      rows.splice(rowIndex, 0, newRow);
      this.processExtenderRow(newRow, fieldSet);

      //rows.push(newRow);
    });
    newRow.rowId = nextRowId;

    // need to do this so the blank row does not have the same id as any of the rows that were replaced
    // lastRow.rowId = rows.length + 1;
    // rows.push(lastRow);

    // let currentDocFieldSet = this.state.currentDoc.fieldSets.find(
    //   (fs) => fs.fieldSetId === this.state.currentFieldSet.fieldSetId
    // );

    // currentDocFieldSet.rows = rows;
    console.log(rows);
    this.setState(
      { rowData: rows, showExcelPaste: false },
      this.reviewSnapshots()
    );

  }

  appendExcelData() {
    let rows = this.state.rowData;
    let excelData = this.state.excelData;
    let nextRowId = 0;
    let fieldSet = this.state.currentFieldSet;
    let maxRow = {
      rowId: 1,
      fieldData: [],
    };

    let newRow = {};
    rows.forEach((row) => {
      if (row.rowId >= nextRowId && row.isNew !== true) {
        nextRowId = row.rowId + 1;
        maxRow = row;
      }
      if (row.isNew === true) {
        newRow = row;
      }
    });
    if (nextRowId === 0) nextRowId = 1;
    let rowIndex = rows.indexOf(maxRow);

    excelData.forEach((ed) => {
      let newRow = {
        rowId: nextRowId,
        dbRowId: -1,
        isChecked: false,
        isNew: false,
        isDeleted: false,
        fieldData: [],
      };
      let cellIndex = 0;
      this.state.currentFieldSet.fieldDefs.forEach((fd) => {
        let newField = {
          fieldId: fd.fieldId,
          fieldDef: fd,
          fieldValue: '',
          fieldDataType: 0,
          allowedValues: [],
          directChildDependents: fd.directChildDependents,
          error: '',
        };
        // if (fd.hasExtender) {
        //   newField.allowedValues = fd.extender.allowedValues;
        // }
        if (ed.rowData.cells.length > cellIndex) {
          newField.fieldValue = ed.rowData.cells[cellIndex].value.replace(
            /\n/g,
            ''
          );
        }
        cellIndex++;

        newRow.fieldData.push(newField);
      });
      nextRowId++;
      rowIndex++;
      rows.splice(rowIndex, 0, newRow);
      this.processExtenderRow(newRow, fieldSet);

      //rows.push(newRow);
    });
    newRow.rowId = nextRowId;

    this.setState(
      { rowData: rows, showExcelPaste: false },
      this.reviewSnapshots()
    );
    // this.state.currentFieldSet.fieldDefs.forEach(fd => {
    //    var newFd = {
    //       fieldDef: fd,
    //       fieldId: fd.fieldId,
    //       value: '',
    //    }
    //    if(newFd.fieldId === fieldId) newFd.value = text;

    // });

    // currentRows.push(rowData);
    // this.setState({rowData: currentRows});
  }

  toggleCheck(rowId) {
    let rows = this.state.rowData;
    let checkRow = rows.find((r) => r.rowId === rowId);
    if (checkRow) {
      if (checkRow.auditCheck === undefined) {
        checkRow.auditCheck = true;
      } else {
        checkRow.auditCheck = !checkRow.auditCheck;
      }
    }
    this.setState({ rowData: rows });
  }

  downloadAttachment(attchId, fileName) {
    DownloadAttachment(this.state.currentDoc.documentId, attchId, fileName);
  }

  deleteAttachment(attchId) {
    DeleteAttachment(this.state.currentDoc.documentId, attchId);
  }

  pullDocFromUser() {
    PullDocument(this.state.currentDoc.documentId);
  }

  uploadAttachment() {
    let input = document.getElementById('acctchUploadInput');
    input.value = '';
    document.getElementById('acctchUploadInput').click();
  }

  uploadAttachmentSubmit(e) {
    let input = document.getElementById('acctchUploadInput');
    if (input.files.length > 0) {
      UploadAttachment(
        input.files[0],
        input.files[0].fileName,
        this.state.currentDoc.documentId
      );
    }
  }

  onDropAttachment(files) {
    UploadAttachment(
      files[0], files[0].path, this.state.currentDoc.documentId
    )
  }

  toggleProperties() {
    let p = this.state.showProperties;
    this.setState({ showProperties: !p });
  }

  handleStampClick(e) {
    let menu = this.state.showStampMenu;
    this.setState({ showStampMenu: !menu });
    e.stopPropagation();
  }

  toggleStamps() {
    let s = this.state.showStampScreen;
    let req = this.state.stampRequest;
    let doc = this.state.currentDoc;
    if (!s) {
      let stampIndex = 1;
      req.stamps = [];
      doc.stampGroups.forEach((sg) => {
        let stamp = {
          stampId: stampIndex,
          stampDesc: sg.groupName,
          x: -1,
          y: -1,
        };
        req.stamps.push(stamp);
        stampIndex++;
      });
    }
    this.setState({ showStampScreen: !s, stampRequest: req });
  }

  showSaveDetails() {
    console.log('Save Details');
    console.log(`isSaving: ${this.state.isSaving}`);
    console.log(`fieldsHaveError: ${this.state.fieldsHaveError}`);
    console.log(`snapshots: ${this.state.snapshots.length}`);
    console.log(`userCanEdit: ${this.state.userCanEdit}`);
    console.log(`userCanMoveAssign ${this.state.userCanMoveAssign}`);
    console.log(`queuesDirty: ${this.state.queuesDirty}`);
  }

  editStampRequest(e, stampValue) {
    let req = this.state.stampRequest;
    switch (stampValue) {
      case 'docId':
        req.showDocId = e.target.checked;
        break;
      case 'userName':
        req.showUserName = e.target.checked;
        break;
      case 'date':
        req.showDate = e.target.checked;
        break;
      case 'time':
        req.showTime = e.target.checked;
        break;
      case 'size':
        req.size = parseInt(e.target.value);
        if (req.size === 1) req.sizeDesc = 'Small';
        if (req.size === 2) req.sizeDesc = 'Medium';
        if (req.size === 3) req.sizeDesc = 'Large';
        break;
      case 'transparency':
        req.useTransparency = e.target.checked;
        break;
      default:
        break;
    }
    this.setState({ stampValue: req });
  }

  stampImageClick(e) {
    let lastStamp = this.state.lastStampPos;
    let req = this.state.stampRequest;
    let nextStamp = lastStamp + 1;
    if (nextStamp >= req.stamps.length) {
      nextStamp = 0;
    }
    let s = req.stamps[nextStamp];
    let z = document.getElementById('stampPositionArea');
    let rect = z.getBoundingClientRect();

    let x = e.clientX - e.target.x;
    let y = e.clientY - e.target.y;
    let imageMax = Math.max(
      this.state.images[0].imageWidth,
      this.state.images[0].imageHeight
    );
    // let r =
    //   Math.max(
    //     this.state.images[0].imageWidth,
    //     this.state.images[0].imageHeight
    //   ) / 300;
    s.x = (x * imageMax) / 300;
    s.y = (y * imageMax) / 300;
    s.x = ((e.clientX - rect.x) * imageMax) / 300;
    s.y = ((e.clientY - rect.y) * imageMax) / 300;
    this.setState({ lastStampPos: nextStamp, stampRequest: req });
  }

  downloadDocument() {
    let doc = this.state.currentDoc;
    DownloadPDF(doc.documentId, doc.originalFileName);
  }

  downloadRelatedDocumentPacket() {
    let doc = this.state.currentDoc;
    let docIds = doc.relatedDocuments.map((a) => a.documentId);

    let originalDocId = doc.originalDocId;
    if (originalDocId === 0) {
      originalDocId = doc.documentId;
    }

    DownloadRelatedDocumentPacket(originalDocId, docIds);
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
}

  downloadDocumentWithStamps() {
    let doc = this.state.currentDoc;
    let sc = this.state.stampRequest;
    sc.stamps.forEach((st) => {
      st.groupName = st.stampDesc;
      st.stamps = [];
      st.x = Math.round(st.x);
      st.y = Math.round(st.y);
      let sr = doc.stampGroups.find((f) => f.groupName === st.stampDesc);
      if (sr) {
        st.groupStatus = sr.groupStatus;
        sr.stamps.forEach((s) => {
          var sDate = new Date(s.stampTime);
          console.log(sDate);
          var sDate2 = this.convertDate2(sDate);
          console.log(sDate2);
          console.log(sDate.toString());
          s.stampLocalTime = sDate2;
          console.log(s);
          st.stamps.push(s);
        });
      }
    });
    DownloadPDFWithStamps(doc.documentId, doc.originalFileName, sc);
  }

  downloadDocumentAndAttachments() {
    let doc = this.state.currentDoc;

    let originalDocId = doc.originalDocId;
    if (originalDocId === 0) {
      originalDocId = doc.documentId;
    }
    let originalFileName = `TrinDocs Document and Attachments for Document ${doc.documentId}.zip`;
    DownloadPDFWithAttachments(doc.documentId, originalFileName);
  }

  toggleShowMoveAssign() {
    let moveAssign = this.state.showMoveAssign;
    this.setState({
      showMoveAssign: !moveAssign,
      moveAssignStepId: -1,
      moveAssignSelectedUsers: [],
    });
  }

  editMoveAssignStep(e) {
    this.setState({ moveAssignStepId: parseInt(e.target.value) });
    let doc = this.state.currentDoc;
    FetchAssignableUsers(
      parseInt(e.target.value, 10),
      this.state.currentDoc.businessProcessId,
      doc.documentId,
    );
  }

  moveAssignDoc() {
    let uids = [];
    this.state.moveAssignSelectedUsers.forEach((u) => uids.push(u.userId));
    MoveAssignDocument(
      this.state.currentDoc.documentId,
      this.state.moveAssignStepId,
      uids,
      this.state.currentDoc
    );
    this.toggleShowMoveAssign();
  }

  onRelatedMouseIn(e, relatedDoc) {
    let rect = e.target.getBoundingClientRect();
    let info = this.state.relatedInfo;
    info.show = true;
    info.left = Math.round(rect.x) + Math.round(rect.width) - 170;
    info.field = relatedDoc.field;
    info.formType = relatedDoc.formType;
    this.setState({ relatedInfo: info });
  }

  onRelatedMouseOut(e) {
    let info = this.state.relatedInfo;
    info.show = false;
    this.setState({ relatedInfo: info });
  }

  onRelatedMouseClick(e) {}

  onRelatedClick(e, doc) {
    if(e.ctrlKey)
    {
      window.open('/Document/' + doc.documentId);
    } else {
      let relatedDocLink = document.getElementById(
        'relatedDocLink' + doc.documentId
      );
      this.setState({ relatedDocChange: true });
      relatedDocLink.click();
    }
  }

  onBackToOriginalDoc() {
    if(this.state.currentDoc.originalDocId !== 0)
    {
      let originalDocLink = document.getElementById('originalDocLink');
      this.setState({ relatedDocChange: true });
      originalDocLink.click();
    }
  }

  rotateDocImageLeft() {
    RotateImage(
      this.state.currentDoc.documentId,
      this.state.currentImage.pageId,
      270,
      this.state.currentImage.imageBytes
    );
    this.setState({ isRotatingLeft: true });
  }

  rotateDocImageRight() {
    RotateImage(
      this.state.currentDoc.documentId,
      this.state.currentImage.pageId,
      90,
      this.state.currentImage.imageBytes
    );
    this.setState({ isRotatingRight: true });
  }

  toggleChangeBusinessProcess() {
    if (this.state.availableBps.length === 0) {
      GetBPList();
    }
    let show = this.state.showChangeBusinessProcess;
    this.setState({ showChangeBusinessProcess: !show });
    this.setState({ templateSearchText2: '' });
  }

  editBps(e) {
    let selectedVal = parseInt(e.target.value, 10);
    this.setState({ selectedBpId2: selectedVal });

    GetAvailableTemplatesByBp2(selectedVal);
  }

  editSelectedChangeBpTemplate(e) {
    let selectedVal = parseInt(e.target.value, 10);
    this.setState({ selectedChangeBpTemplate: selectedVal });

    //GetAvailableTemplatesByBp2(selectedVal);
  }

  onBPChange() {
    let lastChange = BPStore.getLastChange();
    if (lastChange === actionTypes.BPGetList) {
      let bps = BPStore.getBPList();
      this.setState({
        availableBps: bps,
      });
    }
  }

  onChangeTemplateSearchText2(e) {
    this.setState({ templateSearchText2: e.target.value });
    this.filterTemplates2(e.target.value);
  }

  filterTemplates2(searchText) {
    if (searchText === '') {
      this.setState({
        filteredTemplates2: [...this.state.availableTemplates2],
      });
    } else {
      let newList = [];
      this.state.availableTemplates2.forEach((temp) => {
        if (
          temp.templateName.toLowerCase().includes(searchText.toLowerCase())
        ) {
          newList.push(temp);
        }
      });
      this.setState({ filteredTemplates2: newList });
      if (newList.length > 0) {
        this.setState({ suggestedTemplateId2: newList[0] });
      }
    }
  }

  onClickTemplateSearch2() {
    if (this.state.selectedTemplate2.templateId > 0) {
      this.setState({
        selectedTemplate2: {
          templateId: -1,
          templateName: 'Template...',
        },
        templateSearchText2: '',
      });
      this.filterTemplates2('');
    }
  }

  templateSuggestionClick2(tempOption) {
    this.setState({
      selectedTemplate2: tempOption,
      templateSearchText2: tempOption.templateName,
    });
  }

  getTableWidth() {
    let defs = this.state.currentFieldSet.fieldDefs;
    if (defs === undefined) return 0;
    let w = 160;
    defs.forEach((d) => {
      w += d.colWidth;
    });
    return w;
  }

  emailPDF() {
    let doc = this.state.currentDoc;
    let defaultSubject = `Please see the attached TrinDocs document, TrinDocs ID # ${doc.documentId.toString()}`;
    this.setState({
      showEmailPDF: true,
      emailTo: '',
      emailSubject: defaultSubject,
      emailMessage: '',
    });
  }

  moveBpDocsClick() {
    var selectedDocs = [];

    selectedDocs.push(this.state.currentDocId);
    ChangeBusinessProcesses(
      selectedDocs,
      this.state.selectedChangeBpTemplate,
      this.state.applyTemplate2,
      this.state.selectedBpId2
    );
    this.setState({ showChangeBusinessProcess: false });
  }

  cancelEmailPDF() {
    this.setState({ showEmailPDF: false });
  }

  onEditEmailTo(e) {
    this.setState({ emailTo: e.target.value });
  }

  onEditEmailSubject(e) {
    this.setState({ emailSubject: e.target.value });
  }

  onEditEmailMessage(e) {
    this.setState({ emailMessage: e.target.value });
  }

  validateAndSendEmail() {
    let emailAddress = this.state.emailTo;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+/.test(emailAddress)) {
      SendEmail(
        this.state.emailTo,
        this.state.emailSubject,
        this.state.emailMessage,
        this.state.currentDoc.documentId
      );
      this.setState({ showEmailPDF: false });
    } else {
    }
  }

  inpsectTemplate() {
    RequestNav('Template Editor', 'inspectTempLink');
  }

  goToActiveDocs() {
    let link = document.getElementById('actdocLink');
    link.click();
  }

  toggleShowErrorMessage() {
    this.setState({ showErrorMessage: false });
  }

  clearCellError(rowId, colId)
  {
    let fs1 = this.state.currentFieldSet;
    let doc = this.state.currentDoc;
    let fs2 = doc.fieldSets.find((fs) => fs.fieldSetId == fs1.fieldSetId);

    // find row
    let r = parseInt(rowId, 10);
    let c = parseInt(colId, 10);
    let row = fs2.rows.find((rw) => rw.rowId === r);

    let col = row.fieldData.find((fd) => fd.fieldId === c);
    col.error = '';
    //this.errorRowField(fs1.fieldSetId, r, c);
  }

  setCellError(rowId, colId, errorMsg) {

    let fs1 = this.state.currentFieldSet;
    let doc = this.state.currentDoc;
    let fs2 = doc.fieldSets.find((fs) => fs.fieldSetId == fs1.fieldSetId);

    // find row
    let r = parseInt(rowId, 10);
    let c = parseInt(colId, 10);
    let row = fs2.rows.find((rw) => rw.rowId === r);
    console.log(row);
    console.log(c);
    let col = row.fieldData.find((fd) => fd.fieldId === c);
    console.log(col);
    col.error = errorMsg;
    this.errorRowField(fs1.fieldSetId, r, c);
  }

  clearChildCells(row, cell) {}

  toggleShowAlert()
  {
    let alert = this.state.showAlert;
    this.setState({showAlert: !alert});
    if(this.state.alertNextOnClose)
    {
      this.loadDocumentFromDB();
    }
  }

  setAuditTab(tab)
  {
    this.setState({currentAuditView: tab});
  }

  undeleteDocument() {
    UndeleteDocument(this.state.currentDocId);
  }

  onMagicScroll() {
    if (useMagic) {
      let magic = document.getElementById('magicScroll');
      let main = document.getElementById('mainRowGrid');
      main.scrollTop =
        (magic.scrollTop * main.scrollHeight) / magic.scrollHeight;
    } else {
      useMagic = true;
    }
  }

  onMainScroll(e) {
    let main = document.getElementById('mainRowGrid');
    let magic = document.getElementById('magicScroll');

    let top = main.scrollTop + e.deltaY;
    if (top < 0) top = 0;
    if (top > main.scrollHeight) top = main.scrollHeight;

    magic.scrollTop = top;

    useMagic = false;
  }



  analyzeTemplate(e) {
    if(e.ctrlKey) {
      window.open('/TemplateEditor/'+ this.state.currentDoc.templateId + '/' + this.state.currentDoc.documentId);
    } else {
      let returnPath = window.location.pathname + window.location.search;
      localStorage.setItem('docReturn', returnPath);
      RequestNav('Template Editor', 'inspectTempLink');
      //docLink.click();
      // this.setState({ goToDocId: docId }, () => {
      //   let docLink = document.getElementById('docLink');
      //   docLink.click();
      // });
    }
  }

  expandView(fieldText, displayName) {
    this.setState({showExpandedView: true, expandedViewText: fieldText, expandedViewTitle: displayName})
  }

  hideExpandedView()
  {
    this.setState({showExpandedView: false});
  }

  render() {
    return (
      <div
        id='tdDocEditor' // full document editor
        className='container-fluid d-flex flex-column'
        style={{ height: '100%', padding: '0px' }}
        onClick={this.handleDocumentClick}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}

      >
        {this.state.isLoading && (
          <div className='de-loadingScreen'>
            <div style={{ marginRight: '8px' }}>
              <FontAwesomeIcon icon={solid('spinner')} size='3x' spin />
            </div>
            <h4>Loading Document</h4>
          </div>
        )}
        <Prompt isDirty={this.state.snapshots.length > 1} />
        {this.state.currentDoc !== undefined &&
          (this.state.currentDoc.hasRelatedDocs ||
            this.state.currentDoc.originalDocId !== 0) && (
            <div //Related Files
              style={{
                backgroundColor: '#E9E9E9',

                borderBottom: 'solid 1px #B2BABB',
                borderTop: 'solid 1px #B2BABB',
              }}
            >

              <Link
                id='originalDocLink'
                to={'/Document/' + this.state.currentDoc.originalDocId}
                style={{ display: 'none' }}
              ></Link>
              <ul
                className='relatedDocsList'
                style={{ width: window.innerWidth }}
              >
                <li
                  className={
                    this.state.currentDoc.originalDocId === 0
                      ? 'relatedHeader'
                      : 'relatedLabel'
                  }
                  onClick={(e) => this.onBackToOriginalDoc()}
                >
                  {/* className='relatedHeader' */}
                  Document:
                  {this.state.currentDoc.originalDocId !== 0 && (
                    <span>{this.state.currentDoc.originalDocId}</span>
                  )}
                  {this.state.currentDoc.originalDocId === 0 && (
                    <span>{this.state.currentDoc.documentId}</span>
                  )}
                </li>

                <li className='relatedLabel'>Related{':'}</li>

                {this.state.currentDoc.relatedDocuments.map((relatedDoc) => (
                  <li
                    key={relatedDoc.documentId}
                    className={
                      this.state.currentDoc.documentId === relatedDoc.documentId
                        ? 'relatedHeader'
                        : 'relatedLabel'
                    }
                    onMouseEnter={(e) => this.onRelatedMouseIn(e, relatedDoc)}
                    onMouseLeave={this.onRelatedMouseOut}
                    onClick={(e) => this.onRelatedClick(e, relatedDoc)}
                  >
                    <Link
                      id={'relatedDocLink' + relatedDoc.documentId}
                      to={
                        this.state.currentDoc.originalDocId === 0
                          ? '/Document/' +
                            this.state.currentDocId +
                            '?related=' +
                            relatedDoc.documentId
                          : '/Document/' +
                            this.state.currentDoc.originalDocId +
                            '?related=' +
                            relatedDoc.documentId
                      }
                      style={{ display: 'none' }}
                    ></Link>
                    {/* className='relatedLink' */}
                    {relatedDoc.documentId}
                  </li>
                ))}
              </ul>
            </div>
          )}
        <div //menu bar
          className='d-flex flex-wrap justify-content-between'
          style={{
            width: '100%',
            backgroundColor: '#E9E9E9',
            borderBottom: 'solid 1px #B2BABB',
            borderTop: 'solid 1px #B2BABB',
          }}
          id='tdDocumentMenu'
        >
          <div>
            <Link id='backLink' to={this.state.returnUrl} />
            <ActionButton
              iconClass='thumbs-up'
              iconColor='#38C158'
              toolTip='Approve'
              isDisabled={
                !this.state.userCanSubmit ||
                this.state.isSaving ||
                //this.state.fieldsHaveError ||
                this.state.isSubmitting ||
                this.state.isLoading
              }
              waiting={this.state.isSubmitting}
              showBadge={false}
              badgeValue=''
              onClick={this.handleApproveClick}
            />
            <ActionButton
              iconClass='thumbs-down'
              iconColor='#DF3E3E'
              toolTip='Reject'
              isDisabled={
                !this.state.userCanSubmit ||
                this.state.isSaving ||
                //this.state.fieldsHaveError ||
                this.state.isSubmitting ||
                this.state.isLoading
              }
              waiting={this.state.isSubmitting}
              showBadge={false}
              badgeValue=''
              onClick={this.handleRejectClick}
            />
            <ActionButton
              iconClass={this.state.isSaving ? 'saving' : 'save'}
              iconColor='#138D75'
              toolTip='Save'
              isDisabled={
                this.state.isSaving ||
                ((this.state.fieldsHaveError ||
                  this.state.snapshots.length === 1 ||
                  !this.state.userCanEdit) &&
                  //!this.state.userCanMoveAssign &&
                  !this.state.queuesDirty)
              }
              showBadge={false}
              badgeValue=''
              onClick={this.handleSaveClick}
            />
            <ActionButton
              iconClass='undo'
              iconColor='#8D78EE'
              toolTip='Undo'
              isDisabled={
                this.state.snapshots.length === 1 || this.state.isSaving
              }
              showBadge={false}
              badgeValue=''
              onClick={this.undoLatestChange}
            />
            <ActionButton
              iconClass='arrow-left'
              iconColor='#2172D3'
              toolTip='Get Previous Document'
              isDisabled={false}
              showBadge={false}
              badgeValue=''
              onClick={this.handlePreviousClick}
            />
            <ActionButton
              iconClass='arrow-right'
              iconColor='#2172D3'
              toolTip='Get Next Document'
              isDisabled={false}
              showBadge={false}
              badgeValue=''
              onClick={() => this.handleNextClick(false)}
            />

            <div className='dropdown float-start'>
              <ActionButton
                iconClass='paperclip'
                iconColor='#4B4B4B'
                toolTip='Attachments'
                isDisabled={!this.state.userCanAddNotes || this.state.isSaving}
                showBadge={true}
                badgeValue={this.state.attachmentCount.toString()}
                onClick={this.handleAttachmentClick}
              />
              {this.state.showAttachmentMenu && (
                <div className='dropdown-content'>
                  <div>
                    <strong>Attachments</strong>
                  </div>

                  {this.state.currentDoc.attachments.map((attch) => (
                    <div
                      className='attchMenu-attachment'
                      key={attch.attachmentId}
                    >
                      <div className='attchMenu-filename'>
                        <FontAwesomeIcon icon={regular('file-alt')} />{' '}
                        {attch.originalFileName}
                      </div>
                      <div className='attchMenu-attachBy'>
                        Attached by: {attch.uploadBy}
                      </div>
                      <div className='attchMenu-downloadDelete'>
                        <span
                          style={{
                            color: '#198754',
                            cursor: 'pointer',
                            marginRight: '8px',
                          }}
                          onClick={() =>
                            this.downloadAttachment(
                              attch.attachmentId,
                              attch.originalFileName
                            )
                          }
                        >
                          <FontAwesomeIcon icon={solid('download')} /> Download
                        </span>
                        {'   '}
                        <span
                          style={{ color: '#dc3545', cursor: 'pointer' }}
                          onClick={() =>
                            this.deleteAttachment(attch.attachmentId)
                          }
                        >
                          <FontAwesomeIcon icon={solid('times')} /> Delete
                        </span>
                      </div>
                    </div>
                  ))}
                  <hr />

                    <Dropzone
                    multiple={true}
                    onDrop={this.onDropAttachment}


                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: '' })}>
                          <input {...getInputProps()} />
                          <div style={{margin: '0px', cursor: 'pointer'}}>
                            <FontAwesomeIcon icon={solid('download')} /> Upload new
                            Attachment
                          </div>
                        </div>)}
                    </Dropzone>

                </div>
              )}
            </div>
            <ActionButton
              iconClass='trash-alt'
              iconColor='#EC4B4B'
              toolTip='Delete Document'
              isDisabled={!this.state.userCanDelete}
              showBadge={false}
              badgeValue=''
              onClick={this.handleTrashClick}
            />

            <div className='dropdown float-start'>
              <ActionButton
                iconClass='pdf'
                iconColor='#0DA6A6'
                toolTip='Download or Email PDF'
                isDisabled={false}
                showBadge={false}
                badgeValue=''
                onClick={this.handlePdfClick}
              />
              {this.state.showPdfMenu && (
                <div className='dropdown-content'>
                  <div
                    className='dropdown-menuitem'
                    onClick={this.downloadDocument}
                  >
                    <FontAwesomeIcon
                      icon={solid('download')}
                      style={{
                        color: '#38C158',
                        marginRight: '8px',
                      }}
                    />{' '}
                    Download PDF
                  </div>
                  {this.state.currentDoc.stampGroups.length > 0 && (
                    <div
                      className='dropdown-menuitem'
                      onClick={this.toggleStamps}
                    >
                      <FontAwesomeIcon
                        icon={solid('download')}
                        style={{
                          color: '#38C158',
                          marginRight: '8px',
                        }}
                      />{' '}
                      Download PDF with Stamps
                    </div>
                  )}
                  {this.state.currentDoc.attachments.length > 0 && (
                    <div className='dropdown-menuitem'
                        title='Download PDF and Attachments'
                        onClick={this.downloadDocumentAndAttachments}>
                      <FontAwesomeIcon
                        icon={solid('download')}
                        style={{
                          color: '#38C158',
                          marginRight: '8px',
                        }}
                      />{' '}
                      Download PDF and Attachments
                    </div>
                  )}
                  {this.state.currentDoc.relatedDocuments.length > 0 && (
                    <div
                      className='dropdown-menuitem'
                      onClick={this.downloadRelatedDocumentPacket}
                    >
                      <FontAwesomeIcon
                        icon={solid('download')}
                        style={{
                          color: '#38C158',
                          marginRight: '8px',
                        }}
                      />{' '}
                      Download related document packet
                    </div>
                  )}
                  <div className='dropdown-menuitem' onClick={this.emailPDF}>
                    <FontAwesomeIcon
                      icon={solid('envelope')}
                      style={{ marginRight: '8px' }}
                    />{' '}
                    E-Mail PDF
                  </div>
                </div>
              )}
            </div>

            <ActionButton
              iconClass='file'
              iconColor='#AE4BCF'
              toolTip='Send to image review'
              isDisabled={
                !this.state.userCanSendToReview || this.state.isSaving
              }
              showBadge={false}
              badgeValue=''
              onClick={this.handleImageRevClick}
            />
            {this.state.currentDoc !== undefined &&
              this.state.currentDoc.showStamps && (
                <div className='dropdown float-start'>
                  <button
                    className='btn float-start'
                    style={{
                      marginTop: '6px',
                      marginBottom: '6px',
                      position: 'relative',
                      marginLeft: '6px',
                      backgroundColor: '#DC754B',
                      color: '#FFF',
                    }}
                    title='Stamps'
                    disabled={this.state.hasStamps}
                    onClick={this.handleStampClick}
                  >
                    <FontAwesomeIcon icon={solid('stamp')} />{' '}
                    {this.state.currentDoc !== undefined && (
                      <>
                        {this.state.currentDoc.stampGroups.map((sg) => (
                          <span
                            className='badge rounded-pill bg-secondary'
                            key={sg.groupName}
                          >
                            {sg.groupStatus === 'APPROVED' && (
                              <FontAwesomeIcon
                                icon={solid('check-circle')}
                                style={{ color: '#4EF63A' }}
                              />
                            )}
                            {sg.groupStatus === 'REJECTED' && (
                              <FontAwesomeIcon icon={solid('times-circle')} />
                            )}
                            {sg.groupStatus === 'PENDING' && (
                              <FontAwesomeIcon icon={solid('minus-circle')} />
                            )}{' '}
                            {sg.stamps.length}
                          </span>
                        ))}
                      </>
                    )}
                  </button>

                  {this.state.showStampMenu && (
                    <div
                      className='dropdown-content'
                      style={{ width: '330px' }}
                    >
                      {this.state.currentDoc.stampGroups.map((sgroup) => (
                        <div className='stampMenu-stamp' key={sgroup.groupName}>
                          <div className='stampMenu-groupName'>
                            <FontAwesomeIcon icon={solid('stamp')} />{' '}
                            {sgroup.groupName}
                          </div>
                          {sgroup.stamps.map((stamp) => (
                            <div
                              key={stamp.userName}
                              className={
                                stamp.stampType === 'APPROVE'
                                  ? 'stampMenu-stampLineApprove'
                                  : stamp.stampType === 'REJECT'
                                  ? 'stampMenu-stampLineReject'
                                  : 'stampMenu-stampLinePending'
                              }
                            >
                              {stamp.stampType === 'APPROVE' && (
                                <FontAwesomeIcon icon={solid('thumbs-up')} />
                              )}
                              {stamp.stampType === 'REJECT' && (
                                <FontAwesomeIcon icon={solid('thumbs-down')} />
                              )}
                              {stamp.stampType === 'PENDING' && (
                                <FontAwesomeIcon icon={solid('minus-circle')} />
                              )}{' '}
                              {stamp.userName} -{' '}
                              {stamp.stampTime !== null &&
                                this.convertDate2(stamp.stampTime)}
                              {stamp.stampTime === null && <span>Pending</span>}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            {this.state.currentDoc &&
              this.state.currentDoc.queueAssignments.map((queue) => (
                <div
                  key={queue.userId}
                  className={`float-start mt-2
                           ${
                             queue.assignStatus === 'PENDING'
                               ? 'queueAssignOther'
                               : ''
                           }
                           ${
                             queue.assignStatus === 'APPROVED'
                               ? 'queueAssignApproved'
                               : ''
                           }
                           ${
                             queue.assignStatus === 'NEW'
                               ? 'queueAssignNew'
                               : ''
                           }
                           ${
                             queue.assignStatus === 'DELETE'
                               ? 'queueAssignDelete'
                               : ''
                           }
                           ${queue.assignStatus === 'ME' ? 'queueAssignMe' : ''}
                        `}
                >
                  {' '}
                  {queue.assignStatus !== 'APPROVED' && (
                    <FontAwesomeIcon icon={solid('user')} />
                  )}
                  {queue.assignStatus === 'APPROVED' && (
                    <FontAwesomeIcon
                      icon={solid('check')}
                      style={{ color: '#20B219' }}
                    />
                  )}{' '}
                  {queue.userName}{' '}
                  {queue.canUnassign && (
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.removeAssignment(queue.userId)}
                      icon={solid('times')}
                    />
                  )}
                </div>
              ))}
            {this.state.currentDoc &&
              (this.state.currentDoc.canAssign ||
                this.state.currentDoc.licenseLevel > 3) && (
                <div
                  id='docContainerAssignUserText'
                  className='float-start autocomplete2'
                >
                  <input
                    type='text'
                    style={{ margin: '7px 0px 7px 7px' }}
                    className='form-control'
                    placeholder='Assign User...'
                    onFocus={this.editAssignUser}
                    value={this.state.userSearch}
                    onBlur={this.assignUserBlur}
                    onChange={this.editAssignUser}
                  ></input>
                </div>
              )}
          </div>

          <div>
            <div className='dropdown float-end'>
              <button
                id='documentButton'
                className='btn btn-primary'
                style={{ margin: '7px 10px 7px 7px' }}
                onClick={this.toggleDocMenu}
              >
                Document: {this.state.documentID}{' '}
                <FontAwesomeIcon icon={solid('caret-down')} />
              </button>
              {this.state.showDocumentMenu && (
                <div className='document-menu-dropdown'>
                  <div className='row'>
                    <div
                      className='col-6'
                      style={{ borderRight: 'solid 1px #BBBBBB' }}
                    >
                      <div className='docMenu-heading-1'>Form Type</div>
                      <div className='docMenu-value-1'>
                        {this.state.currentDoc.formTypeName}
                      </div>
                      <hr />
                      <div className='docMenu-heading-1'>Template</div>
                      <div className='docMenu-value-1'>
                        {this.state.currentDoc !== undefined &&
                          this.state.currentDoc.templateName}
                      </div>
                      <hr />
                      <div className='docMenu-heading-1'>Workflow</div>
                      <div className='docMenu-value-1'>
                        {this.state.workflowName}
                      </div>
                      <hr />
                      <div className='docMenu-heading-1'>Step</div>
                      <div className='docMenu-value-1'>
                        {this.state.stepName}
                      </div>
                      <hr />
                      <div className='docMenu-heading-1'>Checked out by</div>
                      <div className='docMenu-value-1'>
                        {this.state.currentDoc !== undefined &&
                          this.state.currentDoc.checkedOutUser}
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='docMenu-heading-1'>Info</div>
                      <div
                        className='docMenu-menuItem'
                        onClick={this.toggleShowAudit}
                      >
                        <FontAwesomeIcon icon={solid('shield-alt')} /> Audit
                        Trail
                      </div>
                      <div
                        className='docMenu-menuItem'
                        onClick={this.toggleProperties}
                      >
                        <FontAwesomeIcon icon={regular('file-alt')} />{' '}
                        Properties
                      </div>
                      {this.state.userCanMoveAssign && (
                        <div>
                          <div className='docMenu-heading-1'>Edit</div>
                          <div
                            className='docMenu-menuItem'
                            onClick={this.toggleShowMoveAssign}
                          >
                            <FontAwesomeIcon icon={regular('file-alt')} /> Move
                            &amp; Assign
                          </div>
                          <div
                            className='docMenu-menuItem'
                            onClick={this.toggleChangeBusinessProcess}
                          >
                            <FontAwesomeIcon icon={solid('shield-alt')} />{' '}
                            Change Business Process
                          </div>
                          <div
                            className='docMenu-menuItem'
                            onClick={this.toggleTemplateChange}
                          >
                            <FontAwesomeIcon icon={regular('file-alt')} />{' '}
                            Change Template
                          </div>
                          {this.state.currentDoc !== undefined &&
                          this.state.currentDoc.templateName !== 'None' &&
                          <div
                            className='docMenu-menuItem'
                            onClick={(e) => this.analyzeTemplate(e)}
                          >
                            <FontAwesomeIcon icon={solid('puzzle-piece')} />{' '}
                            Analyze Template
                          </div>
                          }
                          {/*{this.state.currentDoc.templateName !== 'None' && (
                            <div
                              className='docMenu-menuItem'
                              onClick={this.inpsectTemplate}
                            >
                              <FontAwesomeIcon icon={regular('file-alt')} />{' '}
                              Inspect Template
                            </div>
                          )}
                          <div
                            className='docMenu-menuItem'
                            onClick={this.createTempFromDoc}
                          >
                            <FontAwesomeIcon icon={regular('file-alt')} />{' '}
                            Create Template from Document
                          </div>*/}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <button
              className='btn float-end '
              style={{
                margin: '7px 0px 7px 7px',
                position: 'relative',
                marginLeft: '6px',
                backgroundColor: '#4C969B',
                color: '#FFF',
              }}
              onClick={this.toggleUserNotes}
              title='User Notes'
            >
              <FontAwesomeIcon icon={regular('file-alt')} />{' '}
              {this.state.currentDoc !== undefined &&
                this.state.currentDoc.userNotes.length > 0 && (
                  <span
                    className='badge rounded-pill'
                    style={{ color: '#000', backgroundColor: '#81CBD0' }}
                  >
                    {this.state.currentDoc.userNotes.length}
                  </span>
                )}
            </button>
            <div className='dropdown float-end'>
              {this.state.currentDoc !== undefined &&
                this.state.currentDoc.checkedOutStatus === 'Integration-Ready' &&
                   (
                  <div
                    className='float-end mt-2'
                    style={{
                      padding: '7px 12px',
                      margin: '7px 0px 7px 7px',
                      fontSize: '14px',
                      backgroundColor: '#909090',
                      color: '#E3E3E3',
                      borderRadius: '12px',
                    }}
                    title='Automated step'
                  >
                    <FontAwesomeIcon icon={solid('gear')} />
                  </div>
                )}
              {this.state.lockedByMe && (
                <div
                  className='float-end mt-2'
                  style={{
                    padding: '7px 12px',
                    margin: '7px 0px 7px 7px',
                    fontSize: '14px',
                    backgroundColor: '#27AE60',
                    color: '#FFF',
                    borderRadius: '12px',
                  }}
                  title='Locked by me'
                >
                  <FontAwesomeIcon icon={solid('lock')} />
                </div>
              )}
              {this.state.currentDoc !== undefined &&
                this.state.currentDoc.checkedOutStatus === 'Deleted' && (
                  <div
                    className='float-end mt-2'
                    style={{
                      padding: '7px 12px',
                      margin: '7px 0px 7px 7px',
                      fontSize: '13px',
                      backgroundColor: 'rgb(236, 75, 75)',
                      color: '#fff',
                      borderRadius: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={this.toggleSnagMenu}
                  >
                    <FontAwesomeIcon icon={solid('trash')} /> Deleted{' '}
                  </div>
                )}
              {this.state.lockedByOther && (
                <div
                  className='float-end mt-2'
                  style={{
                    padding: '7px 12px',
                    margin: '7px 0px 7px 7px',
                    fontSize: '13px',
                    backgroundColor: '#F1C40F',
                    color: '#212F3D',
                    borderRadius: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={this.toggleSnagMenu}
                >
                  <FontAwesomeIcon icon={solid('user-lock')} />{' '}
                  {this.state.checkedOutBy}{' '}
                </div>
              )}
              {this.state.currentDoc !== undefined &&
                this.state.currentDoc !== null &&
                this.state.currentDoc.checkedOutStatus === 'ImageReview' && (
                  <div
                    className='float-end'
                    style={{
                      padding: '7px 12px',
                      margin: '7px 0px 7px 7px',
                      fontSize: '12px',
                      backgroundColor: '#AE4BCF',
                      color: '#FFF',
                      borderRadius: '12px',
                      cursor: 'pointer',
                    }}
                  >
                    <FontAwesomeIcon icon={solid('lock')} /> Image Review{' '}
                  </div>
                )}
              {this.state.currentDoc !== undefined &&
                this.state.currentDoc !== null &&
                this.state.currentDoc.checkedOutStatus === 'Queue' && (
                  <div
                    className='float-end'
                    style={{
                      padding: '7px 12px',
                      margin: '9px 2px 7px 7px',
                      fontSize: '13px',
                      backgroundColor: '#42B3C1',
                      color: '#FFF',
                      borderRadius: '12px',
                      cursor: 'pointer',
                    }}
                  >
                    <FontAwesomeIcon icon={solid('list-check')} />{' '}
                  </div>
                )}
              {this.state.showSnagMenu && (
                <div className='dropdown-content'>
                  {this.state.lockedByOther && <div
                    className='dropdown-menuitem'
                    onClick={this.pullDocFromUser}
                  >
                    <FontAwesomeIcon
                      style={{
                        color: '#196F3D',
                        marginRight: '8px',
                      }}
                      icon={solid('unlock-alt')}
                    />
                    Pull from {this.state.checkedOutBy}
                  </div>}
                  { this.state.currentDoc.checkedOutStatus === 'Deleted' &&
                  <div
                    className='dropdown-menuitem'
                    onClick={this.undeleteDocument}
                  >
                    <FontAwesomeIcon
                      style={{
                        color: '#196F3D',
                        marginRight: '8px',
                      }}
                      icon={solid('trash-can-arrow-up')}
                    />
                    Undelete
                  </div>}
                </div>
              )}

            </div>
            {!this.state.automatedTask && (
              <div className='float-end'>
                <p className='h6' style={{ margin: '15px 10px 7px 7px' }}>
                  <strong>Step:</strong> {this.state.stepName}
                </p>
              </div>
            )}
            {this.state.automatedTask && (
              <div
                className='float-end'
                style={{
                  padding: '7px 12px',
                  margin: '7px 0px 7px 7px',
                  fontSize: '12px',
                  backgroundColor: '#CCD1D1',
                  color: '#000',
                  borderRadius: '12px',
                }}
              >
                <FontAwesomeIcon icon={solid('cog')} /> <strong>Step:</strong>{' '}
                {this.state.stepName}{' '}
              </div>
            )}
            <div className='float-end'>
              <p className='h6' style={{ margin: '15px 10px 7px 7px' }}>
                <strong>Workflow:</strong> {this.state.workflowName}
              </p>
            </div>
          </div>
        </div>
        <div
          className='d-flex flex-row'
          style={{
            height: `${this.state.topHeight}px`,
            backgroundColor: '#8EEDD0',
          }}
        >
          <div
            className='coolScroll'
            id='headerFieldContainer'
            style={{
              backgroundColor: '#F1EEE4',
              width: '280px',
              borderRight: 'solid 1px #B2A796',
              overflow: 'auto',
            }}
          >
            {this.state.currentDoc &&
              this.state.currentDoc.headerFields.map((field) => (
                <div key={field.fieldId}>
                  <FieldEditor
                    fieldId={field.fieldId}
                    systemName={field.systemName}
                    fieldType={field.fieldType}
                    dataType={field.dataType}
                    decimalPlaces={field.decimalPlaces}
                    currencyCode={field.currencyCode}
                    format={field.format}
                    isRequired={field.isRequired}
                    error={field.error}
                    hasExtender={field.hasExtender}
                    extender={field.extender}
                    expandView={this.expandView}
                    setError={this.setFieldError}
                    displayName={field.displayName}
                    extenderKey={field.extenderKey}
                    extenderId={
                      field.hasExtender ? field.extender.extenderId : -1
                    }
                    textValue={field.fieldValue}
                    value={
                      field.hasExtender && field.extender.type === 4
                        ? field.fieldValue
                        : field.fieldValue
                    }
                    updateTextValue={this.handleHeaderFieldTextEdit}
                    updateValue={this.handleHeaderFieldEdit}
                    readOnly={!(this.state.userCanEdit && field.canEdit)}
                    closeAllList={this.closeAllList}
                    fontSize={0}
                    runExtender={this.runExtender}
                  />
                </div>
              ))}
          </div>
          <div
            className='flex-fill coolScroll'
            style={{
              backgroundColor: '#DADADA',
              position: 'relative',
              overflow: 'auto',
            }}
          >
            {this.state.currentImage === undefined &&
              !this.state.loadingImage && (
                <div
                  className='h-100 d-flex align-items-center justify-content-center'
                  style={{
                    background: '#4E4E4E',
                    textAlign: 'center',
                    color: '#C1C1C1',
                  }}
                >
                  <div>No Image</div>
                </div>
              )}
            {(this.state.currentImage === undefined ||
              (this.state.currentImage !== undefined &&
                this.state.currentImage.imageBytes === null)) &&
              this.state.loadingImage && (
                <div
                  className='h-100 d-flex align-items-center justify-content-center'
                  style={{
                    background: '#4E4E4E',
                    textAlign: 'center',
                    color: '#C1C1C1',
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={solid('spinner')} size='4x' spin />
                    <h4>Loading Image</h4>
                  </div>
                </div>
              )}
            {this.state.currentImage !== undefined &&
              this.state.currentImage.imageBytes !== null && (
                <DocImageViewer
                  viewHeight={this.state.imageviewerHeight}
                  base64={this.state.currentImage.imageBytes}
                  viewWidth={this.state.imageviewerWidth}
                  imageHeight={this.state.currentImage.imageHeight}
                  imageWidth={this.state.currentImage.imageWidth}
                  imageCount={this.state.images.length}
                  currentPage={this.state.currentImage.pageId}
                  onPageNext={this.handleImageNav}
                  rotateLeft={this.rotateDocImageLeft}
                  rotateRight={this.rotateDocImageRight}
                  isRotatingLeft={this.state.isRotatingLeft}
                  isRotatingRight={this.state.isRotatingRight}
                  zoom={this.state.documentZoomSetting}
                />
              )}

          </div>
          {this.state.showUserNotes && (
            <div
              className='coolScroll p-2'
              id='userNotesContainer'
              style={{
                backgroundColor: '#FFF',
                width: '280px',
                borderRight: 'solid 1px #B2A796',
                overflow: 'auto',
                borderLeft: 'solid 1px #777',
              }}
            >
              <strong>
                <FontAwesomeIcon icon={regular('comment-alt')} /> Notes
              </strong>
              <hr />
              {this.state.userCanAddNotes && <div>
                <label
                  className='form-Label'
                  onClick={this.toggleAddNote}
                  style={{ cursor: 'pointer' }}
                >
                  {this.state.showAddNote && (
                    <FontAwesomeIcon icon={solid('chevron-down')} />
                  )}
                  {!this.state.showAddNote && (
                    <FontAwesomeIcon icon={solid('chevron-right')} />
                  )}{' '}
                  Add Note
                </label>
                {this.state.showAddNote && (
                  <>
                    <textarea
                      className='form-control'
                      value={this.state.newNote}
                      onChange={this.editNewNote}
                      //maxLength={512}
                      rows='2'
                    />
                    <button
                      className='btn btn-success btn-sm mt-1'
                      onClick={this.addNote}
                    >
                      Add Note
                    </button>
                  </>
                )}
                <hr />
              </div>


              }

              <div>
                {this.state.currentDoc.userNotes.map((note) => (
                  <div className='userNote-note' key={note.noteId}>
                     <div className='userNote-noteBody' style={{whiteSpace: 'pre-line'}}>
                        {note.noteText.split('\n').map((i,key) => {
                          return <div style={{minHeight: '9px'}} key={key}>{i}</div>
                        })}
                      </div>
                    <div className='userNote-noteFooter'>
                      <span>
                        - {note.userName}: {this.convertDate2(note.noteDate)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            height: '8px',
            backgroundColor: '#AEAEAE',
            cursor: 'row-resize',
            border: 'solid 1px #8E8E8E',
          }}
          onMouseDown={this.onSplitBarMouseDown}
        ></div>
        {this.state.currentDoc !== undefined && (
          <div
            style={{
              paddingLeft: '8px',
              paddingTop: '4px',
              backgroundColor: '#d3e1e0',
              height: `${this.state.bottomHeight}px`,
              maxHeight: `${this.state.bottomHeight}px`,
              minHeight: '30px',
              overflow: 'hidden',
            }}
          >
            {this.state.currentDoc.fieldSets.length > 0 && (
              <div className='row' >
                <div className='col-3'>
                  <ul className='nav nav-pills'>
                    {this.state.currentDoc.fieldSets.map((fs) => (
                      <li
                        key={fs.fieldSetId}
                        onClick={() => this.toggleFieldSet(fs)}
                        className={`nav-link nav-sm ${
                          fs.fieldSetId ===
                          this.state.currentFieldSet.fieldSetId
                            ? 'active'
                            : ''
                        }`}
                        style={{ cursor: 'pointer' }}
                      >
                        {fs.fieldSetName} {'('}
                        {fs.rows.filter((r) => r.isNew !== true).length}
                        {')'}
                      </li>
                    ))}
                  </ul>
                </div>
                {this.state.showTools && (
                  <div className='col-6'>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={this.state.showChecks}
                        onChange={this.toggleChecks}
                      />
                      <FontAwesomeIcon
                        style={{ color: '#247E15' }}
                        icon={solid('square-check')}
                      />
                    </div>

                    <button
                      className='btn btn-sm btn-success'
                      onClick={this.writeGridToClipboard}
                    >
                      <span>
                        Copy{' '}
                        <FontAwesomeIcon
                          style={{ color: '#fff' }}
                          icon={solid('table')}
                        />
                      </span>{' '}
                    </button>

                    {this.state.userCanEdit && (
                      <button
                        className='btn btn-sm btn-success ms-2'
                        onClick={this.toggleExcelPaste}
                      >
                        <span>
                          Paste{' '}
                          <FontAwesomeIcon
                            style={{ color: '#fff' }}
                            icon={solid('table')}
                          />
                        </span>{' '}
                      </button>
                    )}
                    <button
                      className='btn btn-sm btn-primary ms-2'
                      onClick={this.toggleColumnEdit}
                    >
                      <span>
                        Columns{' '}
                        <FontAwesomeIcon
                          style={{ color: '#fff' }}
                          icon={solid('table-columns')}
                        />
                      </span>{' '}
                    </button>
                  </div>
                )}
              </div>
            )}
            {this.state.currentDoc.fieldSets.length > 0 && (
              <div
                className='document-grid-container coolScroll2'
                style={{
                  maxWidth: `${this.state.tableWidth}px`,
                  minHeight: `${this.state.bottomHeight - 48}px`,
                  maxHeight: `${this.state.bottomHeight - 48}px`,

                }}
              >
                <div>
                  <table style={{ borderLeft: 'solid 1px #5d6d7e' }}>
                    <tbody>
                      <tr
                        onMouseMove={this.DragColumnSize}
                        onMouseUp={this.stopDraggingColSize}
                        onMouseLeave={this.stopDraggingColSize}
                      >
                        <td
                          className='document-grider-header-cell1'
                          width='40px'
                          style={{
                            minWidth: '40px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={this.state.allRowCheck}
                            onChange={this.setAllRowCheck}
                          />
                        </td>

                        <td
                          className='document-grider-header-cell1'
                          width='40px'
                          style={{ minWidth: '40px' }}
                        >
                          {this.state.userCanEdit && (
                            <button
                              className='btn btn-sm btn-danger'
                              onClick={this.deleteRows}
                            >
                              <FontAwesomeIcon icon={solid('trash-can')} />
                            </button>
                          )}
                        </td>

                        {this.state.showChecks && (
                          <td
                            className='document-grider-header-cell1'
                            width='60px'
                            style={{ minWidth: '60px', textAlign: 'center' }}
                          >
                            <FontAwesomeIcon
                              className='mt-1'
                              style={{ color: '#247E15' }}
                              icon={solid('circle-check')}
                              size='2x'
                            />
                          </td>
                        )}
                        <td
                          className='document-grider-header-cell1'
                          width='40px'
                          style={{ minWidth: '80px', textAlign: 'center' }}
                        >
                          <button
                            className='btn btn-sm btn-secondary'
                            onClick={this.toggleShowTools}
                          >
                            <FontAwesomeIcon icon={solid('tools')} />
                          </button>
                        </td>

                        {this.state.currentFieldSet.fieldDefs.map(
                          (col) =>
                            col.display && (
                              <React.Fragment key={col.fieldId}>
                                {/*<td className='document-grider-header-left' onMouseDown={(e) => this.startDraggingColumn(e, col.fieldId)} width='8px'>{' '}</td>*/}
                                <td
                                  className='document-grider-header-cell2'
                                  width={`${col.colWidth - 8}px`}
                                  style={{
                                    maxWidth: `${col.colWidth - 8}px`,
                                    minWidth: `${col.colWidth - 8}px`,
                                  }}
                                >
                                  {col.colName}
                                  {col.showTotal && (
                                    <span style={{ color: '#3F98A4' }}>
                                      {' '}
                                      <span
                                        id={`${col.fieldId}_runningTotal`}
                                        key={`${col.fieldId}_runningTotal`}
                                      ></span>
                                    </span>
                                  )}
                                </td>
                                <td
                                  className='document-grider-header-right'
                                  onMouseDown={(e) =>
                                    this.startDraggingColumn(e, col.fieldId)
                                  }
                                  width='8px'
                                >
                                  {' '}
                                </td>
                              </React.Fragment>
                            )
                        )}
                        {/*}
                      <td
                        className='document-grider-header-cell2'
                        width='20px'
                        style={{
                          minWidth: '20px',
                          borderRight: 'solid 1px #5D6D7E',
                        }}
                      ></td>*/}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  id='mainRowGrid'
                  className='document-grid-rows'
                  style={{
                    maxHeight: `${this.state.tableHeight}px`,
                    width: this.getTableWidth() + 18,
                  }}
                  onWheel={this.onMainScroll}
                >
                  <div
                      id='magicScroll'
                      className='coolScroll3'
                      style={{
                        backgroundColor: 'rgba(255,0,255, 0.0)',
                        //backgroundColor: 'red',
                        color: '#FFF',

                        position: 'absolute',
                        right: '24px',
                        top: `${this.state.topHeight + 210}px`,
                        height: `${this.state.bottomHeight - 100}px`,
                        width: '20px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        zIndex: 100,
                      }}
                      onScroll={this.onMagicScroll}
                    >
                      <div style={{ height: `${this.state.rowData.length * 34}px` }}>
                        <span style={{paddingLeft: '50px'}}>.</span>
                        {' '}
                      </div>
                    </div>
                  <table>
                    <tbody>
                      {this.state.rowData.map((row) => (
                        <React.Fragment key={row.rowId}>
                          <tr>
                            <td
                              className={`document-grider-data-cell1 ${
                                row.isNew ? 'new-rec' : ''
                              }`}
                              width='40px'
                              style={{
                                minWidth: '40px',
                                textAlign: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              {!row.isNew && (
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  tabIndex='-1'
                                  checked={row.isChecked}
                                  onChange={(e) =>
                                    this.setRowCheck(e, row.rowId)
                                  }
                                />
                              )}
                            </td>

                            <td
                              className={`document-grider-data-cell1 ${
                                row.isNew ? 'new-rec' : ''
                              }`}
                              width='40px'
                              style={{
                                minWidth: '40px',
                                textAlign: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              {!row.isNew && this.state.userCanEdit && (
                                <FontAwesomeIcon
                                  onClick={() => this.deleteRow(row.rowId)}
                                  style={{ color: '#CD3C3C' }}
                                  icon={solid('trash-can')}
                                />
                              )}
                            </td>

                            {this.state.showChecks && (
                              <td
                                className={`document-grider-data-cell1 ${
                                  row.isNew ? 'new-rec' : ''
                                }`}
                                width='60px'
                                style={{
                                  minWidth: '60px',
                                  textAlign: 'center',
                                }}
                              >
                                <FontAwesomeIcon
                                  className='mt-1'
                                  onClick={() => this.toggleCheck(row.rowId)}
                                  style={{
                                    cursor: 'pointer',
                                    color: row.auditCheck
                                      ? '#247E15'
                                      : '#D7D7D7',
                                  }}
                                  icon={solid('check')}
                                />
                              </td>
                            )}
                            <td
                              className={`document-grider-data-cell1 ${
                                row.isNew ? 'new-rec' : ''
                              }`}
                              width='40px'
                              style={{
                                minWidth: '80px',
                                textAlign: row.isNew ? 'right' : 'center',
                              }}
                            >
                              {!row.isNew && row.rowId}
                              {row.isNew && (
                                <FontAwesomeIcon
                                  style={{
                                    color: '#8C8C8C',
                                    marginRight: '6px',
                                  }}
                                  icon={solid('asterisk')}
                                />
                              )}
                            </td>

                            {row.fieldData.map(
                              (col) =>
                                col.fieldDef !== undefined &&
                                col.fieldDef.display && (
                                  <td
                                    key={col.fieldId}
                                    className='document-grider-data-cell2'
                                    width={`${col.fieldDef.colWidth}px`}
                                    style={{
                                      minWidth: `${col.fieldDef.colWidth}px`,
                                    }}
                                  >
                                    <TableCellEditor
                                      readOnly={!this.state.userCanEdit}
                                      onFocus={this.onCellClick}
                                      value={col.fieldValue}
                                      textValue={col.fieldValue}
                                      extenderKey={col.extenderKey}
                                      updateField={this.handleTableFieldEdit}
                                      fieldDef={col.fieldDef}
                                      orderId={col.displayOrder}
                                      fieldSetId={this.state.currentFieldSet}
                                      onMoveCell={this.onMoveCell}
                                      rowId={row.rowId}
                                      colId={col.fieldId}
                                      hasExtender={col.fieldDef.hasExtender}
                                      extender={col.fieldDef.extender}
                                      extenderId={
                                        col.fieldDef.hasExtender
                                          ? col.fieldDef.extender.extenderId
                                          : -1
                                      }
                                      allowedValues={col.allowedValues}
                                      updateValue={this.handleTableFieldEdit}
                                      setLineFieldError={this.setLineFieldError}
                                      error={col.error}
                                      setError={this.setCellError}
                                      clearError={this.clearCellError}
                                      calculateRunningTotal={
                                        this.calculateRunningTotal
                                      }
                                    />
                                  </td>
                                )
                            )}
                            {/*<td
                            className='document-grider-data-cell2'
                            width='20px'
                            style={{
                              minWidth: '20px',
                              borderRight: 'solid 1px #85929E',
                            }}
                          ></td>*/}
                          </tr>
                        </React.Fragment>
                      ))}
                      {/*<tr>
                        <td className='document-grider-data-cell1 new-rec' width='40px' style={{minWidth: '40px', textAlign: 'center', cursor: 'pointer'}}></td>
                        {this.state.showTrash && <td className='document-grider-data-cell1 new-rec' width='40px' style={{minWidth: '40px'}}></td>}
                        {this.state.showChecks && <td className='document-grider-data-cell1 new-rec' width='60px' style={{minWidth: '60px', textAlign: 'center'}}></td> }
                        <td className='document-grider-data-cell1 new-rec' width='40px' style={{minWidth: '80px', textAlign: 'right'}}><FontAwesomeIcon style={{color: '#8C8C8C', marginRight: '6px' }} icon={solid('asterisk')} /></td>

                        {this.state.currentFieldSet.fieldDefs.map((col) => (
                           col.display &&
                           <td key={col.fieldId} className='document-grider-data-cell2 new-rec`' width={`${col.colWidth}px`} style={{minWidth: `${col.colWidth}px`}}><TableCellEditor readOnly={false} onFocus={this.onCellClick} onLoseFocus={this.createNewRow} value='' onMoveCell={this.onMoveCell} rowId={-1} colId={col.fieldId} /></td>

                        ))}
                        <td className='document-grider-data-cell2' width='20px' style={{minWidth: '20px', borderRight: 'solid 1px #85929E'}}></td>
                        </tr> */}
                      <tr>
                        <td>
                          <br></br>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.showColumnEdit && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '300px' }}>
              <div className='card-header'>
                Hide/Show columns{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleColumnEdit}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '300px', overflow: 'auto' }}
              >
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={this.state.showAllColumns}
                    onChange={(e) => this.toggleShowAllColumns(e)}
                  />
                  <label className='form-check-label'> All Columns</label>
                </div>
                <hr />
                {this.state.currentFieldSet.fieldDefs.map((fs) => (
                  <div key={fs.fieldId} className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={fs.display}
                      onChange={(e) => this.toggleColumnDisplay(e, fs.fieldId)}
                    />
                    <label className='form-check-label'>{fs.colName}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {this.state.showAudit2  && (
           <div className='td-dialogue-container'>tes
           <div
             className='td-dialogue card'
             style={{ width: '85%', height: '85%' }}
           >
           <div className='card-header'>
                Document Audit History
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleShowAudit}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '600px', overflow: 'auto' }}
              >
                <nav className='nav'>
                  <span onClick={() => this.setAuditTab('user')} className={`nav-link nav-button ${this.state.currentAuditView === 'user' ? 'active' : ''}`}>User Actions</span>
                  <span onClick={() => this.setAuditTab('auto')} className={`nav-link nav-button ${this.state.currentAuditView === 'auto' ? 'active' : ''}`}>Automated Actions</span>
                  <span onClick={() => this.setAuditTab('full')} className={`nav-link nav-button ${this.state.currentAuditView === 'full' ? 'active' : ''}`}>Full Audit</span>
                </nav>
                {this.state.currentAuditView==='user' && (
                  <div>User Activity</div>
                )}
                {this.state.currentAuditView==='auto' && (
                  <div>Plugin Activity</div>
                )}
                {this.state.currentAuditView==='user' && (
                  <div>All Activity</div>
                )}

              </div>
           </div>
           </div>
        )}

        {this.state.showAudit && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ width: '85%', height: '85%' }}
            >
              <div className='card-header'>
                Document Audit History
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleShowAudit}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '600px', overflow: 'auto' }}
              >
                <ul className='nav nav-tabs'>
                  <li className='nav-item'>
                    <span
                      className={`nav-link ${
                        this.state.currentAuditView === 'user' ? 'active' : ''
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.switchAuditView('user')}
                    >
                      Document Actions
                    </span>
                  </li>
                  <li className='nav-item'>
                    <span
                      className={`nav-link ${
                        this.state.currentAuditView === 'system' ? 'active' : ''
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.switchAuditView('system')}
                    >
                      Automation Actions
                    </span>
                  </li>
                  <li className='nav-item'>
                    <span
                      className={`nav-link ${
                        this.state.currentAuditView === 'full' ? 'active' : ''
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.switchAuditView('full')}
                    >
                      Combined Actions
                    </span>
                  </li>
                </ul>

                {this.state.isAuditLoading && (
                  <div
                    style={{
                      height: '400px',
                      width: '750px',
                      paddingTop: '100px',
                      textAlign: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: '#777' }}
                      size='3x'
                      icon={solid('spinner')}
                      spin
                    />
                    <div className='mt-2 mb-2'>
                      Loading audit information...
                    </div>
                  </div>
                )}
                {!this.state.isAuditLoading &&
                  this.state.auditInfo !== undefined &&
                  this.state.currentAuditView === 'user' && (
                    <div>
                      <table className='audit-table' style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>Step</th>
                            <th>User</th>
                            <th>Action</th>
                            <th>Date/Time</th>
                            <th>Event detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.auditInfo.userAudit.map((uaudit) => (
                            <tr
                              style={{
                                backgroundColor:
                                  uaudit.performerType === 'System'
                                    ? '#DFDFDF'
                                    : '#FFFCD6',
                              }}
                            >
                              <td>{uaudit.stepName}</td>
                              <td>
                                <span style={{ color: '#676767' }}>
                                  {uaudit.performerType.toUpperCase() ===
                                    'System'.toUpperCase() && (
                                    <FontAwesomeIcon icon={solid('gear')} />
                                  )}
                                  {uaudit.performerType.toUpperCase() ===
                                    'Integration'.toUpperCase() && (
                                    <FontAwesomeIcon icon={solid('gear')} />
                                  )}
                                  {uaudit.performerType.toUpperCase() ===
                                    'User'.toUpperCase() && (
                                    <FontAwesomeIcon icon={solid('user')} />
                                  )}
                                </span>{' '}
                                {uaudit.userName}
                              </td>
                              <td>
                                <span style={{ color: '#676767' }}>
                                  {uaudit.action === 'SAVE' && (
                                    <FontAwesomeIcon icon={solid('save')} />
                                  )}
                                  {(uaudit.action === 'REGISTER' ||
                                    uaudit.action === 'Register') && (
                                    <FontAwesomeIcon
                                      icon={solid('registered')}
                                    />
                                  )}
                                  {(uaudit.action === 'APPROVE' ||
                                    uaudit.action === 'Approved' ||
                                    uaudit.action === 'Bulk Approved') && (
                                    <FontAwesomeIcon
                                      icon={solid('thumbs-up')}
                                    />
                                  )}
                                  {(uaudit.action === 'Moved' ||
                                    uaudit.action ===
                                      'Bulk Move and Assigned') && (
                                    <FontAwesomeIcon
                                      icon={solid('truck-moving')}
                                    />
                                  )}
                                  {(uaudit.action === 'REJECT' ||
                                    uaudit.action === 'Rejected' ||
                                    uaudit.action === 'Bulk Rejected') && (
                                    <FontAwesomeIcon
                                      icon={solid('thumbs-down')}
                                    />
                                  )}
                                  {uaudit.action ===
                                    'Bulk Changed to Ready' && (
                                    <FontAwesomeIcon
                                      icon={solid('check-double')}
                                    />
                                  )}
                                  {uaudit.action === 'Saved' && (
                                    <FontAwesomeIcon icon={solid('save')} />
                                  )}
                                  {uaudit.action === 'Reset' && (
                                    <FontAwesomeIcon
                                      icon={solid('check-double')}
                                    />
                                  )}
                                  {(uaudit.action === 'ASSIGNED' ||
                                    uaudit.action === 'Assigned') && (
                                    <FontAwesomeIcon icon={solid('user-tag')} />
                                  )}
                                  {(uaudit.action === 'DELETED' ||
                                    uaudit.action === 'Bulk Deleted' ||
                                    uaudit.action === 'Deleted') && (
                                    <FontAwesomeIcon
                                      icon={solid('trash-alt')}
                                    />
                                  )}
                                  {(uaudit.action === 'IMAGEREVIEW' ||
                                    uaudit.action === 'Image Review' ||
                                    uaudit.action ===
                                      'Bulk Sent to Image Review') && (
                                    <FontAwesomeIcon
                                      icon={solid('file-image')}
                                    />
                                  )}
                                </span>{' '}
                                {uaudit.action}
                              </td>
                              <td>{this.convertDate(uaudit.eventTime)}</td>
                              <td>{uaudit.note} </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                {!this.state.isAuditLoading &&
                  this.state.auditInfo !== undefined &&
                  this.state.currentAuditView === 'system' && (
                    <div>
                      <table className='audit-table'>
                        <thead>
                          <tr>
                            <th>Step</th>
                            <th>User</th>
                            <th>Action</th>
                            <th>Date/Time</th>
                            <th>Event detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.auditInfo.systemAudit.map((uaudit) => (
                            <tr
                              style={{
                                backgroundColor:
                                  uaudit.performerType === 'System'
                                    ? '#DFDFDF'
                                    : '#FFFCD6',
                              }}
                            >
                              <td>{uaudit.stepName}</td>
                              <td>
                                <span style={{ color: '#676767' }}>
                                  {uaudit.performerType === 'System' && (
                                    <FontAwesomeIcon icon={solid('gear')} />
                                  )}
                                  {uaudit.performerType === 'Integration' && (
                                    <FontAwesomeIcon icon={solid('gear')} />
                                  )}
                                  {uaudit.performerType === 'User' && (
                                    <FontAwesomeIcon icon={solid('user')} />
                                  )}
                                </span>{' '}
                                {uaudit.userName}
                              </td>
                              <td>
                                <span style={{ color: '#676767' }}>
                                  {uaudit.action === 'SAVE' && (
                                    <FontAwesomeIcon icon={solid('save')} />
                                  )}
                                  {(uaudit.action === 'REGISTER' ||
                                    uaudit.action === 'Register') && (
                                    <FontAwesomeIcon
                                      icon={solid('registered')}
                                    />
                                  )}
                                  {(uaudit.action === 'APPROVE' ||
                                    uaudit.action === 'Approved' ||
                                    uaudit.action === 'Bulk Approved') && (
                                    <FontAwesomeIcon
                                      icon={solid('thumbs-up')}
                                    />
                                  )}
                                  {(uaudit.action === 'Moved' ||
                                    uaudit.action ===
                                      'Bulk Move and Assigned') && (
                                    <FontAwesomeIcon
                                      icon={solid('truck-moving')}
                                    />
                                  )}
                                  {(uaudit.action === 'REJECT' ||
                                    uaudit.action === 'Rejected' ||
                                    uaudit.action === 'Bulk Rejected') && (
                                    <FontAwesomeIcon
                                      icon={solid('thumbs-down')}
                                    />
                                  )}
                                  {uaudit.action ===
                                    'Bulk Changed to Ready' && (
                                    <FontAwesomeIcon
                                      icon={solid('check-double')}
                                    />
                                  )}
                                  {uaudit.action === 'Saved' && (
                                    <FontAwesomeIcon icon={solid('save')} />
                                  )}
                                  {uaudit.action === 'Reset' && (
                                    <FontAwesomeIcon
                                      icon={solid('check-double')}
                                    />
                                  )}
                                  {(uaudit.action === 'ASSIGNED' ||
                                    uaudit.action === 'Assigned') && (
                                    <FontAwesomeIcon icon={solid('user-tag')} />
                                  )}
                                  {(uaudit.action === 'DELETED' ||
                                    uaudit.action === 'Bulk Deleted' ||
                                    uaudit.action === 'Deleted') && (
                                    <FontAwesomeIcon
                                      icon={solid('trash-alt')}
                                    />
                                  )}
                                  {(uaudit.action === 'IMAGEREVIEW' ||
                                    uaudit.action === 'Image Review' ||
                                    uaudit.action ===
                                      'Bulk Sent to Image Review') && (
                                    <FontAwesomeIcon
                                      icon={solid('file-image')}
                                    />
                                  )}
                                </span>{' '}
                                {uaudit.action}
                              </td>
                              <td>{this.convertDate(uaudit.eventTime)}</td>
                              <td>{uaudit.note} </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                {!this.state.isAuditLoading &&
                  this.state.auditInfo !== undefined &&
                  this.state.currentAuditView === 'full' && (
                    <div>
                      <table className='audit-table'>
                        <thead>
                          <tr>
                            <th>Step</th>
                            <th>User</th>
                            <th>Action</th>
                            <th>Date/Time</th>
                            <th>Event detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.auditInfo.fullAudit.map((uaudit) => (
                            <tr
                              style={{
                                backgroundColor:
                                  uaudit.performerType === 'System'
                                    ? '#DFDFDF'
                                    : '#FFFCD6',
                              }}
                            >
                              <td>{uaudit.stepName}</td>
                              <td>
                                <span style={{ color: '#676767' }}>
                                  {uaudit.performerType === 'System' && (
                                    <FontAwesomeIcon icon={solid('gear')} />
                                  )}
                                  {uaudit.performerType === 'Integration' && (
                                    <FontAwesomeIcon icon={solid('gear')} />
                                  )}
                                  {uaudit.performerType === 'User' && (
                                    <FontAwesomeIcon icon={solid('user')} />
                                  )}
                                </span>{' '}
                                {uaudit.userName}
                              </td>
                              <td>
                                <span style={{ color: '#676767' }}>
                                  {uaudit.action === 'SAVE' && (
                                    <FontAwesomeIcon icon={solid('save')} />
                                  )}
                                  {(uaudit.action === 'REGISTER' ||
                                    uaudit.action === 'Register') && (
                                    <FontAwesomeIcon
                                      icon={solid('registered')}
                                    />
                                  )}
                                  {(uaudit.action === 'APPROVE' ||
                                    uaudit.action === 'Approved' ||
                                    uaudit.action === 'Bulk Approved') && (
                                    <FontAwesomeIcon
                                      icon={solid('thumbs-up')}
                                    />
                                  )}
                                  {(uaudit.action === 'Moved' ||
                                    uaudit.action ===
                                      'Bulk Move and Assigned') && (
                                    <FontAwesomeIcon
                                      icon={solid('truck-moving')}
                                    />
                                  )}
                                  {(uaudit.action === 'REJECT' ||
                                    uaudit.action === 'Rejected' ||
                                    uaudit.action === 'Bulk Rejected') && (
                                    <FontAwesomeIcon
                                      icon={solid('thumbs-down')}
                                    />
                                  )}
                                  {uaudit.action ===
                                    'Bulk Changed to Ready' && (
                                    <FontAwesomeIcon
                                      icon={solid('check-double')}
                                    />
                                  )}
                                  {uaudit.action === 'Saved' && (
                                    <FontAwesomeIcon icon={solid('save')} />
                                  )}
                                  {uaudit.action === 'Reset' && (
                                    <FontAwesomeIcon
                                      icon={solid('check-double')}
                                    />
                                  )}
                                  {(uaudit.action === 'ASSIGNED' ||
                                    uaudit.action === 'Assigned') && (
                                    <FontAwesomeIcon icon={solid('user-tag')} />
                                  )}
                                  {(uaudit.action === 'DELETED' ||
                                    uaudit.action === 'Bulk Deleted' ||
                                    uaudit.action === 'Deleted') && (
                                    <FontAwesomeIcon
                                      icon={solid('trash-alt')}
                                    />
                                  )}
                                  {(uaudit.action === 'IMAGEREVIEW' ||
                                    uaudit.action === 'Image Review' ||
                                    uaudit.action ===
                                      'Bulk Sent to Image Review') && (
                                    <FontAwesomeIcon
                                      icon={solid('file-image')}
                                    />
                                  )}
                                </span>{' '}
                                {uaudit.action}
                              </td>
                              <td>{this.convertDate(uaudit.eventTime)}</td>
                              <td>{uaudit.note} </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}

        {this.state.showAudit2 && (
          <div className='td-dialogue-container'>
             <div
              className='td-dialogue card'
              style={{ width: '85%', height: '85%' }}
            >
              <div className='card-header'>
                Document Audit History
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleShowAudit}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '600px', overflow: 'auto' }}
              >
              </div>
            </div>
          </div>
        )}

        {this.state.showTemplateChange && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '700px' }}>
              <div className='card-header'>
                Change Template:
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleTemplateChange}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '350px', overflow: 'auto' }}
              >
                <label className='form-label'>Choose Template</label>
                <AutoComplete
                  id={`ChTemplateList`}
                  allowedValues={this.state.filteredTemplates}
                  value={this.state.suggestedTemplateId}
                  valueField='templateId'
                  displayField='templateName'
                  onChange={this.templateSuggestionClick}
                  //setFocus={this.setCurrentRoute}
                />

            {/*
                <div className='form-check form-switch mt-3'>
                  <input
                    className='form-check-input'
                    onChange={this.toggleApplyTemplate}
                    checked={this.state.applyTemplate}
                    type='checkbox'
                    id='changeTemplate-applyNow'
                  />
                  <label className='form-check-label'>Apply Now?</label>
                </div>
                {this.state.selectedTemplate.templateId >= 0 && (
                  <div>
                    <button
                      className='btn btn-success mt-2'
                      onClick={this.onClickTemplateChange}
                    >
                      <FontAwesomeIcon icon={solid('check')} /> OK
                    </button>
                  </div>
                )} */}
                <div className='d-grid gap-2 mt-3'>
                <button onClick={() => this.onClickTemplateChange(1)} type='button' className='btn btn-secondary'>Change Template</button>
                <button onClick={() => this.onClickTemplateChange(2)} type='button' className='btn btn-success'>Change and Apply Template</button>
                <button onClick={() => this.onClickTemplateChange(3)} type='button' className='btn btn-primary'>Apply Template and send to start of workflow</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.showDeleteConfirm && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '700px' }}>
              <div className='card-header'>
                Confirm delete:
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.cancelTrashClick}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '180px', overflow: 'auto' }}
              >
                <div>
                  Are you sure you want to delete Document{' '}
                  {this.state.currentDoc.documentId}?
                </div>
                <div className='mt-3'>
                  <button className='btn btn-success' onClick={this.deleteDoc}>
                    <FontAwesomeIcon icon={solid('check')} /> Yes
                  </button>
                  <button
                    className='btn btn-secondary ms-2'
                    onClick={this.cancelTrashClick}
                  >
                    <FontAwesomeIcon icon={solid('times')} /> No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.showExcelPaste && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '700px' }}>
              <div className='card-header'>
                Paste from Excel:
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleExcelPaste}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '380px', overflow: 'auto' }}
              >
                <div>
                  <label className='form-label'>Paste here:</label>
                  <input
                    className='form-control'
                    value=''
                    onChange={this.pasteExcel}
                  ></input>
                </div>
                <div>
                  <label className='form-label'>Preview:</label>
                  <div
                    style={{
                      height: '200px',
                      width: '665px',
                      overflow: 'auto',
                      border: '1px solid #43683F',
                    }}
                  >
                    <table className='table table-success table-striped table-sm'>
                      <thead>
                        <tr>
                          {this.state.currentFieldSet.fieldDefs.map((field) => (
                            <th key={field.fieldId} scope='col'>
                              {field.colName}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.excelData.map((row) => (
                          <tr key={row.rowId}>
                            {row.rowData.cells.map((cell) => (
                              <td key={cell.cellId}>{cell.value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='mt-2'>
                    <button
                      className='btn btn-primary me-1'
                      onClick={this.replaceExcelData}
                    >
                      Replace
                    </button>
                    <button
                      className='btn btn-primary'
                      onClick={this.appendExcelData}
                    >
                      Append
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showProperties && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px', height: '500px' }}>
              <div className='card-header'>
                Document Properties:
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleProperties}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '400px' }}
              >
                <div className='fs-5'>Document Properties</div>
                <hr />
                <div className='row  justify-content-center gx-5'>
                  <div className='col-auto'>
                    <span className='propLabel'>
                      <FontAwesomeIcon
                        icon={solid('file-alt')}
                        style={{ color: '#2E439E' }}
                      />{' '}
                      FormType:
                    </span>
                    <span className='propValue'>
                      {this.state.currentDoc.formTypeName}
                    </span>
                  </div>
                  <div className='col-auto'>
                    <span className='propLabel'>
                      <FontAwesomeIcon
                        icon={solid('sitemap')}
                        style={{ color: '#F17F13' }}
                      />{' '}
                      Workflow:
                    </span>
                    <span className='propValue'>
                      {this.state.currentDoc.workflowName}
                    </span>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-auto'>
                    <span className='propLabel'>
                      <FontAwesomeIcon
                        icon={solid('puzzle-piece')}
                        style={{ color: '#30D93A' }}
                      />{' '}
                      Template:
                    </span>
                    <span className='propValue'>
                      {this.state.currentDoc.templateName}
                    </span>
                  </div>
                </div>

                <hr />
                <div className='row justify-content-center'>
                  <div className='col-auto'>
                    <span className='propLabel'>
                      <FontAwesomeIcon
                        icon={solid('upload')}
                        style={{ color: '#2E9E94' }}
                      />{' '}
                      Upload Information:
                    </span>
                  </div>
                </div>
                <div
                  className='row justify-content-center'
                  style={{ marginTop: '12px' }}
                >
                  <div className='col-auto'>
                    <div>
                      <span className='propLabel2'>Upload Method:</span>{' '}
                      <span propValue2>
                        {this.state.currentDoc.uploadInfo.uploadMethod}
                      </span>
                    </div>
                    <div>
                      <span className='propLabel2'>Upload Date:</span>{' '}
                      <span propValue2>
                        {this.convertDate2(
                          this.state.currentDoc.uploadInfo.uploadDate
                        )}
                      </span>
                    </div>

                    <div>
                      <span className='propLabel2'>Received From:</span>{' '}
                      <span propValue2>
                        {this.state.currentDoc.uploadInfo.receivedFrom}
                      </span>
                    </div>
                    <div>
                      <span className='propLabel2'>Document Source:</span>{' '}
                      <span propValue2>
                        {this.state.currentDoc.uploadInfo.docSource}
                      </span>
                    </div>
                    <div>
                      <span className='propLabel2'>File Name:</span>{' '}
                      <span propValue2>
                        {this.state.currentDoc.uploadInfo.fileName}
                      </span>
                    </div>
                    {this.state.currentDoc.uploadInfo.intakeStrategy !== undefined && this.state.currentDoc.uploadInfo.intakeStrategy && <div>
                      <span className='propLabel2'>Intake Strategy:</span>{' '}
                      <span propValue2>
                        {this.state.currentDoc.uploadInfo.intakeStrategy}
                      </span>
                    </div>}
                    {this.state.currentDoc.uploadInfo.ocrType !== undefined && this.state.currentDoc.uploadInfo.ocrType && <div>
                      <span className='propLabel2'>OCR Type:</span>{' '}
                      <span propValue2>
                        {this.state.currentDoc.uploadInfo.ocrType}
                      </span>
                    </div>}
                    {this.state.currentDoc.uploadInfo.ocrQuality !== undefined && this.state.currentDoc.uploadInfo.ocrQuality && <div>
                      <span className='propLabel2'>OCR Quality:</span>{' '}
                      <span propValue2>
                        {this.state.currentDoc.uploadInfo.ocrQuality}
                      </span>
                    </div>}
                    {this.state.currentDoc.uploadInfo.barcodes !== undefined && this.state.currentDoc.uploadInfo.barcodes && <div>
                      <span className='propLabel2'>Barcodes:</span>{' '}
                      <span propValue2>
                        {this.state.currentDoc.uploadInfo.barcodes}
                      </span>
                    </div>}
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        )}
        {this.state.showStampScreen && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                Download with Stamps:
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleStamps}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '430px', overflow: 'auto' }}
              >
                <div className='row'>
                  <div className='col-6'>
                    <label>Show:</label>
                    <div className='form-group'>
                      <input
                        className='form-check-input me-2'
                        checked={this.state.stampRequest.showDocId}
                        onChange={(e) => this.editStampRequest(e, 'docId')}
                        type='checkbox'
                      />
                      <label className='form-check-label'>Doc Id</label>
                    </div>
                    <div className='form-group'>
                      <input
                        className='form-check-input me-2'
                        checked={this.state.stampRequest.showUserName}
                        onChange={(e) => this.editStampRequest(e, 'userName')}
                        type='checkbox'
                      />
                      <label className='form-check-label'>User Name</label>
                    </div>
                    <div className='form-group'>
                      <input
                        className='form-check-input me-2'
                        checked={this.state.stampRequest.showDate}
                        onChange={(e) => this.editStampRequest(e, 'date')}
                        type='checkbox'
                      />
                      <label className='form-check-label'>Date</label>
                    </div>
                    <div className='form-group'>
                      <input
                        className='form-check-input me-2'
                        checked={this.state.stampRequest.showTime}
                        onChange={(e) => this.editStampRequest(e, 'time')}
                        type='checkbox'
                      />
                      <label className='form-check-label'>Time</label>
                    </div>
                    <label>Size:</label>
                    <div className='slidecontainer'>
                      <input
                        type='range'
                        min='1'
                        step='1'
                        max='3'
                        value={this.state.stampRequest.size}
                        onChange={(e) => this.editStampRequest(e, 'size')}
                        className='slider'
                        id='myRange'
                      />{' '}
                      <span>{this.state.stampRequest.sizeDesc}</span>
                    </div>
                    <div className='form-group'>
                      <input
                        className='form-check-input me-2'
                        value={this.state.stampRequest.useTransparency}
                        onChange={(e) =>
                          this.editStampRequest(e, 'transparency')
                        }
                        type='checkbox'
                      />
                      <label className='form-check-label'>Transparent</label>
                    </div>
                    <label className='mt-4'>Stamps:</label>
                    {this.state.stampRequest.stamps.map((stamp) => (
                      <div>
                        <span className='stamp-index'>{stamp.stampId}</span>
                        <span>{stamp.stampDesc}</span>
                      </div>
                    ))}
                  </div>
                  <div className='col-6'>
                    <div>
                      <label>Positions:</label>{' '}
                      <span style={{ color: '#999', fontSize: '9pt' }}>
                        (click to place stamps)
                      </span>
                    </div>
                    <div
                      id='stampPositionArea'
                      style={{
                        maxWidth: '300px',
                        maxHeight: '300px',
                        position: 'relative',
                      }}
                    >
                      <img
                        alt='stamp_image'
                        onClick={this.stampImageClick}
                        src={this.state.images[0].imageBytes}
                        style={{
                          width: `${
                            (this.state.images[0].imageWidth /
                              Math.max(
                                this.state.images[0].imageWidth,
                                this.state.images[0].imageHeight
                              )) *
                            300
                          }px`,
                          height: `${
                            (this.state.images[0].imageHeight /
                              Math.max(
                                this.state.images[0].imageWidth,
                                this.state.images[0].imageHeight
                              )) *
                            300
                          }px`,
                          border: '1px solid #555',
                          cursor: 'pointer',
                        }}
                      />

                      {this.state.stampRequest.stamps.map(
                        (stamp) =>
                          stamp.x > -1 && (
                            <div
                              key={stamp.stampId}
                              style={{
                                top: `${
                                  (stamp.y /
                                    Math.max(
                                      this.state.images[0].imageWidth,
                                      this.state.images[0].imageHeight
                                    )) *
                                  300
                                }px`,
                                left: `${
                                  (stamp.x /
                                    Math.max(
                                      this.state.images[0].imageWidth,
                                      this.state.images[0].imageHeight
                                    )) *
                                  300
                                }px`,
                              }}
                              className={`stamp-position-icon
                                 ${
                                   this.state.stampRequest.size === 3
                                     ? 'stamp-position-large'
                                     : ''
                                 }
                                 ${
                                   this.state.stampRequest.size === 2
                                     ? 'stamp-position-medium'
                                     : ''
                                 }
                                 ${
                                   this.state.stampRequest.size === 1
                                     ? 'stamp-position-small'
                                     : ''
                                 }
                                 ${
                                   this.state.stampRequest.useTransparency
                                     ? 'stamp-transparent'
                                     : ''
                                 }
                                 `}
                            >
                              {stamp.stampId}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className='btn btn-primary mt-3'
                  style={{ width: '100%' }}
                  onClick={this.downloadDocumentWithStamps}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.showMoveAssign && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                Move and Assign
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleShowMoveAssign}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '500px', padding: '40px', overflow: 'auto' }}
              >
                <label className='form-label'>Workflow step</label>
                <select
                  className='form-select'
                  value={this.state.moveAssignStepId}
                  onChange={this.editMoveAssignStep}
                >
                  <option value='-1'></option>
                  {this.state.currentDoc.destinations.map((dest) => (
                    <option key={dest.stepId} value={dest.stepId}>
                      {dest.stepName}
                    </option>
                  ))}
                </select>
                <label className='form-label mt-4'>Assign User(s)</label>
                <div className='d-flex flex-row'>
                  {this.state.moveAssignSelectedUsers.map((user) => (
                    <div key={user.userId} className='queueAssignOther mt-2'>
                      {user.userName}{' '}
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        onClick={(e) =>
                          this.onClickRemoveMoveAssignUser(e, user)
                        }
                        icon={solid('times')}
                      />
                    </div>
                  ))}
                </div>
                <div id='moveContainerAssignUserText' className='autocomplete2'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Assign User...'
                    onFocus={this.editMoveAssignUser}
                    value={this.state.moveAssignUserSearch}
                    onBlur={this.moveAssignUserBlur}
                    onChange={this.editMoveAssignUser}
                  ></input>
                </div>
                {this.state.moveAssignStepId !== -1 && (
                  <button
                    className='btn btn-success mt-3'
                    onClick={this.moveAssignDoc}
                  >
                    <FontAwesomeIcon icon={solid('check')} /> OK
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {this.state.showChangeBusinessProcess && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                Change Business Process
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleChangeBusinessProcess}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '500px', padding: '40px', overflow: 'auto' }}
              >
                <label className='form-label'>Business Process</label>
                <select
                  className='form-select'
                  value={this.state.selectedBpId2}
                  onChange={this.editBps}
                >
                  <option value='-1'></option>
                  {this.state.availableBps.map((dest) => (
                    <option key={dest.bpId} value={dest.bpId}>
                      {dest.bpName}
                    </option>
                  ))}
                </select>
                <label className='form-label'>Choose Template</label>
                <select
                  className='form-select'
                  value={this.state.selectedChangeBpTemplate}
                  onChange={this.editSelectedChangeBpTemplate}
                >
                  {/* <option value='-1'>Smart Template Matching</option> */}
                  {this.state.availableTemplates2.map((template) => (
                    <option
                      key={template.templateId}
                      value={template.templateId}
                    >
                      {template.templateName}
                    </option>
                  ))}
                </select>

                {this.state.selectedBpId2 !== -1 && (
                  <button
                    className='btn btn-success mt-3'
                    onClick={this.moveBpDocsClick}
                  >
                    <FontAwesomeIcon icon={solid('check')} /> OK
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {this.state.showReasonForReject && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>Document reject:</div>
              <div
                className='card-body'
                style={{ height: '400px', overflow: 'auto' }}
              >
                <div className='fs-5'>
                  Please provide a reason for document rejection
                </div>

                <>
                  <textarea
                    className='form-control'
                    value={this.state.rejectReasonNote}
                    onChange={this.editRejectNote}
                    maxLength={512}
                    rows='2'
                  />
                  <button
                    className='btn btn-danger mt-2'
                    style={{ marginRight: '10px' }}
                    onClick={this.confirmReject}
                    disabled={this.state.rejectReasonNote.length <= 2}
                  >
                    Reject
                  </button>
                  <button
                    className='btn btn-secondary mt-2'
                    onClick={this.cancelReject}

                  >
                    Cancel
                  </button>
                </>
              </div>
            </div>
          </div>
        )}

        {this.state.inProgressAnimation && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '450px' }}>
              <div className='card-header'>Action is in progress:</div>
              <div
                className='card-body'
                style={{ height: '250px', overflow: 'auto' }}
              >
                <div className='fs-5'>
                  Please wait while TrinDocs processes your request...
                </div>
                <div className='fa-5x' style={{ textAlign: 'center' }}>
                  <FontAwesomeIcon
                    icon={solid('spinner')}
                    className='fa-spin-pulse'
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.showEmailPDF && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                E-Mail PDF{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.cancelEmailPDF}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '400px', overflow: 'auto' }}
              >
                <div className='mb-3'>
                  <label className='form-labael'>To:</label>
                  <input
                    className='form-control'
                    value={this.state.emailTo}
                    onChange={this.onEditEmailTo}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-labael'>Subject:</label>
                  <input
                    className='form-control'
                    value={this.state.emailSubject}
                    onChange={this.onEditEmailSubject}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-labael'>Message:</label>
                  <textarea
                    className='form-control'
                    value={this.state.emailMessage}
                    onChange={this.onEditEmailMessage}
                  />
                </div>

                <div>
                  <button
                    className='btn btn-danger mt-2'
                    style={{ marginRight: '10px' }}
                    onClick={this.cancelEmailPDF}
                  >
                    <FontAwesomeIcon icon={solid('times')} /> Cancel
                  </button>
                  <button
                    className='btn btn-success mt-2'
                    onClick={this.validateAndSendEmail}
                  >
                    <FontAwesomeIcon icon={solid('check')} /> OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.showExpandedView && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                {this.state.expandedViewTitle}{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.hideExpandedView}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '300px', overflow: 'auto' }}
              >
               <div m-4>
                <textarea className='form-control' readonly={true} style={{width:'670px', border: 'solid,1px,#BBB'}} rows='8'>{this.state.expandedViewText}</textarea>
               </div>
              </div>
            </div>
          </div>
        )}
        {this.state.preventViewing && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                Document {this.state.documentID}
                <button
                  type='button'
                  onClick={this.goToActiveDocs}
                  className='btn-close'
                />
              </div>
              <div
                className='card-body'
                style={{ height: '200px', overflow: 'auto' }}
              >
                <div className='mb-3'>
                  <p>You are not permitted to view this document</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showErrorMessage && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                Document Errors
                <button
                  type='button'
                  onClick={this.toggleShowErrorMessage}
                  className='btn-close'
                />
              </div>
              <div
                className='card-body'
                style={{ height: '200px', overflow: 'auto' }}
              >
                <div className='mb-3'>
                  {this.state.errorMessages.map((error) => (
                    <p style={{ color: 'red' }}>{error}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.showAlert && (
          <div className='td-dialogue-container'>
            <div className='td-error-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
               {this.state.alertTitle}
                <button
                  type='button'
                  onClick={this.toggleShowAlert}
                  className='btn-close'
                />
              </div>
              <div
                className='card-body'
                style={{ height: '200px', overflow: 'auto' }}
              >
                <div className='mb-3'>

                    <p>{this.state.alertMessage}</p>

                </div>
              </div>
            </div>
          </div>
        )}

        <Link id='actdocLink' to='/' style={{ display: 'none' }}>
          ActiveDocs
        </Link>
        <Link
          id='nextDocLink'
          to={'/Document/' + this.state.currentDocId}
          style={{ display: 'none' }}
        >
          HiddenLink
        </Link>
        <Link
          id='docLink'
          to={'/Document/' + this.state.goToDocId}
          style={{ display: 'None' }}
        />
        {this.state.currentDoc !== undefined && (
          <Link
            id='inspectTempLink'
            className='nav-link'
            to={`/TemplateEditor/${
              this.state.currentDoc === undefined
                ? 0
                : this.state.currentDoc.templateId
            }/${this.state.currentDocId}`}
            style={{ display: 'none' }}
          ></Link>

        )}
        {this.state.relatedInfo.show && (
          <div
            className='relatedTip'
            style={{ left: `${this.state.relatedInfo.left}px`, top: '107px' }}
          >
            <div>FormType: {this.state.relatedInfo.formType}</div>
            <div>Field: {this.state.relatedInfo.field}</div>
          </div>
        )}
        <input
          id='acctchUploadInput'
          onChange={this.uploadAttachmentSubmit}
          type='file'
          hidden
        />
      </div>
    );
  }
}

export default DocumentEditor;
