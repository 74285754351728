import * as React from 'react';
import CompanionSidebar from './CompanionSidebar';
import {
  LoadCompanionSchema,
  GetCompanionData,
  UpdateCompanionData,
} from '../../../actions/CompanionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import actionTypes from '../../../actions/ActionTypes';
import CompStore from '../../../stores/CompanionStore';
import CompanionCell from './CompanionCell';

class CompanionView extends React.Component {
  constructor() {
    super();
    // state
    this.state = {
      currentSchema: undefined,
      schemaId: 0,
      allData: [],
      filteredData: [],
      currentEdit: undefined,
      hasDirtyRows: false,
      viewHeight: window.innerHeight - 120,
      viewWidth: window.innerWidth - 325,
    };
    this.updateCompanionTable = this.updateCompanionTable.bind(this);
    this.onStoreUpdate = this.onStoreUpdate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.filterData = this.filterData.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onCellEdit = this.onCellEdit.bind(this);
    this.moveCell = this.moveCell.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleAddRowClick = this.handleAddRowClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    CompStore.addChangeListener(this.onStoreUpdate);
    window.onresize = this.onWindowResize;
    this.updateCompanionTable();
    document.title = 'TrinDocs - Companion Tables'
  }

  componentWillUnmount() {
    CompStore.removeChangeListener(this.onStoreUpdate);
  }

  componentDidUpdate() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let compId = parseInt(pathParts[pathParts.length - 1], 10);
    if(compId !== this.state.schemaId && this.state.schemaId !== 0)
    {
      this.updateCompanionTable();
    }

  }

  updateCompanionTable() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let compId = parseInt(pathParts[pathParts.length - 1], 10);
    this.setState({ schemaId: compId });
    LoadCompanionSchema(compId);
  }

  onStoreUpdate() {
    let lastAction = CompStore.lastChange();

    if (lastAction === actionTypes.CompanionLoadOne) {
      let schema = CompStore.getCurrentCompanion();
      for (let record of schema.columnData) {
        record.filter = '';
      }
      this.setState({ currentSchema: schema });
      GetCompanionData(this.state.schemaId);
    }

    if (lastAction === actionTypes.CompanionGetData) {
      // update allData
      let data = CompStore.getCurrentData();
      this.setState({ allData: data.records });

      // filter, start with top 100 rows
      let filter = [];
      //let filterCount = 0;
      for (let ent of data.records) {
        //if (filterCount < 100) {
        filter.push(ent);
        //filterCount++;
        // }
      }
      this.setState({ filteredData: filter });
    }
  }

  onWindowResize(e) {
    //setViewWidth
    let vh = window.innerHeight - 120;
    let vw = window.innerWidth - 325;
    this.setState({ viewHeight: vh, viewWidth: vw });
  }

  onCellEdit(rowId, colId, newText) {
    let data = this.state.filteredData;
    let rec = data.find((x) => x.rowId === rowId);
    let ent = rec.entities.find((y) => y.colIndex === colId);
    if (!ent.isReadOnly) {
      rec.isDirty = true;
      ent.value = newText;
      this.setState({
        currentEdit: { rowId: rowId, colId: colId },
        hasDirtyRows: true,
      });
    }
  }

  moveCell(dir) {
    let data = this.state.filteredData;
    if (this.state.currentEdit !== undefined) {
      // deselected the previous edit
      let preRec = data.find((x) => x.rowId === this.state.currentEdit.rowId);
      let preEnt = preRec.entities.find(
        (y) => y.colIndex === this.state.currentEdit.colId
      );
      preEnt.editMode = false;

      // find the entity
      let rec = undefined; // = data.find((x) => x.rowId === rowId);
      let ent = undefined; // = rec.entities.find((y) => y.colIndex === colId);
      if (dir === 'down') {
        let rowIndex = data.indexOf(preRec);
        rec = data[rowIndex + 1];
        //rec = data.find((x) => x.rowId === preRec.rowId + 1);

        if (rec !== undefined) {
          ent = rec.entities.find((y) => y.colIndex === preEnt.colIndex);
        }
      }
      if (dir === 'up') {
        let rowIndex = data.indexOf(preRec);
        rec = data[rowIndex - 1];
        //rec = data.find((x) => x.rowId === preRec.rowId + 1);

        if (rec !== undefined) {
          ent = rec.entities.find((y) => y.colIndex === preEnt.colIndex);
        }
      }
      if (dir === 'right') {
        rec = data.find((x) => x.rowId === preRec.rowId);

        if (rec !== undefined) {
          ent = rec.entities.find((y) => y.colIndex === preEnt.colIndex + 1);
        }
      }
      if (dir === 'left') {
        rec = data.find((x) => x.rowId === preRec.rowId);

        if (rec !== undefined) {
          ent = rec.entities.find((y) => y.colIndex === preEnt.colIndex - 1);
        }
      }
      if (dir === 'tab') {
        rec = data.find((x) => x.rowId === preRec.rowId);

        if (rec !== undefined) {
          // normally move to the right
          ent = rec.entities.find((y) => y.colIndex === preEnt.colIndex + 1);
          if (ent === undefined) {
            // at end of line, move to start of next row
            let rowIndex = data.indexOf(preRec);
            rec = data[rowIndex + 1];
            ent = rec.entities.find((y) => y.colIndex === 1);
          }
        }
      }

      if (ent !== undefined) {
        // move the focus
        preEnt.editMode = false;
        ent.editMode = true;

        this.setState(
          {
            filteredData: data,
            currentEdit: { rowId: rec.rowId, colId: ent.colIndex },
          },
          () => {
            // select current cell
            let input = document.getElementById(
              `edit_${this.state.currentEdit.rowId}_${this.state.currentEdit.colId}`
            );
            input.select();
          }
        );
      }
    }
  }

  onCellClick(rowId, colId) {
    let data = this.state.filteredData;
    if (this.state.currentEdit !== undefined) {
      // deselected the previous edit
      let preRec = data.find((x) => x.rowId === this.state.currentEdit.rowId);
      if (preRec !== undefined) {
        let preEnt = preRec.entities.find(
          (y) => y.colIndex === this.state.currentEdit.colId
        );
        preEnt.editMode = false;
      }
    }

    // find the entity
    let rec = data.find((x) => x.rowId === rowId);
    let ent = rec.entities.find((y) => y.colIndex === colId);
    //rec.isDirty = true;
    ent.editMode = true;

    this.setState(
      {
        filteredData: data,
        currentEdit: { rowId: rowId, colId: colId },
      },
      () => {
        // select current cell
        let input = document.getElementById(
          `edit_${this.state.currentEdit.rowId}_${this.state.currentEdit.colId}`
        );
        input.select();
      }
    );
  }

  handleDeleteClick(rowId) {
    console.log(`delete row ${rowId}`);
    let data = this.state.filteredData;
    let recordToDelete = data.find((x) => x.rowId === rowId);
    if (recordToDelete !== undefined) {
      recordToDelete.isDeleted = true;
    }
    this.setState({ filteredData: data, hasDirtyRows: true });
  }

  onFilterChange(column, newValue) {
    // update filter
    let schema = this.state.currentSchema;
    let col = schema.columnData.find((x) => x.columnId === column.columnId);
    col.filter = newValue;
    this.setState({ currentSchema: schema });

    // filter the data
    this.filterData(schema);
  }

  filterData(schema) {
    let newFilter = [];
    let filterCount = 0;
    let data = this.state.allData;
    for (let rec of data) {
      if (filterCount < 1000) {
        // check values against filter in the schema
        let canPush = true;
        for (let ent of rec.entities) {
          let colDef = schema.columnData.find(
            (x) => x.columnId === ent.colIndex
          );
          if (
            colDef.filter !== '' &&
            !ent.value.toUpperCase().startsWith(colDef.filter.toUpperCase())
          ) {
            canPush = false;
          }
        }
        if (canPush) {
          newFilter.push(rec);
          filterCount++;
        }
      }
    }
    this.setState({ filteredData: newFilter });
  }

  handleAddRowClick() {
    console.log('handle Add row click');
    // Find max row index from allData
    let maxIndex = 0;
    for (let rec of this.state.allData) {
      if (rec.rowId > maxIndex) {
        maxIndex = rec.rowId;
      }
    }

    let newRowId = maxIndex + 1;
    console.log(newRowId);
    // create new CompanionRecord
    let newRec = {};
    newRec.rowId = newRowId;
    newRec.isNew = true;
    newRec.isDirty = false;
    newRec.entities = [];
    newRec.entities = this.state.currentSchema.columnData.map((col) => ({
      colIndex: col.columnId,
      rowIndex: newRowId,
      colName: col.columnName,
      value: '',
      isReadOnly: col.isReadOnly,
      editMode: false,
    }));

    // add record to all and filtered sets
    let all = this.state.allData;
    all.push(newRec);
    let filter = this.state.filteredData;
    filter.push(newRec);

    // update and click first cell
    this.setState({ allData: all, filteredData: filter }, () =>
      this.onCellClick(newRowId, 1)
    );
  }

  handleSave() {
    let dirtyRecords = [];
    for (let rec of this.state.allData) {
      if (rec.isDirty || rec.isNew || rec.isDeleted) {
        dirtyRecords.push(rec);
      }
    }
    UpdateCompanionData(this.state.schemaId, dirtyRecords);
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <CompanionSidebar />
          {this.state.currentSchema !== undefined && (
            <div className='flex-fill' style={{ backgroundColor: '#efefef' }}>
              <div
                className='card'
                style={{
                  marginTop: '10px',
                  marginLeft: '30px',
                  marginRight: '30px',
                  height: `${this.state.viewHeight}px`,
                  maxHeight: `${this.state.viewHeight}px`,
                  width: `${this.state.viewWidth}px`,
                  maxWidth: `${this.state.viewWidth}px`,
                }}
              >
                <div className='card-header'>
                  <h3>
                    <i className='fas fa-table' />{' '}
                    {this.state.currentSchema.companionName}
                  </h3>
                </div>
                <div className='card-body' style={{ padding: '0px' }}>
                  <div
                    style={{
                      paddingTop: '0px',
                      maxHeight: `${this.state.viewHeight - 80}px`,
                      overflow: 'scroll',
                      border: '1px solid #E1E1E1',
                    }}
                  >
                    <table
                      className='table'
                      style={{
                        border: '1px solid #E1E1E1',
                      }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: '#D1D1D1' }}>
                          <th
                            scope='col'
                            style={{
                              position: 'sticky',
                              left: '0px',
                              backgroundColor: '#E1E1E1',
                              minWidth: '50px',
                            }}
                          >
                            <i className='fas fa-filter'></i>
                          </th>
                          {this.state.currentSchema.columnData.map((column) => (
                            <th key={column.columnId}>
                              <input
                                type='text'
                                style={{
                                  padding: '2px',
                                  minWidth: '80px',
                                }}
                                className='form-control'
                                id={`filter_${column.columnId}`}
                                value={column.filter}
                                onChange={(e) =>
                                  this.onFilterChange(column, e.target.value)
                                }
                              />
                            </th>
                          ))}
                          <th></th>
                        </tr>
                        <tr>
                          <th
                            scope='col'
                            style={{
                              position: 'sticky',
                              left: '0px',
                              backgroundColor: '#FFFFFF',
                            }}
                          >
                            #
                          </th>
                          {this.state.currentSchema.columnData.map((column) => (
                            <th key={column.columnId}>
                              {column.columnDisplay}
                            </th>
                          ))}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.filteredData.map((record) => (
                          <tr
                            key={record.rowId}
                            style={{
                              backgroundColor: record.isDeleted
                                ? '#ECBDBD'
                                : '#fff',
                            }}
                          >
                            <th
                              scope='row'
                              style={{
                                position: 'sticky',
                                left: '0px',
                                backgroundColor: '#E1E1E1',
                              }}
                            >
                              <span>{record.rowId} </span>
                              {record.isDirty &&
                                !record.isNew &&
                                !record.isDeleted && (
                                  <i
                                    className='fas fa-marker'
                                    style={{
                                      color: '#3E4374',
                                    }}
                                  />
                                )}
                              {record.isNew && (
                                <i
                                  className='fas fa-plus'
                                  style={{ color: '#198754' }}
                                />
                              )}
                              {record.isDeleted && (
                                <i
                                  className='fas fa-times'
                                  style={{ color: '#E10000' }}
                                />
                              )}
                            </th>
                            {record.entities.map((cell) => (
                              <CompanionCell
                                key={cell.colIndex}
                                value={cell.value}
                                rowId={record.rowId}
                                colId={cell.colIndex}
                                isEditMode={cell.editMode}
                                onMoveCell={this.moveCell}
                                isReadOnly={cell.isReadOnly}
                                onClickHandler={this.onCellClick}
                                onEditHandler={this.onCellEdit}
                              />
                            ))}
                            <td>
                              <i
                                className='fas fa-times'
                                style={{
                                  color: '#700000',
                                  cursor: 'pointer',
                                }}
                                onClick={(e) =>
                                  this.handleDeleteClick(record.rowId)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td>
                            <button
                              className='btn btn-success'
                              onClick={this.handleAddRowClick}
                            >
                              <FontAwesomeIcon icon={solid('plus')} />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {this.state.hasDirtyRows && (
                    <button
                      className='btn btn-primary'
                      style={{ marginTop: '6px' }}
                      onClick={this.handleSave}
                    >
                      <FontAwesomeIcon icon={solid('save')} /> Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CompanionView;
